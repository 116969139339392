import Constants from "../Common/Constants";
import PFUtils from "../Common/Process.Fulfillment.Utils";
import IdentityManager from "./Identity.manager";
import PFManager from "./Process.Fulfillment.Manager";

export default class PFServicesManager {

    public static getCodes = async () => {
        let token = IdentityManager.AccessToken;
        await fetch(
            `/pf/getShipCodes`,
            {
                method:'get',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            }
        )
        .then(async (response) => {
            let res: any = await response.json();
            if(res.success)
            {
                PFManager.reasonCodes = res.data;
                const premiumReasonCodes = JSON.parse(PFManager.configs.find((x: any) => x.key == Constants.Configs.PremiumReasons)?.value);
                if(premiumReasonCodes?.length>0)
                {
                    PFManager.reasonCodes.push(...premiumReasonCodes);
                }
            }
        })
        .catch(error => console.log('getShipCodes Error', error));
    }

    public static getGroups  = async () => {
        let token = IdentityManager.AccessToken;
        await fetch(
            `/pf/getGroups`,
            {
                method:'get',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            }
        )
        .then(async (response) => {
            let res: any = await response.json();
            if(res.success)
            {
                PFManager.shippingGroups = res.data;
            }
        })
        .catch(error => console.log('getGroups Error', error));
    }

    public static getActionServices  = async () => {
        let token = IdentityManager.AccessToken;
        await fetch(
            `/pf/getActionServices`,
            {
                method:'get',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            }
        )
        .then(async (response) => {
            let res: any = await response.json();
            if(res.success)
            {

                let [results, def, data] = PFUtils.parseCarrierServices(res.data);
                PFManager.carrierServiceData = results;
                PFManager.default = def;
                PFManager.unGroupedCarrierServiceData = data;
            }
        })
        .catch(error => console.log('getActionServices Error', error));
    }

    public static getServiceRecommendation = async(payload:any) => {
        let token = IdentityManager.AccessToken;
        let body:any = {
                "STATE": payload.state,
                "WEIGHT": payload.weight,
                "COMPANY": payload.company
        };
        let result = await fetch(
            `/pf/getServiceRecommendation`,
            {
                method:'post',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            }
        )
        .then(async (response) => {
            let res: any = await response.json();
            return res;
        })
        .catch(error => console.log('getServiceRecommendation Error', error));
        return result;
    }

    public static voidShipment = async (payload:any) => {
        let token = IdentityManager.AccessToken;
        let empId = IdentityManager.getUserProperty(Constants.employeeid);
        if (!empId) {
            empId = IdentityManager.getUserProperty(Constants.preferred_username);
        }
        let body:any = {
            PAYLOAD:{
                APP_ID: Constants.PROCESS_FULFILLMENT.APP_ID,
                EMPLOYEE_ID: empId,
                SHIPPER: payload.shipper,
                SUID: payload.SUID
            }
        };
        let _result: any = { response: { msg: '' } };
        await fetch(
            `/pf/voidShipment`,
            {
                method:'post',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            }
        )
        .then(async (response) => {
            _result = await response.json();
        })
        .catch(error => console.log('voidShipment Error', error));
        return _result;
    }

    public static createShipment = async (shipper:string, payload:any) => {
        let token = IdentityManager.AccessToken;
        let empId = IdentityManager.getUserProperty(Constants.employeeid);
        if (!empId) {
            empId = IdentityManager.getUserProperty(Constants.preferred_username);
        }
        let body: any = PFUtils.getBody_Create_Shipment(empId, shipper, payload);
        if (window.location.search.includes('r=1')) {
            body.PAYLOAD.LOGTYPE = 'ReturnLabel';
        }
        let result = await fetch(
            `/pf/createShipment`,
            {
                method:'post',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            }
        )
        .then(async (response) => {
            let res: any = await response.json();
            return res;
        })
        .catch(error => console.log('createShipment Error', error));
        return result;
    }

    public static rePrint = async (SUID: string, shipper: string) => {
        let token = IdentityManager.AccessToken;
        let empId = IdentityManager.getUserProperty(Constants.employeeid);
        if (!empId) {
            empId = IdentityManager.getUserProperty(Constants.preferred_username);
        }
        let body = PFUtils.getBody_RePrint(empId, SUID, shipper);
        let result = await fetch(
            `/pf/rePrintLabel`,
            {
                method:'post',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            }
        )
        .then(async (response) => {
            let res: any = await response.json();
            return res;
        })
        .catch(error => console.log('rePrint Error', error));
        return result;
    }

}