import React, { useState } from 'react';
import './Labels.scss';
import Constants from '../../Common/Constants';

import { makeStyles, withStyles, Theme } from '@material-ui/core/styles';
import { Button, Select, MenuItem, IconButton, Grid } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import TablePagination from '@material-ui/core/TablePagination';

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Loader from 'react-loader-spinner';
import LabelUtils from '../../Common/Label.Utils';
import PFManager from '../../Managers/Process.Fulfillment.Manager';
import ExcelManager from '../../Managers/Excel.Manager';
import Utils from '../../Common/Utils';
const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
        },
    },
}));

const PageButtonSelected = withStyles((theme: Theme) => ({
    root: {
        margin: "0 5px",
        fontWeight: 600,
        color: theme.palette.getContrastText("#D93A2F"),
        backgroundColor: "#D93A2F",
        '&:hover': {
            backgroundColor: "#ad180e",
        },
    },
}))(Button);

const PageButton = withStyles((theme: Theme) => ({
    root: {
        margin: "0 5px",
        fontWeight: 600,
        color: '#D93A2F',//theme.palette.getContrastText("#FFFFFF"),
        backgroundColor: "#FFFFFF",
        border: '1px solid #D93A2F',
        '&:hover': {
            backgroundColor: "#D93A2F",
            color: theme.palette.getContrastText("#D93A2F"),
        },
    },
}))(Button);

export default function LabelTablePageButtons(props: any): any {

    const [excelLoading, setExcelLoading] = useState(false);

    const classes = useStyles();

    const handleChange = (event: any, value: any) => {
        let pagination = props.pagination;
        pagination.page = value;
        console.log(value, "handleChangeOld");
        props.labelActionChange({ pagination });
    };

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,) => {
        let pagination = props.pagination;
        pagination.page = newPage + 1;
        console.log(pagination.page, "handleChangeOld");
        props.labelActionChange({ pagination });

    }

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {

        let pagination = { size: 5, page: 1 };
        pagination.size = parseInt(event.target.value);
        console.log(pagination.size, "pagination.size");
        props.labelActionChange({ pagination });
    };

    const changePageSize = (data: any) => {
        let pagination = { size: 5, page: 1 };
        pagination.size = data.target.value;
        props.labelActionChange({ pagination });
    }

    const getPaginationValues = () => {
        let from = (props.pagination.page - 1) * props.pagination.size;
        let to = from + props.pagination.size;
        if (to > props.count) { to = props.count }

        let pageCount = Math.ceil(props.count / props.pagination.size);
        let pages = [];
        for (let i = 0; i < pageCount; i++) {
            pages.push(i + 1);
        }

        let pageStart = from + 1;
        let currentPage = Math.ceil(pageStart / props.pagination.size);

        return [to, pages, pageCount, currentPage, props.count, props.pagination.size];
    }

    const specificPage = (requiredPage: number) => {
        let pagination = props.pagination;
        pagination.page = requiredPage;
        props.labelActionChange({ pagination });
    }

    const changePage = (data: any, type: string) => {
        let pagination = props.pagination;
        if (type === 'back' && pagination.page > 1) {
            pagination.page--;
        } else {
            pagination.page++;
        }
        props.labelActionChange({ pagination });
    }
    const AddDataToExcelIndex = async (params: any, guidId: any) => {
        params.guidId = guidId
        params.excelStatus = "Initiating Process"
        params.isProcessComplete = 0
        params.createdDate = new Date().toISOString();
        await ExcelManager.addExcelEntry(params)
    }
    const CallExcelLambda = async () => {

    }
    const exportData = async () => {
        const guid = Utils.getGuid()
        let params = LabelUtils.getExportExcelParams(props.state, props.searchTerm, guid);
        const daysDifference = LabelUtils.daysDifference(params.to, params.from);
        const exportLimit = PFManager.configs.find((x: any) => x.key == Constants.Configs.ExportConfig)?.value;
        if (daysDifference <= parseInt(exportLimit, 10)) {
            setExcelLoading(true);
            const url = "/Export?id=" + guid;
            localStorage.setItem("exportParameters", JSON.stringify(params));
            window.open(url, "_blank");
            setExcelLoading(false);
        }
        else {
            const dialogMsg = `Data can be Exported upto ${exportLimit} Days.`
            props.openDialog(dialogMsg);
        }
    }

    const [to, pages, pageCount, currentPage, count, size] = getPaginationValues();
    return (
        <div style={{ width: '100%', display: 'flex', justifyContent: 'space-around', alignItems: 'center', background: '#D93A2F' }}>
            {/* <div className={classes.root}>
                <Pagination count={pageCount} page={currentPage} color="secondary" onChange={handleChange} />
            </div> */}
            < div id='paginationWebId' className="paginationWeb" style={{ background: "#D93A2F", display: "flex", flex: "1", alignItems: "center", justifyContent: "center", marginLeft: "145px" }
            }>

                <div style={{ paddingTop: "10px" }}>

                    <TablePagination
                        component="div"
                        count={count}
                        page={currentPage - 1}
                        onPageChange={handleChangePage}
                        rowsPerPage={size}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />

                </div>
            </div >
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                {excelLoading ? <div style={{ marginRight: "5px" }}><Loader type="TailSpin" color="white" height={30} width={30} /></div> : <div style={{ marginRight: "5px", width: 30 }}></div>}

                {Utils.isMobile == false ? <Button variant="contained" color="primary" disabled={excelLoading} style={{ background: "#22945e", marginRight: "10px" }} onClick={() => { exportData() }}>Export Data</Button> : <></>}
            </div>
        </div>
    );
}