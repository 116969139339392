import React from 'react';
import { useHistory } from 'react-router-dom';
import './Navbar.scss';

import Utils from '../../Common/Utils';
import AuthManager from '../../Managers/Auth.manager';
import IdentityManager from '../../Managers/Identity.manager';
import Constants from "../../Common/Constants"
import MenuIcon from '@material-ui/icons/Menu';
import { AppBar, IconButton, MenuItem, Menu } from '@material-ui/core';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';

export default function Navbar(props: any): any {

  const history = useHistory();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const logout = () => AuthManager.logout()

  const handleMenuClose = () => setAnchorEl(null)

  const openStoreInformation = () => setAnchorEl(null)

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget)

  const openUserProfile = () => {
    setAnchorEl(null);
    props.changeState({ userProfileDialog: true });
  }

  const toStart = (e: any) => {
    props.setSelectedOption('');
    history.push({ pathname: '/' });
  }

  const open = Boolean(anchorEl);
  const user = IdentityManager.getUserProperty(Constants.username);

  return (
    <AppBar position="static" style={{ display: 'flex', width: "100%", flexDirection: 'row', flexWrap: 'nowrap', justifyContent: 'space-between' }}>

      <div style={{ display: 'flex' }}>
        <div className='logo-Container hvr-inner-spread' onClick={(e) => { toStart(e) }}>
        </div>

        {
          !Utils.isMobile && !props.loading &&
          <div onClick={openStoreInformation} className="storeDiv">
            <div className='storeContainer'>
              <div className='storeTitle'>Shipping Center</div>
            </div>
          </div>
        }
      </div>

      {user && !Utils.isMobile && !props.loading && (
        <div className={'profileContainer'}>
          <div className={'profileName'}><span style={{ fontWeight: 600 }}>Welcome, </span>{user}</div>
          <div className={'profileMenu'}>
            <IconButton onClick={handleMenuOpen} color="inherit">
              <AccountCircleOutlinedIcon style={{ fontSize: "40px" }} />
            </IconButton>
            <Menu
              open={open}
              keepMounted
              anchorEl={anchorEl}
              onClose={handleMenuClose}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
              <MenuItem onClick={openUserProfile}>Profile</MenuItem>
              <MenuItem onClick={logout}>Sign Out</MenuItem>
            </Menu>
          </div>
        </div>
      )}

      {
        Utils.isMobile &&
        <IconButton color="secondary" onClick={() => { props.toggleDrawer() }}>
          <MenuIcon />
        </IconButton>
      }

    </AppBar>
  );
}
