import React from 'react';
import './TechAudits.scss';
import { FaTimes, FaPrint, FaArrowUp, FaArrowDown } from 'react-icons/fa';
import { FiPlus } from 'react-icons/fi';
import { AiOutlineReload, AiOutlineRollback } from 'react-icons/ai';
import { BiErrorAlt } from 'react-icons/bi';
import { BsFillGearFill } from 'react-icons/bs';

export default function AuditLogo(props: any): any {
    
    const getLogType = () => {
        switch (props.logType) {
            case 'VoidLabel':
              return  <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                          <FaTimes style={{fontSize: '16px', color: 'red'}}/>
                        </div>
                        <div style={{width: '70px', marginLeft: '5px', textAlign: 'left'}}>{props.logType}</div>
                      </div>
            case 'CreateLabel':
              return  <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                          <FiPlus style={{fontSize: '16px', color: 'green'}}/>
                        </div>
                        <div style={{width: '70px', marginLeft: '5px', textAlign: 'left'}}>{props.logType}</div>
                      </div>
            case 'ReturnLabel':
              return  <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                          <AiOutlineRollback style={{fontSize: '16px', color: 'red'}}/>
                        </div>
                        <div style={{width: '70px', marginLeft: '5px', textAlign: 'left'}}>{props.logType}</div>
                      </div>
            case 'StorePrint':
              return  <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                          <FaPrint style={{fontSize: '16px', color: 'green'}}/>
                        </div>
                        <div style={{width: '70px', marginLeft: '5px', textAlign: 'left'}}>{props.logType}</div>
                      </div>
            case 'RePrint':
              return  <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                          <AiOutlineReload style={{fontSize: '16px', color: 'darkblue'}}/>
                        </div>
                        <div style={{width: '70px', marginLeft: '5px', textAlign: 'left'}}>{props.logType}</div>
                      </div>
            case 'PrintConfig':
              return  <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                          <BsFillGearFill style={{fontSize: '16px', color: 'grey'}}/>
                        </div>
                        <div style={{width: '70px', marginLeft: '5px', textAlign: 'left'}}>{props.logType}</div>
                      </div>
            case 'Logout':
              return  <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                          <FaArrowUp style={{fontSize: '16px', color: 'darkblue'}}/>
                        </div>
                        <div style={{width: '70px', marginLeft: '5px', textAlign: 'left'}}>{props.logType}</div>
                      </div>
            case 'Login':
              return  <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                          <FaArrowDown style={{fontSize: '16px', color: 'darkblue'}}/>
                        </div>
                        <div style={{width: '70px', marginLeft: '5px', textAlign: 'left'}}>{props.logType}</div>
                      </div>
            case 'Error':
              return  <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                          <BiErrorAlt style={{fontSize: '22px', color: 'red'}}/>
                        </div>
                        <div style={{width: '70px', marginLeft: '5px', textAlign: 'left'}}>{props.logType}</div>
                      </div>
            default:
              return  <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        </div>
                        <div style={{width: '70px', textAlign: 'left'}}>{props.logType}</div>
                      </div>
        }
    }

    return (
        getLogType()
    )
}