import React from 'react';
import './Dashboard.scss';
import moment from "moment";
import Loader from "react-loader-spinner";

import Utils from '../../Common/Utils';
import EODUtils from '../../Common/EOD.Utils';
import IdentityManager from '../../Managers/Identity.manager';
import PFManager from '../../Managers/Process.Fulfillment.Manager';

import { Button, Paper, Select, MenuItem } from '@material-ui/core';
import SecurityIcon from '@material-ui/icons/Security';
import { FaUps, FaFedex, FaUsps } from "react-icons/fa";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import PresentToAllIcon from '@material-ui/icons/PresentToAll';
import Constants from '../../Common/Constants';
import StoreManager from '../../Managers/Store.manager';
import LabelUtils from '../../Common/Label.Utils';
import PrintList from './PrintList';
import AddPrinter from './AddPrinter';
import PrintConfigurationManager from '../../Managers/PrintConfiguration.Manager';

// let rows = [
//   { StoreNo: 'G107', PrinterName: 'LABEL', PrinterType: 'Thermal', PrintingType: 'RAW' },
//   { StoreNo: 'G109', PrinterName: 'LABEL', PrinterType: 'Thermal', PrintingType: 'RAW' },
// ];

export default class PrinterConfigurationView extends React.Component<any, {}> {

  public state = {
    loading: true,
    openDialog: false,
    displayRows: [],
  }
  
  constructor(props: any) {
    super(props);

    this.deleteItem = this.deleteItem.bind(this);
    this.changeState = this.changeState.bind(this);
    this.createNewRow = this.createNewRow.bind(this);
  }
  
  componentDidMount = async () => {
    await this.getConfigs();
    await this.loadData();
  }

  changeState = (data: any) => this.setState(data);

  createNewRow = async (row: any) => {
    let body = {"ACTION": PrintConfigurationManager.requestTypes.Create, "PAYLOAD": row};
    let printers: any = await PrintConfigurationManager.sendPrintConfigRequest(body);
    await this.loadData();
  }

  loadData = async () => {
    this.setState({displayRows: [], loading: true, openDialog: false});
    let printers: any = await PrintConfigurationManager.sendPrintConfigRequest({"ACTION": PrintConfigurationManager.requestTypes.Read});
    this.setState({displayRows: printers.data, loading: false, openDialog: false});
  }

  getConfigs = async() => {
    let configs: any = await PrintConfigurationManager.sendPrintConfigRequest({"ACTION": PrintConfigurationManager.requestTypes.Configs});
    PrintConfigurationManager.printerTypes = configs.data.printerTypes;
    PrintConfigurationManager.printingTypes = configs.data.printingTypes;
  }

  deleteItem = async(storeNo: string) => {
    let body = {"ACTION": PrintConfigurationManager.requestTypes.Delete, "PAYLOAD": {StoreNo: storeNo}};
    let printers: any = await PrintConfigurationManager.sendPrintConfigRequest(body);
    await this.loadData();
  }

  // RENDER METHODS

  render = () => {
    return (
      <div className="DashboardContainer">
        <AddPrinter
          open={this.state.openDialog}
          changeState={this.changeState}
          createNewRow={this.createNewRow}
          handleClose={() => {this.setState({openDialog: false})}}
        />
        <div className="PrinterListContainer">
          <div className="Header">
            <div className="Title">Printer Configuration List:</div>
            {
              !this.state.loading &&
              <div>
                <Button color='primary' variant='contained' onClick={() => {this.setState({openDialog: true})}}>Create</Button>
              </div>
            }
          </div>
          <div>
            {
              this.state.loading
              ?
              <Loader type="TailSpin" color="#D93A2F" height={80} width={80} />
              :
              <PrintList
                deleteItem={this.deleteItem}
                displayRows={this.state.displayRows}
              />
            }
          </div>
        </div>
      </div>
    );
  }
}