import React from 'react';
import './Routes.scss';
import CacheBuster from '../Managers/Cache.Buster';
import PrinterConfigurationView from '../Subcomponent/PrinterConfiguration/PrinterConfigurationView';

export default class PrinterConfiguration extends React.Component<any, {}> {

  public state = {
  }

  constructor(props: any) {
    super(props);

    this.changeState = this.changeState.bind(this);
  }

  componentDidMount = async () => {
    await CacheBuster.checkVersion();
  }

  changeState = (data: any) => this.setState(data);

  // RENDER METHODS

    render = () => {
      return (
        <div className='innerContianer'>
          <div className='primaryRow'>
            <div className='headerText'>Printer Configurations</div>
          </div>
          
          <PrinterConfigurationView />
        </div>
      );
    }
}