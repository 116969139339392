import 'date-fns';
import React from 'react';

import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

export default function DatePicker(props: any): any {

  const handleDateChange = (date: Date | null) => {
    if (date !== null) {props.setDate(date)}
  };

  const defaultDateDisable = (date: Date | null) => false

  const DateSet = props.shouldDisableDate === undefined ? defaultDateDisable : props.shouldDisableDate

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        disableToolbar
        margin="normal"
        variant="inline"
        format="MM/dd/yyyy"
        style={props.style}
        value={props.currentDate}
        onChange={handleDateChange}
        shouldDisableDate={DateSet}
        InputProps={{ readOnly: true }}
        KeyboardButtonProps={{'aria-label': 'change date'}}
      />
    </MuiPickersUtilsProvider>
  );
}
