import Constants from "./Constants";

import { FaPrint, FaBarcode, FaTimes } from "react-icons/fa";
import { FiPlus } from "react-icons/fi";
import { GrPowerCycle } from "react-icons/gr";
import moment from "moment";
import StoreManager from "../Managers/Store.manager";
import PFManager from "../Managers/Process.Fulfillment.Manager";
import IdentityManager from "../Managers/Identity.manager";
import Utils from "./Utils";

export default class PFUtils {

    public static getBody_RePrint = (empId: string, SUID: string, shipper: string) => {
        return {
            ACTION: Constants.PROCESS_FULFILLMENT.ACTIONS.REPRINT,
            PAYLOAD: {
                APP_ID: Constants.PROCESS_FULFILLMENT.APP_ID,
                EMPLOYEE_ID: empId,//"986732",
                SHIPPER: shipper,//"G220",
                SUID: SUID//"3DE9386ED84249F98B7EB2FACEBA0F40"
            }
        };
    }

    public static getBody_Void_Shipment = (empId: string, SUID: string, shipper: string) => {
        return {
            ACTION: Constants.PROCESS_FULFILLMENT.ACTIONS.VOID_SHIPMENT,
            PAYLOAD: {
                APP_ID: Constants.PROCESS_FULFILLMENT.APP_ID,
                EMPLOYEE_ID: empId,//"3453535",
                SHIPPER: shipper,//"G220",
                SUID: SUID,//"EDF39EF1626842999FF47E6A165BD21"
            }
        }
    }

    public static getBody_Create_Shipment = (empId: string, shipper: string, data: any) => {
        if (Array.isArray(data)) {
            let payLoad: any = [];
            data.forEach((value: any) => {
                const _obj = {
                    ACTION: Constants.PROCESS_FULFILLMENT.ACTIONS.CREATE_SHIPMENT,
                    PAYLOAD: {
                        BOX_NO: value.BOX_NO,
                        APP_ID: Constants.PROCESS_FULFILLMENT.APP_ID,
                        EMPLOYEE_ID: empId,//"986732",
                        SHIPPER: shipper,//"G220",
                        SHIPPER_SHIPMENT_REFERENCE: value.SHIPPER_SHIPMENT_REFERENCE,

                        CONSIGNEE_CONTACT: value.CONSIGNEE_CONTACT,
                        CONSIGNEE_COMPANY: value.CONSIGNEE_COMPANY,
                        CONSIGNEE_ADDRESS1: value.CONSIGNEE_ADDRESS1,
                        CONSIGNEE_ADDRESS2: value.CONSIGNEE_ADDRESS2,
                        CONSIGNEE_ADDRESS3: value.CONSIGNEE_ADDRESS3,
                        CONSIGNEE_CITY: value.CONSIGNEE_CITY,
                        CONSIGNEE_POSTALCODE: value.CONSIGNEE_POSTALCODE,
                        CONSIGNEE_STATE: value.CONSIGNEE_STATE,
                        CONSIGNEE_COUNTRYID: value.CONSIGNEE_COUNTRYID,
                        CONSIGNEE_PHONE: value.CONSIGNEE_PHONE,

                        RETURN_COMPANY: value.RETURN_COMPANY,
                        RETURN_CONTACT: value.RETURN_CONTACT,
                        RETURN_ADDRESS1: value.RETURN_ADDRESS1,
                        RETURN_ADDRESS2: value.RETURN_ADDRESS2,
                        RETURN_CITY: value.RETURN_CITY,
                        RETURN_STATE: value.RETURN_STATE,
                        RETURN_POSTALCODE: value.RETURN_POSTALCODE,
                        RETURN_PHONE: value.RETURN_PHONE,

                        SHIPDATE: value.SHIPDATE,
                        SHIPMENTHOLD_FLAG: value.SHIPMENTHOLD_FLAG,
                        CONSIGNEE_RESIDENTIAL_FLAG: value.CONSIGNEE_RESIDENTIAL_FLAG,
                        PACKAGES: value.PACKAGES,
                        CARRIER: value.CARRIER,
                        SERVICE: value.SERVICE,
                        REASON: value.REASON,
                        WEIGHT_UNIT: value.WEIGHT_UNIT,

                        CUSTOMER_EMAIL: value.CUSTOMER_EMAIL,
                        CUSTOMER_SEND_EMAIL: value.CUSTOMER_SEND_EMAIL,
                        STORE_EMAIL: value.STORE_EMAIL,
                        STORE_SEND_EMAIL: value.STORE_SEND_EMAIL,
                        PREMIUM_REASONCODE: value.PREMIUM_REASONCODE ? value.PREMIUM_REASONCODE : "",
                        PREMIUM_REASONTEXT: value.PREMIUM_REASONTEXT ? value.PREMIUM_REASONTEXT : ""
                    }
                }
                payLoad.push(_obj);
            });
            return payLoad;
        }
        return {
            ACTION: Constants.PROCESS_FULFILLMENT.ACTIONS.CREATE_SHIPMENT,
            PAYLOAD: {
                APP_ID: Constants.PROCESS_FULFILLMENT.APP_ID,
                EMPLOYEE_ID: empId,//"986732",
                SHIPPER: shipper,//"G220",
                SHIPPER_SHIPMENT_REFERENCE: data.SHIPPER_SHIPMENT_REFERENCE,

                CONSIGNEE_CONTACT: data.CONSIGNEE_CONTACT,
                CONSIGNEE_COMPANY: data.CONSIGNEE_COMPANY,
                CONSIGNEE_ADDRESS1: data.CONSIGNEE_ADDRESS1,
                CONSIGNEE_ADDRESS2: data.CONSIGNEE_ADDRESS2,
                CONSIGNEE_ADDRESS3: data.CONSIGNEE_ADDRESS3,
                CONSIGNEE_CITY: data.CONSIGNEE_CITY,
                CONSIGNEE_POSTALCODE: data.CONSIGNEE_POSTALCODE,
                CONSIGNEE_STATE: data.CONSIGNEE_STATE,
                CONSIGNEE_COUNTRYID: data.CONSIGNEE_COUNTRYID,
                CONSIGNEE_PHONE: data.CONSIGNEE_PHONE,

                RETURN_COMPANY: data.RETURN_COMPANY,
                RETURN_CONTACT: data.RETURN_CONTACT,
                RETURN_ADDRESS1: data.RETURN_ADDRESS1,
                RETURN_ADDRESS2: data.RETURN_ADDRESS2,
                RETURN_CITY: data.RETURN_CITY,
                RETURN_STATE: data.RETURN_STATE,
                RETURN_POSTALCODE: data.RETURN_POSTALCODE,
                RETURN_PHONE: data.RETURN_PHONE,

                SHIPDATE: data.SHIPDATE,
                SHIPMENTHOLD_FLAG: data.SHIPMENTHOLD_FLAG,
                CONSIGNEE_RESIDENTIAL_FLAG: data.CONSIGNEE_RESIDENTIAL_FLAG,
                PACKAGES: data.PACKAGES,
                CARRIER: data.CARRIER,
                SERVICE: data.SERVICE,
                REASON: data.REASON,
                WEIGHT_UNIT: data.WEIGHT_UNIT,

                CUSTOMER_EMAIL: data.CUSTOMER_EMAIL,
                CUSTOMER_SEND_EMAIL: data.CUSTOMER_SEND_EMAIL,
                STORE_EMAIL: data.STORE_EMAIL,
                STORE_SEND_EMAIL: data.STORE_SEND_EMAIL,
                PREMIUM_REASONCODE: data.PREMIUM_REASONCODE ? data.PREMIUM_REASONCODE : "",
                PREMIUM_REASONTEXT: data.PREMIUM_REASONTEXT ? data.PREMIUM_REASONTEXT : ""
            }
        }
    }

    public static parseCarrierServices = (data: any[]) => {
        let def: any = {},
            results: any[] = [],
            carriers: string[] = [],
            defloaded: boolean = false;

        data.forEach((r: any) => {
            if (!carriers.includes(r.Carrie)) { carriers.push(r.Carrie); }
            if (r.Default === 'true' && !defloaded) { def = r; defloaded = true; }
        });

        carriers.forEach((r: string) => {
            let result: any = {};
            result['Title'] = r;
            result['Services'] = data
                .filter((e: any) => e.Carrie === r)
                .map((e: any) => ({
                    price: '..',
                    name: e.Name !== undefined && e.Name !== null ? e.Name : "",
                    service: e.Service !== undefined && e.Service !== null ? e.Service : "",
                    SaturdayDelivery: e.SaturdayDelivery !== undefined ? e.SaturdayDelivery : "true",
                    Company: e.Company != null || e.Company !== undefined ? e.Company : "",
                    DefaultReturn: e.DefaultReturn != null || e.DefaultReturn !== undefined ? e.DefaultReturn : "",
                    //MAAReturnDefault: e.MAAReturnDefault != null || e.MAAReturnDefault !== undefined ? e.MAAReturnDefault: "",
                    //GCReturnDefault: e.GCReturnDefault != null || e.GCReturnDefault !== undefined ? e.GCReturnDefault: ""
                }));
            results.push(result);
        });
        console.log(results, "parseCarrierServices");
        console.log(def, "def");
        return [results, def, data];
    }

    public static parseData_CreateShipment = (data: any, box?: any) => {

        let _date = moment(data.shipDate).format('YYYY-MM-DD');

        let storeName = '';
        if (data.isDC) {
            let dc = StoreManager.DCList.find(r => r.id === data.shipToDetails.id);
            storeName = `${dc.StoreID} - ${dc.Description}`
        } else {
            let storeId = data.shipToDetails ? data.shipToDetails.id : "";
            if (storeId == undefined || storeId == null || storeId == "") {
                storeName = "";
                if (data.formType === 'ReturnLabel') {
                    let currentUserStoreInfo = StoreManager.getStoreDetails_local(IdentityManager.storeNumber);
                    storeId = currentUserStoreInfo.id;
                }

            }

            storeName = `Guitar Center Store ${Utils.convertNullUndefinedToEmptyString(storeId)}`;
        }

        console.log(storeName, "storeName_parseData_CreateShipment");

        let customer = data.formType === 'Customer/Vendor' ? data.customer : storeName;
        let returnContact = data.formType === 'ReturnLabel' ? data.customer : `Store ${Utils.convertNullUndefinedToEmptyString(data.shipFromDetails.id)}`;
        let carrier = data.multiBox ? box.carrier : data.carrier; //PFManager.carrierServiceData[parseInt(data.carrier)].Title;
        let service = data.multiBox ? box.service : data.service; // PFManager.carrierServiceData[parseInt(data.carrier)].Services[parseInt(data.service)].service;
        let consigneeCompany = data.formType === 'Customer/Vendor' ? data.consigneeCompany : data.formType === 'ReturnLabel' ? `Guitar Center ${data.shipToDetails.City}` : ""

        let requestData: any = {
            SHIPPER_SHIPMENT_REFERENCE: data.shipperRef,//"GC10078003",
            CONSIGNEE_CONTACT: customer,
            CONSIGNEE_COMPANY: consigneeCompany,
            CONSIGNEE_ADDRESS1: data.shipToDetails.Address,
            CONSIGNEE_ADDRESS2: data.shipToDetails.Address2,
            CONSIGNEE_ADDRESS3: "",
            CONSIGNEE_CITY: data.shipToDetails.City,
            CONSIGNEE_POSTALCODE: data.shipToDetails.Zip,
            CONSIGNEE_STATE: data.shipToDetails.State,
            CONSIGNEE_COUNTRYID: "US",
            CONSIGNEE_PHONE: data.shipToDetails.Phone,
            SHIPDATE: _date,
            RETURN_COMPANY: data.formType === 'ReturnLabel' ? "GC Return" : `Guitar Center ${data.shipFromDetails.City}`,
            RETURN_CONTACT: returnContact,
            RETURN_ADDRESS1: data.shipFromDetails.Address,
            RETURN_ADDRESS2: data.shipFromDetails.Address2,
            RETURN_CITY: data.shipFromDetails.City,
            RETURN_STATE: data.shipFromDetails.State,
            RETURN_POSTALCODE: data.shipFromDetails.Zip,
            RETURN_PHONE: data.shipFromDetails.Phone,
            SHIPMENTHOLD_FLAG: data.flags.SHIPMENTHOLD_FLAG ? "1" : "0",
            CONSIGNEE_RESIDENTIAL_FLAG: data.multiBox ? (box.resFlag ? "1" : "0") : (data.flags.IS_RESIDENTIAL ? "1" : "0"),
            PACKAGES: [],
            CARRIER: carrier,
            SERVICE: service,
            REASON: data.reason
        }

        // if (StoreManager.config.multiPackage === "true") {
        //     data.packages.forEach((r: any) => 
        //         requestData.PACKAGES.push({
        //             WEIGHT: r.weight,
        //             DIMENSION: r.dimension,
        //             PROOF_FLAG: data.flags.PROOF_FLAG ? "1":"0",
        //             PROOF_SIGNATURE_FLAG: data.flags.PROOF_SIGNATURE_FLAG ? "1":"0",
        //             SATURDAYDELIVERY_FLAG: data.flags.SATURDAYDELIVERY_FLAG ? "1":"0"
        //         })
        //     );
        // } 
        if (data.multiBox && box) {
            requestData.PACKAGES.push({
                WEIGHT: box.weight,
                DIMENSION: box.dimension,
                PROOF_FLAG: box.proofFlag ? "1" : "0",
                PROOF_SIGNATURE_FLAG: box.proofSigFlag ? "1" : "0",
                SATURDAYDELIVERY_FLAG: box.satDeliveryFlag ? "1" : "0"
            })
        }
        else {
            let weight = data.weightUnit === 'lb' ? data.weight : (parseInt(data.weight) / 16) + "";
            requestData.PACKAGES.push({
                WEIGHT: weight,
                DIMENSION: data.dimension,
                PROOF_FLAG: data.flags.PROOF_FLAG ? "1" : "0",
                PROOF_SIGNATURE_FLAG: data.flags.PROOF_SIGNATURE_FLAG ? "1" : "0",
                SATURDAYDELIVERY_FLAG: data.flags.SATURDAYDELIVERY_FLAG ? "1" : "0"
            })
        }

        if (data.formType === 'ReturnLabel') {
            // {customer_email: '', store_email: '', sendMail_customer: true, sendMail_store: true}
            requestData.CUSTOMER_EMAIL = data.returnLabelData.customer_email;
            requestData.CUSTOMER_SEND_EMAIL = data.returnLabelData.sendMail_customer;
            requestData.STORE_EMAIL = data.returnLabelData.store_email;
            requestData.STORE_SEND_EMAIL = data.returnLabelData.sendMail_store;
        }
        requestData.PREMIUM_REASONCODE = data.reason;
        requestData.PREMIUM_REASONTEXT = data.reasonText
        requestData.WEIGHT_UNIT = data.weightUnit
        return requestData;
    }

    public static parseApprovalLoggingPayload = (payload: any, response:any, profile: any) => {
        let puid = [];
        let trackingNo = [];
        if(response?.PACKAGES?.PACKAGE.length>0)
        {
            response.PACKAGES.PACKAGE.forEach((value:any) => {
                puid.push(value.PUID);
                trackingNo.push(value.CARRIER_PACKAGE_REFERENCE)
            });
        }
        else{
            puid.push(response?.PACKAGES?.PACKAGE?.PUID ?? "");
            trackingNo.push(response?.PACKAGES?.PACKAGE?.CARRIER_PACKAGE_REFERENCE ?? "");
        }

        const labelInfo = {
            puid: puid,
            trackingNo: trackingNo,
            carrier: payload.CARRIER,
            service: payload.SERVICE,
            dimension: payload.PACKAGES[0].DIMENSION,
            weight: payload.PACKAGES[0].WEIGHT,
            weightUnit: payload.WEIGHT_UNIT ?? ""
        }

        let payLoad = {
            labelCreatorId: IdentityManager.getUserProperty(Constants.employeeid) ?? "",
            labelCreatorUserName: IdentityManager.getUserProperty(Constants.username) ?? "",
            approvalDate: Utils.currentDate.toISOString(),
            shipper: payload.SHIPPER,
            shipperReference: payload.SHIPPER_SHIPMENT_REFERENCE,
            suid: response.SUID,
            consigneeAddress1: payload.CONSIGNEE_ADDRESS1,
            consigneeAddress2: payload.CONSIGNEE_ADDRESS2,
            returnAddress1: payload.RETURN_ADDRESS1,
            returnAddress2: payload.RETURN_ADDRESS2,
            labelInfo: labelInfo,
            approvalProfile: profile,
            isByManager: payload.isByManager
        } as any
        if (payload.PREMIUM_REASONCODE && payload.PREMIUM_REASONTEXT) {
            payLoad.PREMIUM_REASONCODE = payload.PREMIUM_REASONCODE
            payLoad.PREMIUM_REASONTEXT = payload.PREMIUM_REASONTEXT
        }

        return payLoad;
    }

    public static getBody_Email_Data = (data: any, email: string, pdfData: any, trackingNo: string, storeCopy: boolean) => {
        let ad1 = data.shipToDetails.Address !== undefined && data.shipToDetails.Address !== null ? data.shipToDetails.Address : '';
        let ad2 = data.shipToDetails.Address2 !== undefined && data.shipToDetails.Address2 !== null ? data.shipToDetails.Address2 : '';
        let city = data.shipToDetails.City !== undefined && data.shipToDetails.City !== null ? data.shipToDetails.City : '';
        let id = data.shipToDetails.id !== undefined && data.shipToDetails.id !== null ? data.shipToDetails.id : '';
        let ccStoreEmail = PFUtils.getStoreReturnEmail(data);

        let body: any = {};
        body.ReferenceNo = data.shipperRef;
        body.PDFData = pdfData;
        body.CustomerEmail = email;
        body.TrackingNo = trackingNo;
        body.Store = {
            Name: `${id} - ${city}`,
            Address: `${ad1}, ${ad2}`,
            City: city,
            State: data.shipToDetails.State !== undefined && data.shipToDetails.State !== null ? data.shipToDetails.State : '',
            ZIP: data.shipToDetails.Zip !== undefined && data.shipToDetails.Zip !== null ? data.shipToDetails.Zip : '',
            Phone: data.shipToDetails.Phone !== undefined && data.shipToDetails.Phone !== null ? data.shipToDetails.Phone : '',
            Email: storeCopy ? ccStoreEmail : ""
        }
        return body;
    }

    public static getStoreReturnEmail(data: any) {
        let ccStoreEmail = "";
        if (data) {
            if (data.returnLabelData && data.returnLabelData.store_email.length > 0) {
                ccStoreEmail = data.returnLabelData.store_email;
            }
            else if (data.shipToDetails && data.shipToDetails.StoreEmail.length > 0) {
                ccStoreEmail = data.shipToDetails.StoreEmail;
            }
        }

        return ccStoreEmail;

    }


}


