import React from 'react';
import './Dialogs.scss';

import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Divider, Slide } from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import LabelUtils from '../../Common/Label.Utils';


import { FaUps, FaFedex, FaUsps } from "react-icons/fa";
import moment from 'moment';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
  ) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function LabelDialog(props: any): any {

  console.log(props.selectedLabel)
    
  const selectCarrierIcon = (_carrier: string) => {
    _carrier = _carrier.toLowerCase();
    switch (_carrier) {
      case 'ups':
        return <FaUps style={{fontSize: '40px'}} title="UPS"/>;
      case 'usps':
        return <FaUsps style={{fontSize: '40px'}}  title="USPS"/>;
      case 'fedex':
        return <FaFedex style={{fontSize: '40px'}}  title="FedEx"/>;
      default:
        return null;
    }
  }
  
  const checkField = (field: any) => field !== undefined && field !== null && field !== '';

  const closeDialogBox = () => props.changeState({labelDialog: false, selectedLabel: {}});

  const renderCarrierInfo = () => {
    return (
      <div className='labelRow'>
      {
        checkField(props.selectedLabel.Carrier) &&
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-around'}}>
          <div style={{width: 'auto', textAlign: 'center'}}>{props.selectedLabel.Carrier}</div>
          {selectCarrierIcon(props.selectedLabel.Carrier)}
        </div>
      }

      {
        checkField(props.selectedLabel.Service) &&
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-around'}}>
          <div style={{width: 'auto', textAlign: 'center'}}>Service</div>
          <div style={{width: 'auto', textAlign: 'center', minHeight: '40px', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>{props.selectedLabel.Service}</div>
        </div>
      }

      {
        checkField(props.selectedLabel.ConsigneeResidentialFlag) &&
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-around'}}>
          <div style={{width: 'auto', textAlign: 'center'}}>Is Residential</div>
          <div style={{width: 'auto', textAlign: 'center', minHeight: '40px', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>{props.selectedLabel.ConsigneeResidentialFlag === '0' ? 'No':'Yes'}</div>
        </div>
      }
      </div>
    )
  }

  const renderShipmentData = () => {
    let service: any = props.selectedLabel.ServiceResponse;
    let _package: any = props.selectedLabel.PayLoad !== undefined ? props.selectedLabel.PayLoad.PACKAGES[0] : undefined;

    let localDate = moment(props.selectedLabel.LogTime).toLocaleString();
    let createDate = moment(localDate).format('LLL');

    let weight = _package !== undefined ? LabelUtils.getProperty.numeric(props.selectedLabel.PayLoad.PACKAGES, 'WEIGHT') : 0;
    let trackingNumbers: any[] = service !== undefined ? LabelUtils.getProperty.trackingNo(service) : [];
    let address: any[] = _package !== undefined ? LabelUtils.getProperty.address(props.selectedLabel.PayLoad) : [''];
    
    return (
      <div className='labelColumn'>

        {renderCarrierInfo()}

        {/* {
          checkField(props.selectedLabel.Carrier) &&
          <div className='labelRow'>
            <div>Carrier</div>
            <div>{props.selectedLabel.Carrier}</div>
          </div>
        }

        {
          checkField(props.selectedLabel.Service) &&
          <div className='labelRow'>
            <div>Service</div>
            <div>{props.selectedLabel.Service}</div>
          </div>
        }

        {
          checkField(props.selectedLabel.ConsigneeResidentialFlag) &&
          <div className='labelRow'>
            <div>Is Residential</div>
            <div>{props.selectedLabel.ConsigneeResidentialFlag === '0' ? 'No':'Yes'}</div>
          </div>
        } */}

        <Divider />
        
        {
          checkField(props.selectedLabel.REASON) &&
          <div className='labelRow'>
            <div>Reason Code</div>
            <div>{props.selectedLabel.REASON}</div>
          </div>
        }

        {
          checkField(props.selectedLabel.Date) &&
          <div className='labelRow'>
            <div>Ship Date</div>
            <div>{props.selectedLabel.Date}</div>
          </div>
        }
        
        {
          checkField(props.selectedLabel.LogTime) &&
          <div className='labelRow'>
            <div>Created Date/Time</div>
            <div>{createDate}</div>
          </div>
        }

        {
          checkField(props.selectedLabel.Store) &&
          <div className='labelRow'>
            <div>Created at Store</div>
            <div>{props.selectedLabel.Store}</div>
          </div>
        }

        {
          checkField(props.selectedLabel.EmployeeID) &&
          <div className='labelRow'>
            <div>Created by Employee Id</div>
            <div>{props.selectedLabel.EmployeeID}</div>
          </div>
        }

        <Divider />

        {
          _package !== undefined && checkField(_package.DIMENSION) &&
          <div className='labelRow'>
            <div>Dimension</div>
            <div>{_package.DIMENSION}</div>
          </div>
        }

        {
          _package !== undefined && checkField(_package.WEIGHT) &&
          <div className='labelRow'>
            <div>Weight</div>
            <div>{weight} lb</div>
          </div>
        }

        <Divider />
        
        {
          service !== undefined && checkField(service.SHIPPER_SHIPMENT_REFERENCE) &&
          <div className='labelRow'>
            <div>Shipper Shipment Reference</div>
            <div>{service.SHIPPER_SHIPMENT_REFERENCE}</div>
          </div>
        }
        
        {
          trackingNumbers.length > 0 && 
          <div className='labelRow'>
            <div>Tracking Number</div>
            <div>{trackingNumbers.map((data: any, i: number) => `${data}\n`)}</div>
          </div>
        }
        
        {
          service !== undefined && checkField(service.SUID) &&
          <div className='labelRow'>
            <div>SUID</div>
            <div>{service.SUID}</div>
          </div>
        }

        <Divider />
        
        {
          _package !== undefined && checkField(_package.WEIGHT) &&
          <div className='labelRow'>
            <div>Consignee Address</div>
            <div style={{display: 'flex', flexDirection: 'column'}}>{address.map((data: any, i: number) => <span>{data}</span>)}</div>
          </div>
        }

      </div>
    );
  }

  return (
    <Dialog open={props.open} onClose={() => {closeDialogBox()}} TransitionComponent={Transition}>
      <DialogTitle className="itemDialogBorder"><div className="dialogHeader">Label Details</div></DialogTitle>
      <DialogContent className="itemDialogBorder">
        {props.selectedLabel === null ? null : renderShipmentData()}
      </DialogContent>
      <DialogActions>
        <Button size="small" onClick={() => {closeDialogBox()}} variant="contained" color="primary">
          <span className="dialogButton">Close</span>
        </Button>
      </DialogActions>
    </Dialog>
  );
}