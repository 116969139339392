import React from 'react';
import './Routes.scss';

import Loader from 'react-loader-spinner';
import PFManager from '../Managers/Process.Fulfillment.Manager';
import CreateLabelForm from '../Subcomponent/CreateLabel/CreateLabelForm';
import DialogBox from '../Subcomponent/Dialog/DialogBox';
import CacheBuster from '../Managers/Cache.Buster';
import PFServicesManager from '../Managers/Fulfillment.Services.Manager';

export default class CreateLabel extends React.Component<any, {}> {

    public state = {
        loading: false,
        openDialog: false,
        dialogText: {Title: '', Msg: ''},  
    }

    constructor(props: any) {
        super(props);

        this.changeState = this.changeState.bind(this);
    }

    componentDidMount = async () => {
        await CacheBuster.checkVersion();
        if (PFManager.carrierServiceData.length === 0) {
            //Use Shipping API or Lambda for PF Services
            if(PFManager.shippingAPI){
                await PFServicesManager.getActionServices();
            }
            else{ 
                await PFManager.getCarrierServices();
            }
        }
    }

    changeState = (_state: any) => this.setState(_state)

    // RENDER METHODS

    render = () => {
        let height = getComputedStyle(document.getElementsByTagName('body')[0]).height;
        let isReturn = window.location.search.includes('r=1');
        return (
            <div className='innerContianer'>
                <DialogBox
                    text={this.state.dialogText}
                    open={this.state.openDialog}
                    handleClose={() => {this.setState({openDialog: false})}}
                />
                {
                    this.state.loading &&
                    <div className='overlay' style={{height:height}}>
                        <div className='loader'>
                            <Loader type="TailSpin" color="#D93A2F" height={80} width={80}/>
                        </div>
                    </div>
                }
                <div className='primaryRow'>
                    <div className='headerText'>
                        {isReturn ? 'Create a return Label' : 'Create a shipment Label'}
                        {/* <div><CustomButton>Distribution List</CustomButton></div> */}
                    </div>
                </div>
                {<CreateLabelForm changeState={this.changeState} history={this.props.history}/>}
            </div>
        );
    }
}