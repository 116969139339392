import React from 'react';
import './Routes.scss';

import DialogBox from '../Subcomponent/Dialog/DialogBox';
import LabelDialog from '../Subcomponent/Dialog/LabelDialog';
import VoidLabelDialog from '../Subcomponent/Dialog/VoidDialog';
import ReturnLabelTable from '../Subcomponent/ReturnLabels/ReturnLabelTable';

import { withStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';

import { FaEraser, FaPrint } from "react-icons/fa";
import MultiVoidDialog from '../Subcomponent/Dialog/MultiVoidDialog';
import Utils from '../Common/Utils';
import MultiPrintDialog from '../Subcomponent/Dialog/MultiPrintDialog';
import CacheBuster from '../Managers/Cache.Buster';
import StoreManager from '../Managers/Store.manager';

const CreateButton = withStyles(({
  root: {
    color: 'white',
    fontWeight: 600,
    backgroundColor: "#D93A2F",
    '&:hover': {backgroundColor: "#bd3627"},
  },
}))(Button);

export default class ReturnLabels extends React.Component<any, {}> {

  public state = {
    searchTerm: '',
    multiVoid: false,
    multiPrint: false,
    dataLoaded: true,
    openDialog: false,
    labelDialog: false,
    selectedLabel: null,
    bulkProcessLabels: [],
    voidLabelDialog: false,
    dialogText: {Title: '', Msg: ''},
  }

  constructor(props: any) {
    super(props);
    
    this.changeState = this.changeState.bind(this);
  }

  componentDidMount = async () => {
    await CacheBuster.checkVersion();
    await StoreManager.getAllSCStores();
    this.setState({selectedLabel: null, labelDialog: false, bulkProcessLabels: []})
  }

  changeState = (data: any) => this.setState(data);

  BulkVoid = () => this.setState({multiVoid: true});

  BulkPrint = () => this.setState({multiPrint: true});

  handleDateChange = (date: Date | null) => console.log(date);

  updateSearchTerm = (e: any) => this.setState({searchTerm: e.target.value});


  // RENDER METHODS

  render = () => {
    return (
      <div className='innerContianer'>
        
        <LabelDialog
          open={this.state.labelDialog}
          changeState={this.changeState}
          selectedLabel={this.state.selectedLabel}
        />
        <VoidLabelDialog
          changeState={this.changeState}
          open={this.state.voidLabelDialog}
          selectedLabel={this.state.selectedLabel}
        />
        <DialogBox 
          text={this.state.dialogText}
          open={this.state.openDialog}
          handleClose={() => {this.setState({openDialog: false})}}
        />
        <MultiVoidDialog
          open={this.state.multiVoid}
          changeState={this.changeState}
          labels={this.state.bulkProcessLabels}
        />
        <MultiPrintDialog
          open={this.state.multiPrint}
          changeState={this.changeState}
          labels={this.state.bulkProcessLabels}
        />

        <div className='primaryRow'>
          <CreateButton onClick={() => {this.props.history.push('/CreateLabel?r=1')}}>Create Return Label</CreateButton>

          <div>
            {
              !Utils.isMobile && this.state.bulkProcessLabels.length > 0 &&
              <CreateButton onClick={this.BulkPrint} style={{marginRight: '5px'}}>
                <FaPrint style={{marginRight: '5px'}}/> Print Selected
              </CreateButton>
            }
            {
              !Utils.isMobile && this.state.bulkProcessLabels.length > 0 &&
              <CreateButton onClick={this.BulkVoid}>
                <FaEraser style={{marginRight: '5px'}}/> Void Selected
              </CreateButton>
            }

            <input 
              type="text"
              className="searchBar"
              placeholder="Search..."
              value={this.state.searchTerm}
              onChange={this.updateSearchTerm}
              style={{marginLeft: '10px', width: Utils.isMobile ? '150px' : '300px'}}
            />
          </div>
        </div>

        
        {
          Utils.isMobile &&
          <div>
            {
              this.state.bulkProcessLabels.length > 0 &&
              <CreateButton onClick={this.BulkPrint} style={{marginRight: '5px'}}>
                <FaPrint style={{marginRight: '5px'}}/> Print Selected
              </CreateButton>
            }
            {
              this.state.bulkProcessLabels.length > 0 &&
              <CreateButton onClick={this.BulkVoid}>
                <FaEraser style={{marginRight: '5px'}}/> Void Selected
              </CreateButton>
            }
          </div>
        }

        {
          this.state.dataLoaded &&
          <ReturnLabelTable
            changeState={this.changeState}
            searchTerm={this.state.searchTerm}
          />
        }
      </div>
    );
  }
}