import IdentityManager from "./Identity.manager";
import PFUtils from "../Common/Process.Fulfillment.Utils";
import StoreManager from "./Store.manager";
import Constants from "../Common/Constants"
export default class PFManager {

    public static default: any = null;
    public static labelData: any = [];
    public static reasonCodes: any[] = [];
    public static carrierServiceData: any = [];
    public static shippingGroups: any[] = [];
    public static unGroupedCarrierServiceData: any[] = [];
    public static configs: any[] = [];
    public static shippingAPI: any = false;

    public static getCarrierServices = async () => {
        let token = IdentityManager.AccessToken;
        let body = { "ACTION": "GET_SERVICES" };
        let _res = await fetch(
            `/pf/sendRequest`,
            {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            }
        );
        if (_res.status !== 500 && _res.status !== 400) {
            let res = await _res.json();
            let [results, def, data] = PFUtils.parseCarrierServices(res.response.data);
            PFManager.carrierServiceData = results;
            PFManager.default = def;
            PFManager.unGroupedCarrierServiceData = data;
        }
    }

    public static getCodes = async () => {
        let token = IdentityManager.AccessToken;
        let body = { "ACTION": "GET_CODES" };
        let _res = fetch(
            `/pf/sendRequest`,
            {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            }
        )
            .then(async (response) => {
                let res: any = await response.json();
                PFManager.reasonCodes = res.response.data;
                const premiumReasonCodes = JSON.parse(PFManager.configs.find((x: any) => x.key == Constants.Configs.PremiumReasons)?.value);
                if(premiumReasonCodes?.length>0)
                {
                    PFManager.reasonCodes.push(...premiumReasonCodes);
                }
            })
            .catch(error => console.log('error', error));
    }

    public static getRecommendedService = async (state: string, weight: string, Company: any) => {
        let token = IdentityManager.AccessToken;
        let body = {
            "ACTION": "GET_SERVICE_RECOMMENDATION",
            "PAYLOAD": {
                "STATE": state,
                "WEIGHT": weight,
                "COMPANY": Company
            }
        };
        let result = null;
        try {
            let _res = await fetch(
                `/pf/sendRequest`,
                {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(body)
                }
            )
            let res: any = await _res.json();
            result = res;
        } catch (error) {
            console.log(error);
        }
        return result;
    }

    public static getLabels = async (params: any, word: string) => {
        let token = IdentityManager.AccessToken;
        let total: number = 0, _result: any = {};
        let body = {
            word: word,
            app: params.app,
            status: params.status,
            carrier: params.carrier,
            service: params.service,
            store: params.storeNumber,
            to: params.date.To,
            from: params.date.From,
            psize: params.pagination.psize,
            pfrom: params.pagination.pfrom,
            sortField:params.sortField,
            sortOrder:params.sortOrder
        }
        try {
            let _res = await fetch(
                `/pf/getLabels`,
                {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(body)
                }
            );
            let result = await _res.json();
            if (_res.status !== 500 && _res.status !== 400) {
                total = result.hits.total.value
                _result['data'] = result.hits.hits.map((r: any) => ({ ...r._source, isSelected: false, isVoided: false }));
                _result['aggregations'] = result.aggregations;
                PFManager.labelData = _result;
            } else {
                Object.assign(_result, result);
            }
            _result['status'] = _res.status;
        } catch (error) {
            console.log(error);
        }
        return [total, _result];
    }

    public static getReturnLabels = async (store: any, date_To: any, date_From: any) => {
        let token = IdentityManager.AccessToken;
        let _result: any = {};
        if (store && store.length > 0) {
            store = StoreManager.getSoreShipperPreFixByStoreId(store) + store;
        }

        try {
            let _res = await fetch(
                `/pf/getReturnLabels?store=${store}&to=${date_To}&from=${date_From}`,
                { headers: { 'Authorization': `Bearer ${token}` } }
            );
            let result = await _res.json();
            // let result: any = {"method":"Send Process Fulfillment Request","details":{"message":"socket hang up","name":"Error","stack":"Error: socket hang up\n    at createHangUpError (_http_client.js:322:15)\n    at TLSSocket.socketOnEnd (_http_client.js:425:23)\n    at TLSSocket.emit (events.js:187:15)\n    at TLSSocket.EventEmitter.emit (domain.js:442:20)\n    at endReadableNT (_stream_readable.js:1094:12)\n    at process._tickCallback (internal/process/next_tick.js:63:19)","config":{"url":"https://oz9wlboq0m.execute-api.us-west-2.amazonaws.com/Dev/ProcessFullFillment","method":"post","data":"{\"ACTION\":\"GET_SERVICES\"}","headers":{"Accept":"application/json, text/plain, */*","Content-Type":"application/json","x-api-key":"4aCaXj3rpw20LJDjjjuip7FJs1gjrVI08PqQBoN9","User-Agent":"axios/0.21.1","Content-Length":25},"transformRequest":[null],"transformResponse":[null],"timeout":0,"xsrfCookieName":"XSRF-TOKEN","xsrfHeaderName":"X-XSRF-TOKEN","maxContentLength":-1,"maxBodyLength":-1},"code":"ECONNRESET"}};
            if (_res.status !== 500 && _res.status !== 400) {
                _result['data'] = result.hits.hits.map((r: any) => ({ ...r._source, isSelected: false, isVoided: false, isProcessing: false }));
                PFManager.labelData = _result;
            } else {
                Object.assign(_result, result);
            }
            _result['status'] = _res.status;
        } catch (error) {
            console.log(error);
        }
        return _result;
    }

    public static createShipment = async (shipper: string, data: any) => {
        //;
        let token = IdentityManager.AccessToken,
            empId = IdentityManager.getUserProperty(Constants.employeeid);

        if (!empId) {
            empId = IdentityManager.getUserProperty(Constants.preferred_username);
        }
        let body: any = PFUtils.getBody_Create_Shipment(empId, shipper, data);
        if (window.location.search.includes('r=1')) {
            body.PAYLOAD.LOGTYPE = 'ReturnLabel';
        }

        let _result: any = null;
        try {
            let _res = await fetch(
                `/pf/sendRequest`,
                {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(body)
                }
            );
            _result = await _res.json();
        } catch (error) {
            console.log(error);
        }
        return _result;
    }

    public static rePrint = async (SUID: string, shipper: string) => {
        let token = IdentityManager.AccessToken;
        let empId = IdentityManager.getUserProperty(Constants.employeeid);
        if (!empId) {
            empId = IdentityManager.getUserProperty(Constants.preferred_username);
        }
        let body = PFUtils.getBody_RePrint(empId, SUID, shipper);
        let _result: any = {};
        try {
            let _res = await fetch(
                `/pf/sendRequest`,
                {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(body)
                }
            );
            if (_res.status !== 500 && _res.status !== 400) {
                _result = await _res.json();
            }
        } catch (error) {
            console.log(error);
        }
        return _result;
    }

    public static voidShipment = async (SUID: string, shipper: string) => {
        let token = IdentityManager.AccessToken;
        let empId = IdentityManager.getUserProperty(Constants.employeeid);
        if (!empId) {
            empId = IdentityManager.getUserProperty(Constants.preferred_username);
        }
        let body = PFUtils.getBody_Void_Shipment(empId, SUID, shipper);
        let _result: any = { response: { msg: '' } };
        try {
            let _res = await fetch(
                `/pf/sendRequest`,
                {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(body)
                }
            );
            if (_res.status !== 500 && _res.status !== 400) {
                _result = await _res.json();
            }
        } catch (error) {
            console.log(error);
        }
        return _result;
    }

    // public static getEODData = async (date_To: any, carrier: any, services: any) => {
    //     let token = IdentityManager.AccessToken;
    //     let _result: any = {};
    //     try {
    //         let _res = await fetch(
    //             `/pf/getEODData?store=${IdentityManager.storeNumber}&to=${date_To}&carrier=${carrier}&services=${services}`,
    //             {headers: {'Authorization': `Bearer ${token}`}}
    //         );
    //         let result = await _res.json();
    //         // let result: any = {"method":"Send Process Fulfillment Request","details":{"message":"socket hang up","name":"Error","stack":"Error: socket hang up\n    at createHangUpError (_http_client.js:322:15)\n    at TLSSocket.socketOnEnd (_http_client.js:425:23)\n    at TLSSocket.emit (events.js:187:15)\n    at TLSSocket.EventEmitter.emit (domain.js:442:20)\n    at endReadableNT (_stream_readable.js:1094:12)\n    at process._tickCallback (internal/process/next_tick.js:63:19)","config":{"url":"https://oz9wlboq0m.execute-api.us-west-2.amazonaws.com/Dev/ProcessFullFillment","method":"post","data":"{\"ACTION\":\"GET_SERVICES\"}","headers":{"Accept":"application/json, text/plain, */*","Content-Type":"application/json","x-api-key":"4aCaXj3rpw20LJDjjjuip7FJs1gjrVI08PqQBoN9","User-Agent":"axios/0.21.1","Content-Length":25},"transformRequest":[null],"transformResponse":[null],"timeout":0,"xsrfCookieName":"XSRF-TOKEN","xsrfHeaderName":"X-XSRF-TOKEN","maxContentLength":-1,"maxBodyLength":-1},"code":"ECONNRESET"}};
    //         if (_res.status !== 500 && _res.status !== 400) {
    //             _result['data'] = result.hits.hits.map((r: any) => ({...r._source}));
    //             PFManager.labelData = _result; 
    //         } else {
    //             Object.assign(_result, result);
    //         }
    //         _result['status'] = _res.status;
    //     } catch (error) {
    //         console.log(error);
    //     }
    //     return _result;
    // }


    public static getEODData = async (store: any, date_To: any, carrier: any) => {
        let token = IdentityManager.AccessToken;
        let _result: any = {};
        try {
            let _res = await fetch(
                `/pf/getEODData?store=${store}&to=${date_To}&carrier=${carrier}`,
                { headers: { 'Authorization': `Bearer ${token}` } }
            );
            let result = await _res.json();
            // let result: any = {"method":"Send Process Fulfillment Request","details":{"message":"socket hang up","name":"Error","stack":"Error: socket hang up\n    at createHangUpError (_http_client.js:322:15)\n    at TLSSocket.socketOnEnd (_http_client.js:425:23)\n    at TLSSocket.emit (events.js:187:15)\n    at TLSSocket.EventEmitter.emit (domain.js:442:20)\n    at endReadableNT (_stream_readable.js:1094:12)\n    at process._tickCallback (internal/process/next_tick.js:63:19)","config":{"url":"https://oz9wlboq0m.execute-api.us-west-2.amazonaws.com/Dev/ProcessFullFillment","method":"post","data":"{\"ACTION\":\"GET_SERVICES\"}","headers":{"Accept":"application/json, text/plain, */*","Content-Type":"application/json","x-api-key":"4aCaXj3rpw20LJDjjjuip7FJs1gjrVI08PqQBoN9","User-Agent":"axios/0.21.1","Content-Length":25},"transformRequest":[null],"transformResponse":[null],"timeout":0,"xsrfCookieName":"XSRF-TOKEN","xsrfHeaderName":"X-XSRF-TOKEN","maxContentLength":-1,"maxBodyLength":-1},"code":"ECONNRESET"}};
            if (_res.status !== 500 && _res.status !== 400) {
                _result['data'] = result.hits.hits.map((r: any) => ({ ...r._source, isSelected: false, isVoided: false }));
                PFManager.labelData = _result;
            } else {
                Object.assign(_result, result);
            }
            _result['status'] = _res.status;
        } catch (error) {
            console.log(error);
        }
        return _result;
    }


    public static generateEmail = async (data: any, email: string, pdfData: any, trackingNo: string, storeCopy: boolean) => {
        //;
        let token = IdentityManager.AccessToken;
        let body = PFUtils.getBody_Email_Data(data, email, pdfData, trackingNo, storeCopy);
        let _result: any = { response: { msg: '' } };
        try {
            let _res = await fetch(
                `/pf/generateEmail`,
                {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(body)
                }
            );
            if (_res.status !== 500 && _res.status !== 400) {
                _result = await _res.json();
            }
        } catch (error) {
            console.log(error);
        }
        return _result;
    }

    public static getGroups = async () => {
        let token = IdentityManager.AccessToken;
        let body = { "ACTION": "GET_GROUPS" };
        let _res = await fetch(
            `/pf/sendRequest`,
            {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            }
        );
        if (_res.status !== 500 && _res.status !== 400) {
            let res = await _res.json();
            PFManager.shippingGroups = res.response.data;
            console.log(PFManager.shippingGroups, "PFManager.shippingGroups");
        }
    }

    public static getNotificationConfig = async () => {

        let token = IdentityManager.AccessToken;
        let body = { "ACTION": "GET_SHIPPING_CENTER_NOTIFICATION_CONFIG" };
        let _res = await fetch(
            `/pf/sendRequest`,
            {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            }
        );
        if (_res.status !== 500 && _res.status !== 400) {
            let res = await _res.json();
            if(res?.response?.data?.length>0)
            {
                PFManager.configs = res.response.data;
                //Configuration to check to either use Shipping API Project or Lambdas for PF Services
                let _result = PFManager.configs.find((x:any)=>x.key == Constants.Configs.ShippingAPI)?.value;
                PFManager.shippingAPI = _result?.toLowerCase() == "true" ? true : false;
            }
            return res?.response?.data;
            //return JSON.parse(res?.response?.data[0]?.value);

        }
    }

    public static exportToExcel = async (params: any) => {
        let token = IdentityManager.AccessToken;
        let _result : any = {};
        try {
            let _res = await fetch(
                `/pf/exportToExcel`,
                {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Accept': 'application/octet-stream',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(params)
                }
            );
            if (_res.status !== 500 && _res.status !== 400) {
               
                _result = await _res.blob();
                const link = document.createElement('a');
                const url = URL.createObjectURL(_result);
                const filename = "LabelData.xlsx";
                link.href = url;
                link.download = filename;
                link.click();
                URL.revokeObjectURL(url);
                document.body.removeChild(link);
            }
        } catch (error) {
            console.log("exportToExcel Error",error);
        }
        return _result;
    }
}