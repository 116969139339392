import React from 'react';
import './PreviousInfo.scss';
import Utils from '../../Common/Utils';
import StoreManager from '../../Managers/Store.manager';

import { BsPersonFill } from 'react-icons/bs';
import { MdAssignmentReturned } from 'react-icons/md';
import { FaStoreAlt, FaTruck, FaHome } from 'react-icons/fa';

export default function PreviousInfo(props: any): any {

    const isFirstStep = props.formType === undefined || props.currentStore === undefined || props.selectedStore === undefined;
    
    const isCustomer = props.formType === 'Customer/Vendor';

    const isReturn = props.formType === 'ReturnLabel';

    const customer = props.customer !== undefined ? props.customer : 'N/A'

    let currentStore = {id: '', name: ''};
    if (props.currentStore !== undefined) {
        currentStore = props.currentStore;//StoreManager.storeList.find(r => r.id === props.currentStore.id);
    }

    let selectedStore = {id: '', name: ''};
    if (props.selectedStore !== undefined) {
        selectedStore = props.selectedStore;//StoreManager.storeList.find(r => r.id === props.selectedStore.id);
    }

    let Stype = props.formType !== 'ReturnLabel' ? `Store to ${props.formType}` : `Return Label`
    
    return (
        <div className='prevInfo'>
            <div className='title'><b>Label Informtion:</b></div>
            <div className='row'>

                <div className='section'>
                    <div className='icon'>{props.formType.includes('DC')?<FaStoreAlt/>:<BsPersonFill/>} </div>
                    <div className='text'>
                        <div><b>Shipment Type</b></div>
                        <div>{Stype}</div>
                    </div>
                </div>

                {
                    isFirstStep
                    ?
                    <div className='section nb'></div>
                    :
                    <div className='section'>
                        <div className='icon'><FaHome/></div>
                        <div className='text'>
                            <div><b>Is Residential</b></div>
                            <div>{props.isResidential?'Yes':'No'}</div>
                        </div>
                    </div>
                }


                {
                    isFirstStep
                    ?
                    !Utils.isMobile && <div className='section nb'></div>
                    :
                    <div className='section'>
                        <div className='icon'><FaTruck/></div>
                        <div className='text'>
                            <div><b>Shipped From</b></div>
                            <div>{isReturn ? customer : currentStore !== undefined ? `${currentStore.id} - ${currentStore.name}` : 'N/A'}</div>
                        </div>
                    </div>
                }

                
                {
                    isFirstStep
                    ?
                    !Utils.isMobile && <div className='section nb'></div>
                    :
                    <div className='section'>
                        <div className='icon'><MdAssignmentReturned/></div>
                        <div className='text'>
                            <div><b>Shipped To</b></div>
                            <div>{isCustomer ? customer : `${selectedStore.id} - ${selectedStore.name}`}</div>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
}
