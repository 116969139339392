import React from 'react';
import './CreateLabelViews.scss';

import Utils from '../../../Common/Utils';
import Constants from '../../../Common/Constants';
import LabelUtils from '../../../Common/Label.Utils';

import StoreManager from '../../../Managers/Store.manager';
import PFManager from '../../../Managers/Process.Fulfillment.Manager';
import PreviousInfo from '../../Shared/PreviousInfo';

import {
    Select, MenuItem, TextField, Button, OutlinedInput, InputAdornment, Divider,
    FormControlLabel, Checkbox, Tooltip, ButtonGroup, Switch, Grid, Card, CardContent, TableContainer, Paper, TableHead, TableRow, TableCell, TableBody, Table
} from '@material-ui/core';

import AddIcon from '@material-ui/icons/Add';
import ErrorIcon from '@material-ui/icons/Error';
import RemoveIcon from '@material-ui/icons/Remove';
import VerifyCredentials from '../../Dialog/VerifyCredentials';
import { toast } from 'react-toastify';
import PFServicesManager from '../../../Managers/Fulfillment.Services.Manager';
import Reasons from '../../Dialog/Reasons';


export default function ServiceInformation(props: any): any {

    console.log(props, "ServiceInformation");
    const multiPackage = StoreManager.config.multiPackage === "true";

    const [nextAttempt, setnextAttempt] = React.useState<boolean>(false);
    const [verifyCredsPopup, setVerifyCredsPopup] = React.useState<boolean>(false);
    const [reasonsPopup, setReasonsPopup] = React.useState<boolean>(false);
    const [reasonsRequired, setReasonsRequired] = React.useState<boolean>(false);
    const [reqBoxes, setReqBoxes] = React.useState([]);
    const [serviceName, setServiceName] = React.useState([]);
    const [] = React.useState<number>(0);
    const [] = React.useState<number>(0);
    const [] = React.useState<number>(0);

    const closeReasonsPopup = (e: any) => {
        setReasonsPopup(false)
    }

    const changeDropdown = (e: any) => {
        let _obj: any = {};
        _obj[e.target.name] = e.target.value;
        props.changeState(_obj)
    }


    const handleCarrierChange = (e: any) => {
        let _arr = e.target.value.split('-');
        let flags = { PROOF_FLAG: false, IS_RESIDENTIAL: false, SHIPMENTHOLD_FLAG: false, PROOF_SIGNATURE_FLAG: false, SATURDAYDELIVERY_FLAG: false };
        let _state = { carrier: _arr[0], service: _arr[1], serviceChanged: true, flags: flags };
        const _serviceName = PFManager.unGroupedCarrierServiceData.find(a => a.Carrie == _state.carrier && a.Service == _state.service)?.Name ?? "";
        if (!props.data.multiBox && !props.data.bypassCreds) {
            setRequiredCredentials(_state.carrier, _state.service);
        }
        setServiceName(_serviceName);
        props.changeState(_state);
        let filterCarrierService: any = PFManager.unGroupedCarrierServiceData.find(a => a.Carrie == _state.carrier && a.Service == _state.service);
        if (filterCarrierService?.Credentials == "true") {
            setReasonsRequired(true)
        }
        else {
            setReasonsRequired(false)
        }
    }

    const changeStep = (step: number) => {
        setnextAttempt(true);
        if (step === 3) {
            if (props.data.formType === 'ReturnLabel') {
                if (LabelUtils.validateStep2(props.data, step)) {
                    props.changeActiveStep(step);
                }
            } else {
                if (LabelUtils.validateStep2(props.data, step) && props.data.reason !== '0') {
                    getReqBoxes();
                    if (reasonsRequired) {
                        setReasonsPopup(true);
                    }
                    else {
                        props.changeActiveStep(step);
                    }
                }
            }
        } else {
            props.changeActiveStep(step);
        }
    }

    const handleFlagChange = (e: any) => {
        let flags = props.data.flags;
        flags[e.target.name] = e.target.checked;
        props.changeState({ flags: flags });
    }

    const changeText = (e: any) => props.changeState(LabelUtils.ServiceInfo_ChangeText(e))

    const changeWeight = async (e: any) => {
        console.log("changeWeight");
        let obj = LabelUtils.ServiceInfo_ChangeText(e);
        obj['recommendedService'] = false;
        props.changeState(obj)
        if (!multiPackage && !window.location.search.includes('r=1')) {
            if (obj.weight !== undefined && obj.weight !== '') {
                if (!isNaN(obj.weight)) {
                    let weight = props.weightUnit === 'lb' ? obj.weight : (parseInt(obj.weight) / 16) + "";
                    await getRecommendedService(weight);
                }
            }
        }
    }

    const setRequiredCredentials = (carrier: any, service: any) => {
        let filterCarrierService: any = PFManager.unGroupedCarrierServiceData.find(a => a.Carrie == carrier && a.Service == service);
        if (filterCarrierService?.Credentials == "true") {
            props.changeState({ requiredCreds: true, approvalProfile: null });
        }
        else {
            props.changeState({ requiredCreds: false, approvalProfile: null });
        }
    }

    const handleMultiBox = (e: any) => {
        console.log("Event Target Multibox", e.target.checked);
        if (!e.target.checked && !props.data.bypassCreds) {
            setRequiredCredentials(props.data.carrier, props.data.service);
        }
        props.changeState({ multiBox: e.target.checked, packages: [] });
    }

    const getRecommendedService = async (weight: string) => {
        let res: any = null;
        //Use Shipping API or Lambda for PF Services
        if (PFManager.shippingAPI) {
            let payload = {
                state: props.data.shipToDetails.State,
                weight: weight,
                company: props.data.shipFromDetails.Company
            };
            res = await PFServicesManager.getServiceRecommendation(payload);
        }
        else {
            res = await PFManager.getRecommendedService(props.data.shipToDetails.State, weight, props.data.shipFromDetails.Company);
        }

        let carrierServiceData = PFManager.carrierServiceData;
        console.log(carrierServiceData, "carrierServiceDatacarrierServiceData");
        console.log(res, "res");
        console.log(res.response.data.Carrier, "res.response.data.Carrier");
        console.log(res.response.data.Service, "res.response.data.Service");
        let obj = { target: { value: `${res.response.data.Carrier}-${res.response.data.Service}` } };
        console.log(obj, "objobj");
        handleCarrierChange(obj);
        props.changeState({ recommendedService: true });
    }

    const validateInput = (property: string) => LabelUtils.ServiceInfo_FieldValidate(nextAttempt, props.data, property)

    const modifyPackages = async (action: string, index: number) => {
        let packages: any[] = props.packages;
        if (action === 'remove') {
            packages.splice(index, 1);
            if (packages.length > 0 && !props.data.bypassCreds && !packages.some((box: any) => box.serviceCreds)) {
                props.changeState({ requiredCreds: false, approvalProfile: null });
            }
            props.changeState({ packages: packages });
        } else if (action === 'add') {
            if (props.weight !== '' && props.weight != '0' && props.dimension !== '0' && props.data.service != '0') {
                if (packages.length < 5) {
                    let servicefilterCarrierService: any = PFManager.unGroupedCarrierServiceData.find(a => a.Carrie == props.data.carrier && a.Service == props.data.service);
                    const serviceCreds = servicefilterCarrierService?.Credentials == "true" ? true : false;
                    packages.push({
                        dimension: props.dimension, weight: props.weight,
                        weightUnit: props.weightUnit,
                        carrier: props.data.carrier, service: props.data.service,
                        serviceName: servicefilterCarrierService.Name,
                        serviceCreds: serviceCreds,
                        saturdayFlag: props.data.flags.SATURDAYDELIVERY_FLAG,
                        proofFlag: props.data.flags.PROOF_FLAG,
                        proofSigFlag: props.data.flags.PROOF_SIGNATURE_FLAG,
                        resFlag: props.data.flags.IS_RESIDENTIAL
                    })
                    if (!props.data.bypassCreds && packages.some((box: any) => box.serviceCreds == true)) {
                        props.changeState({ requiredCreds: true, approvalProfile: null });
                    }
                    else {
                        props.changeState({ requiredCreds: false, approvalProfile: null });
                    }
                    props.changeState({ packages: packages, dimension: '0', weight: '' });
                }
                else {
                    let errorMsg = "You have reached the Maximum Limit for creating Boxes.";
                    props.openDialog({ openDialog: true, dialogText: { Title: 'info', Msg: errorMsg } });
                }

                // if (multiPackage) {
                //     if (props.weight !== undefined && props.weight !== '') {
                //         if (!isNaN(props.weight)) {
                //             let _weight: number = 0;
                //             packages.forEach((r: any) => _weight = _weight + parseFloat(r.weight))
                //             await getRecommendedService('' + _weight);
                //         }
                //     }
                // }
            }
        }
    }

    const changeWeightUnit = async (unit: string) => {
        props.changeState({ weightUnit: unit });
        let weight = unit === 'lb' ? props.weight : (parseInt(props.weight) / 16) + "";
        if (weight > 0) {
            await getRecommendedService(weight);
        }
    }

    const req = () => <span style={{ color: 'red' }}>*</span>

    const renderShiftButtons = () => {
        return (

            <div className='shiftButtons'>
                <Button variant="contained" onClick={() => { changeStep(1) }}>Back</Button>
                <Button variant="contained" color="primary" style={{ marginLeft: '10px' }} onClick={() => { changeStep(3) }}>Next</Button>
            </div>
        );
    }



    const renderCarriers = () => {

        let defVal = `${props.data.carrier}-${props.data.service}`;
        console.log(defVal, "renderCarriersrenderCarriersrenderCarriers");
        let defValName = '';
        if (!props.data.serviceChanged) {
            if (PFManager.default !== null) {
                let def = PFManager.default,
                    isReturn = window.location.search.includes('r=1'),
                    carrierServiceData = PFManager.carrierServiceData;
                let carrierCheck = isReturn ? props.data.carrier : def.Carrie,
                    serviceCheck = isReturn ? props.data.service : def.Service;

                let filterCarrierService = PFManager.unGroupedCarrierServiceData.filter(a => a.Carrie == carrierCheck && a.Service == serviceCheck);
                if (filterCarrierService && filterCarrierService.length > 0) {
                    defVal = `${filterCarrierService[0].Carrie}-${filterCarrierService[0].Service}`;
                    defValName = `${filterCarrierService[0].Carrie}-${filterCarrierService[0].Name}`;
                }


            }
        }

        console.log(PFManager.carrierServiceData, "PFManager.carrierServiceData");
        let carriers = PFManager.carrierServiceData.filter((c: any) => c.Services.filter((s: any) => (s.Company.length == 0) || s.Company.includes(props.data.currentStore.company)).length > 0);
        console.log(carriers, "carrierscarrierscarriers");


        return (
            props.data.multiBox ?
                <Select native value={defVal} onChange={handleCarrierChange} style={{ width: '100%', height: 'max-content', background: 'white' }}>
                    {

                        PFManager.carrierServiceData.filter((c: any) => c.Services.filter((s: any) => (s.Company.length == 0) || s.Company.includes(props.data.currentStore.company)).length > 0).map((data: any, i: number) => {
                            let serrvicesToRender = data.Services.filter((a: any) => (a.Company.length == 0) || a.Company.includes(props.data.currentStore.company));
                            console.log(serrvicesToRender, "servicesToRender");
                            return (
                                <optgroup key={i.toString()} label={data.Title}>
                                    {
                                        serrvicesToRender.map((service: any, id: number) =>
                                            <option key={id.toString()} value={`${data.Title}-${service.service}`}>
                                                {data.Title} - {service.name}
                                            </option>)
                                    }
                                </optgroup>
                            );
                        })
                    }
                </Select>
                :
                <div className='row'>
                    <div className='fieldTitle' style={{ fontSize: '16px', display: 'flex', alignItems: 'center' }}>
                        Service: {req()}
                        {
                            props.data.recommendedService &&
                            <Tooltip style={{ marginLeft: '5px' }} title={<div style={{ fontSize: '16px', padding: '5px', width: '250px', textAlign: 'center' }}>Recommended service selected based on predefined rules</div>} arrow><ErrorIcon style={{ color: 'green', marginLeft: '5px' }} /></Tooltip>
                        }
                    </div>
                    {
                        props.data.formType === 'ReturnLabel'
                            ?
                            <OutlinedInput disabled value={defValName} style={{ width: Utils.isMobile ? '200px' : '250px' }} />
                            :
                            <div className='fieldSelect' style={{ fontSize: '16px', display: 'flex', alignItems: 'center' }}>
                                <Select native value={defVal} onChange={handleCarrierChange} style={{ width: Utils.isMobile ? '200px' : '250px' }} >
                                    {

                                        PFManager.carrierServiceData.filter((c: any) => c.Services.filter((s: any) => (s.Company.length == 0) || s.Company.includes(props.data.currentStore.company)).length > 0).map((data: any, i: number) => {
                                            let serrvicesToRender = data.Services.filter((a: any) => (a.Company.length == 0) || a.Company.includes(props.data.currentStore.company));
                                            console.log(serrvicesToRender, "servicesToRender");
                                            return (
                                                <optgroup key={i.toString()} label={data.Title}>
                                                    {
                                                        serrvicesToRender.map((service: any, id: number) =>
                                                            <option key={id.toString()} value={`${data.Title}-${service.service}`}>
                                                                {data.Title} - {service.name}
                                                            </option>)
                                                    }
                                                </optgroup>
                                            );
                                        })
                                    }
                                </Select>
                                {showServiceWarning() && <Tooltip title={<div style={{ fontSize: '16px', padding: '5px', width: '250px', textAlign: 'center' }}>Manager Approval is required to use "{serviceName}".</div>} arrow><ErrorIcon style={{ color: '#ff9800' }} /></Tooltip>}
                            </div>

                    }
                </div>
        );
    }

    const showServiceWarning = () => {
        const _service = PFManager.unGroupedCarrierServiceData.find(a => a.Carrie == props.data.carrier && a.Service == props.data.service)?.Credentials == "true";
        return props.data.formType !== 'ReturnLabel' && _service && !props.data.bypassCreds;
    }

    const renderSizesSelect = () => {
        return (
            <div className='row'>
                <div className='fieldTitle'>Dimensions: {req()}</div>
                <Select value={props.dimension} name='dimension' onChange={changeDropdown} className='fieldSelect'>
                    <MenuItem value={'0'}>Select a Dimension</MenuItem>
                    {
                        Constants.SIZES.map((data: any, i: number) => <MenuItem value={data.value} key={i}>{data.name}</MenuItem>)
                    }
                </Select>
            </div>
        );
    }


    const renderSizes = () => {
        let dimensionValue = props.data.dimension === '0' ? '' : props.data.dimension;
        return (
            <div className='row'>
                <div className='fieldTitle' style={{ visibility: "hidden" }}>Dimension</div>
                <OutlinedInput
                    value={dimensionValue}
                    placeholder='WxLxH'
                    error={validateInput('dimension')}
                    onChange={changeText} id='dimension'
                    style={{ width: Utils.isMobile ? '200px' : '250px' }}
                    endAdornment={<InputAdornment position="end">IN</InputAdornment>}
                />
            </div>
        );
    }

    const renderReasonCodes = () => {
        return (
            <div className='row'>
                <div className='fieldTitle'>Reason Code: {req()}</div>
                {
                    props.data.formType === 'ReturnLabel'
                        ?
                        <OutlinedInput disabled value={'Customer Return'} style={{ width: Utils.isMobile ? '200px' : '250px' }} />
                        :
                        <Select
                            error={validateInput('reason')}
                            value={props.data.reason} name='reason' onChange={changeDropdown}
                            className='fieldSelect'
                        >
                            <MenuItem value={'0'}>Select Reason Code</MenuItem>
                            {
                                PFManager.reasonCodes.map((data: any, i: number) => <MenuItem value={data.Name} key={i}>{data.Name}</MenuItem>)
                            }
                        </Select>
                }
            </div>
        );
    }

    const renderPackageAddition = () => {
        let dimensionValue = props.data.dimension === '0' ? '' : props.data.dimension;
        return (
            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly', borderBottom: '1px solid #e0e0e0' }}>
                <div style={{ width: '70%', display: 'flex', flexDirection: 'column' }}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around', margin: '5px 0' }}>
                        <div style={{ width: '20%' }}>Dimension: </div>
                        <Select
                            value={props.dimension} name='dimension' onChange={changeDropdown}
                            style={{ width: '60%', height: 'max-content', background: 'white' }}
                        >
                            <MenuItem value={'0'}>Select a Dimension</MenuItem>
                            {
                                Constants.SIZES.map((data: any, i: number) => <MenuItem value={data.value} key={i}>{data.name}</MenuItem>)
                            }
                        </Select>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around', margin: '5px 0' }}>
                        <div style={{ width: '20%', visibility: "hidden" }}>Dimension</div>
                        <OutlinedInput
                            onChange={changeText} id='dimension'
                            value={dimensionValue}
                            style={{ width: '60%' }}
                            endAdornment={<InputAdornment position="end">IN</InputAdornment>}
                        />
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around', margin: '5px 0' }}>
                        <div style={{ width: '20%' }}>Weight: </div>
                        <OutlinedInput
                            type='number' id='weight'
                            onChange={changeWeight} value={props.weight}
                            style={{ width: '60%' }}
                            endAdornment={<InputAdornment position="end">lb</InputAdornment>}
                        />
                    </div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Button
                        color='primary'
                        variant='contained'
                        style={{ width: '10%' }}
                        onClick={() => { modifyPackages('add', 0) }}
                        disabled={props.weight === '' || props.dimension === '0'}
                    >
                        <AddIcon />
                    </Button>
                </div>
            </div>
        );
    }
    const renderMultiBoxOptions = () => {
        let dimensionValue = props.data.dimension === '0' ? '' : props.data.dimension;
        return (
            <>
                <Grid container className='fullWidthContainer'>
                    <Grid container className='fullWidthContainer'>
                        <Grid item xs={6} sm={6} md={6}>
                            <Grid container className='fullWidthContainer'>
                                <Grid item xs={5} sm={5} md={5}>
                                    <p>Dimension: {req()}</p>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6}>
                                    <Select
                                        value={props.dimension} name='dimension' onChange={changeDropdown}
                                        style={{ width: '100%', height: 'max-content', background: 'white' }}
                                    >
                                        <MenuItem value={'0'}>Select a Dimension</MenuItem>
                                        {
                                            Constants.SIZES.map((data: any, i: number) => <MenuItem value={data.value} key={i}>{data.name}</MenuItem>)
                                        }
                                    </Select>
                                </Grid>
                                <Grid item xs={1} sm={1} md={1}></Grid>

                                <Grid item xs={5} sm={5} md={5}>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6}>
                                    <OutlinedInput
                                        onChange={changeText} id='dimension'
                                        style={{ width: "100%" }}
                                        value={dimensionValue}
                                        endAdornment={<InputAdornment position="end">IN</InputAdornment>}
                                    />
                                </Grid>
                                <Grid item xs={1} sm={1} md={1}></Grid>

                                <Grid item xs={5} sm={5} md={5}></Grid>
                                <Grid item xs={6} sm={6} md={6} style={{ marginTop: "10px" }}>
                                    <Grid container className='fullWidthContainer'>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <OutlinedInput
                                                type='number' id='weight'
                                                onChange={changeWeight} value={props.weight}
                                                style={{ width: "100%" }}
                                                endAdornment={<InputAdornment position="end">{props.weightUnit}</InputAdornment>}
                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6} style={{ display: "grid", justifyContent: "flex-end" }}>
                                            <ButtonGroup disableElevation color="primary">
                                                <Button variant={props.weightUnit === 'lb' ? "contained" : "outlined"} onClick={() => { changeWeightUnit('lb') }}>lb</Button>
                                                <Button variant={props.weightUnit === 'oz' ? "contained" : "outlined"} onClick={() => { changeWeightUnit('oz') }}>oz</Button>
                                            </ButtonGroup>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={1} sm={1} md={1}></Grid>

                            </Grid>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6}>
                            <Grid container className='fullWidthContainer'>
                                <Grid item xs={5} sm={5} md={5}>
                                    <p style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>Service: {req()}
                                        {
                                            props.data.recommendedService &&
                                            <Tooltip title={<div style={{ fontSize: '16px', padding: '5px', width: '250px', textAlign: 'center' }}>Recommended service selected based on predefined rules</div>} arrow><ErrorIcon style={{ color: 'green', marginLeft: '5px' }} /></Tooltip>
                                        }</p>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6}>
                                    {renderCarriers()}
                                </Grid>
                                {showServiceWarning() && <Grid item xs={1} sm={1} md={1} style={{ display: "flex", justifyContent: "center", marginTop: "5px" }}>
                                    <Tooltip title={<div style={{ fontSize: '16px', padding: '5px', width: '250px', textAlign: 'center' }}>Manager Approval is required to use "{serviceName}".</div>} arrow><ErrorIcon style={{ color: '#ff9800' }} /></Tooltip>
                                </Grid>}

                                <Grid item xs={5} sm={5} md={5}></Grid>
                                <Grid item xs={6} sm={6} md={6} style={{ display: "grid", justifyContent: "flex-start" }}>
                                    <FormControlLabel
                                        className='fieldSelect'
                                        label="Saturday Delivery"
                                        disabled={disableSaturdayDelivery}
                                        control={<Checkbox color='primary' name='SATURDAYDELIVERY_FLAG' checked={props.data.flags.SATURDAYDELIVERY_FLAG} onChange={handleFlagChange} />}
                                        onClick={() => {
                                            if (!disableSaturdayDelivery) {
                                                handleFlagChange({ target: { name: 'SATURDAYDELIVERY_FLAG', checked: !props.data.flags.SATURDAYDELIVERY_FLAG } })
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={1} sm={1} md={1}></Grid>

                                <Grid item xs={5} sm={5} md={5}></Grid>
                                <Grid item xs={6} sm={6} md={6} style={{ display: "grid", justifyContent: "flex-start" }}>
                                    <FormControlLabel
                                        label="Proof"
                                        className='fieldSelect'
                                        onClick={() => { handleFlagChange({ target: { name: 'PROOF_FLAG', checked: !props.data.flags.PROOF_FLAG } }) }}
                                        control={<Checkbox color='primary' name='PROOF_FLAG' checked={props.data.flags.PROOF_FLAG} onChange={handleFlagChange} />}
                                    />
                                </Grid>
                                <Grid item xs={1} sm={1} md={1}></Grid>

                                <Grid item xs={5} sm={5} md={5}></Grid>
                                <Grid item xs={6} sm={6} md={6} style={{ display: "grid", justifyContent: "flex-start" }}>
                                    <FormControlLabel
                                        label="Proof Signature"
                                        className='fieldSelect'
                                        onClick={() => { handleFlagChange({ target: { name: 'PROOF_SIGNATURE_FLAG', checked: !props.data.flags.PROOF_SIGNATURE_FLAG } }) }}
                                        control={<Checkbox color='primary' name='PROOF_SIGNATURE_FLAG' checked={props.data.flags.PROOF_SIGNATURE_FLAG} onChange={handleFlagChange} />}
                                    />
                                </Grid>
                                {props.data.formType === 'Customer/Vendor' && <><Grid item xs={6} sm={6} md={6}></Grid>
                                    <Grid item xs={6} sm={6} md={6} style={{ display: "grid", justifyContent: "flex-start" }}>
                                        <FormControlLabel
                                            label="Residential"
                                            className='fieldSelect'
                                            onClick={() => { handleFlagChange({ target: { name: 'IS_RESIDENTIAL', checked: !props.data.flags.IS_RESIDENTIAL } }) }}
                                            control={<Checkbox color='primary' name='IS_RESIDENTIAL' checked={props.data.flags.IS_RESIDENTIAL} onChange={handleFlagChange} />}
                                        />
                                    </Grid>
                                    <Grid item xs={1} sm={1} md={1}></Grid></>}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={6} sm={6} md={6}>
                            <Button
                                color='primary'
                                variant='contained'
                                onClick={() => { modifyPackages('add', 0) }}
                                disabled={!props.weight || !props.dimension || !props.data.carrier || !props.data.service || props.data.service == '0'}
                            >
                                <AddIcon style={{ color: "white" }} />
                            </Button>
                        </Grid>
                    </Grid>

                </Grid>
            </>
        );
    }

    const renderTable = () => {
        console.log("Packages", props.packages);
        return (
            <TableContainer component={Paper} style={{ marginTop: "10px" }}>
                <Table aria-label="simple table">
                    <TableHead style={{ backgroundColor: "#D93A2F", color: "white" }}>
                        <TableRow>
                            <TableCell style={{ color: "white" }}>Dimension</TableCell>
                            <TableCell align="right" style={{ color: "white" }} >Weight</TableCell>
                            <TableCell align="right" style={{ color: "white" }} >Service</TableCell>
                            <TableCell align="right" style={{ color: "white" }} >Saturday Delivery</TableCell>
                            <TableCell align="right" style={{ color: "white" }} >Proof</TableCell>
                            <TableCell align="right" style={{ color: "white" }} >Proof Signature</TableCell>

                            {props.data.formType === 'Customer/Vendor' && <TableCell align="right" style={{ color: "white" }} >Residential</TableCell>}
                            <TableCell align="right" style={{ color: "white" }} ></TableCell>
                            {props.packages?.some((value: any) => value.serviceCreds) && !props.data.bypassCreds && <TableCell align="right" style={{ color: "white" }} ></TableCell>}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.packages.map((row: any, i: any) => (
                            <TableRow key={i}>
                                <TableCell >
                                    {row.dimension}
                                </TableCell>
                                <TableCell align="right" >{row.weight}{row.weightUnit}</TableCell>
                                <TableCell align="right" >{row.carrier}-{row.serviceName}</TableCell>
                                <TableCell align="right" >{row.saturdayFlag ? "YES" : "NO"}</TableCell>
                                <TableCell align="right" >{row.proofFlag ? "YES" : "NO"}</TableCell>
                                <TableCell align="right" >{row.proofSigFlag ? "YES" : "NO"}</TableCell>
                                {props.data.formType === 'Customer/Vendor' &&
                                    <TableCell align="right" >{row.resFlag ? "YES" : "NO"}</TableCell>}
                                <TableCell align="right" >
                                    <Button
                                        size='small'
                                        color='primary'
                                        variant='contained'
                                        style={{ width: '10%' }}
                                        onClick={() => { modifyPackages('remove', i) }}
                                    >
                                        <RemoveIcon />
                                    </Button>
                                </TableCell>
                                {row.serviceCreds && !props.data.bypassCreds && <TableCell align="right" >
                                    <Tooltip title={<div style={{ fontSize: '16px', padding: '5px', width: '250px', textAlign: 'center' }}>Manager Approval is required to use "{row.serviceName}".</div>} arrow><ErrorIcon style={{ color: '#ff9800' }} /></Tooltip>
                                </TableCell>}

                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    }

    const renderPackageTableHeader = () => {
        return (
            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly', padding: '5px 0', background: '#e0e0e0', borderBottom: '1px solid #e0e0e0' }}>
                <div style={{ width: '35%' }}><b>Dimensions</b></div>
                <div style={{ width: '35%' }}><b>Weight</b></div>
                <div style={{ width: '10%' }}></div>
            </div>
        );
    }

    const renderPackageTable = () => {
        return (
            <div style={{ width: '100%', height: '120px', maxHeight: '200px', overflow: 'hidden', overflowY: 'scroll' }}>
                {
                    props.packages.map((data: any, i: number) =>
                        <div key={i} style={{ width: '100%', height: '30px', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', padding: '5px 0', borderBottom: '1px solid #e0e0e0' }}>
                            <div style={{ width: '35%' }}>{data.dimension} IN</div>
                            <div style={{ width: '35%' }}>{data.weight} lb</div>
                            <div style={{ width: '10%' }}>
                                <Button
                                    size='small'
                                    color='primary'
                                    variant='contained'
                                    style={{ width: '10%' }}
                                    onClick={() => { modifyPackages('remove', i) }}
                                >
                                    <RemoveIcon />
                                </Button>
                            </div>
                        </div>
                    )
                }
            </div>
        );
    }

    let disableSaturdayDelivery = false;
    if (PFManager.carrierServiceData !== undefined) {

        let filterCarrierService = PFManager.unGroupedCarrierServiceData.filter(a => a.Carrie == props.data.carrier && a.Service == props.data.service);
        if (filterCarrierService && filterCarrierService.length > 0) {
            disableSaturdayDelivery = filterCarrierService[0].SaturdayDelivery === 'false';
        }

    }

    const closeCredsPopUp = () => {
        setVerifyCredsPopup(false);
    }

    const getReqBoxes = () => {
        if (!props.data.multiBox) {
            const _serviceName = PFManager.unGroupedCarrierServiceData.find(a => a.Carrie == props.data.carrier && a.Service == props.data.service)?.Name ?? "";
            const box: any = [{
                dimension: props.data.dimension,
                weight: props.data.weight,
                weightUnit: props.data.weightUnit,
                carrier: props.data.carrier,
                serviceName: _serviceName,
            }]
            setReqBoxes(box);
        }
        else {
            const _reqBoxes = props.data.packages.filter((box: any) => box.serviceCreds);
            setReqBoxes(_reqBoxes);
        }
    }
    const addReason = (text: any) => {
        props.changeState({ reasonText: text });
        setReasonsPopup(false);
        if (props.data.requiredCreds && !props.data.bypassCreds) {
            setVerifyCredsPopup(true);
        }
        else {
            props.changeActiveStep(3);
        }
    }

    const credsConfirmed = (profile: any) => {
        props.changeState({ requiredCreds: false, approvalProfile: profile });
        closeCredsPopUp();
        toast.success('Approved!', {
            className: "success-toast",
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: "light",
        });
        props.changeActiveStep(3);
    }

    return (
        <div className='stepContainer' style={{ height: 'auto' }}>
            <PreviousInfo
                customer={props.data.customer}
                formType={props.data.formType}
                currentStore={props.data.currentStore}
                selectedStore={props.data.selectedStore}
                isResidential={props.data.isResidential}
            />
            <h2>Select Carrier Details</h2>
            <div className='cardContainer serviceInfo'>
                <div className='column'>
                    <div className='row'>
                        <div className='fieldTitle'>Shipper Reference: {req()}</div>
                        <TextField error={validateInput('shipperRef')} onChange={changeText} id='shipperRef' value={props.data.shipperRef} style={{ width: Utils.isMobile ? '200px' : '250px' }} variant="outlined" size="small" />
                    </div>
                    {/* {props.data.formType !== 'ReturnLabel' && renderReasonCodes()} */}
                    {props.data.formType !== 'ReturnLabel' && !Utils.isMobile && <div className='row'>
                        <div className='fieldTitle' style={{ display: 'flex', alignItems: 'center' }}>Multi-Box:
                            {
                                <Tooltip style={{ marginLeft: '5px' }} title={<div style={{ fontSize: '16px', padding: '5px', width: '250px', textAlign: 'center' }}>Multiple Boxes can be created by enabling this option.</div>} arrow><ErrorIcon style={{ color: '#2196F3', marginLeft: '5px' }} />
                                </Tooltip>
                            }
                        </div>
                        <div style={{ width: '250px', display: 'flex', justifyContent: 'space-between' }}>
                            <Switch
                                checked={props.data.multiBox}
                                onChange={handleMultiBox}
                                color="primary"
                                name="checkedB"
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                        </div>

                        {/* </Grid>  */}
                    </div>}

                    {(!multiPackage && !props.data.multiBox) && renderSizesSelect()}
                    {(!multiPackage && !props.data.multiBox) && renderSizes()}
                    {
                        (!multiPackage && !props.data.multiBox) &&
                        <div className='row'>
                            <div className='fieldTitle'>Weight: {req()}</div>
                            <div style={{ width: '250px', display: 'flex', justifyContent: 'space-between' }}>
                                <OutlinedInput
                                    type='number' id='weight'
                                    error={validateInput('weight')}
                                    onChange={changeWeight} value={props.weight}
                                    style={{ width: Utils.isMobile ? '100px' : '130px' }}
                                    endAdornment={<InputAdornment position="end">{props.weightUnit}</InputAdornment>}
                                />
                                <ButtonGroup disableElevation color="primary">
                                    <Button variant={props.weightUnit === 'lb' ? "contained" : "outlined"} onClick={() => { changeWeightUnit('lb') }}>lb</Button>
                                    <Button variant={props.weightUnit === 'oz' ? "contained" : "outlined"} onClick={() => { changeWeightUnit('oz') }}>oz</Button>
                                </ButtonGroup>
                            </div>
                        </div>
                    }

                    {/* {
                        multiPackage &&
                        <div className='row' style={{ border: '1px solid #cecece', display: 'flex', flexDirection: 'column' }}>
                            {renderPackageAddition()}
                            {renderPackageTableHeader()}
                            {renderPackageTable()}
                        </div>
                    } */}
                </div>
                {
                    !Utils.isMobile && <Divider orientation="vertical" />
                }

                <div className='column' style={{ alignItems: 'center' }}>
                    {/* {props.data.formType === 'ReturnLabel' && renderReasonCodes()} */}
                    {renderReasonCodes()}
                    {/* <div className='row'>
                    </div>
                    <div className='row'>
                    </div> */}
                    {!props.data.multiBox && <>
                        {renderCarriers()}
                        {
                            props.data.formType !== 'ReturnLabel' &&
                            <div className='row'>
                                <div className='fieldTitle'></div>
                                <FormControlLabel
                                    className='fieldSelect'
                                    label="Saturday Delivery"
                                    disabled={disableSaturdayDelivery}
                                    control={<Checkbox color='primary' name='SATURDAYDELIVERY_FLAG' checked={props.data.flags.SATURDAYDELIVERY_FLAG} onChange={handleFlagChange} />}
                                    onClick={() => {
                                        if (!disableSaturdayDelivery) {
                                            handleFlagChange({ target: { name: 'SATURDAYDELIVERY_FLAG', checked: !props.data.flags.SATURDAYDELIVERY_FLAG } })
                                        }
                                    }}
                                />
                            </div>
                        }
                        {
                            props.data.formType !== 'ReturnLabel' &&
                            <div className='row'>
                                <div className='fieldTitle'></div>
                                <FormControlLabel
                                    label="Proof"
                                    className='fieldSelect'
                                    onClick={() => { handleFlagChange({ target: { name: 'PROOF_FLAG', checked: !props.data.flags.PROOF_FLAG } }) }}
                                    control={<Checkbox color='primary' name='PROOF_FLAG' checked={props.data.flags.PROOF_FLAG} onChange={handleFlagChange} />}
                                />
                            </div>
                        }
                        {
                            props.data.formType !== 'ReturnLabel' &&
                            <div className='row'>
                                <div className='fieldTitle'></div>
                                <FormControlLabel
                                    label="Proof Signature"
                                    className='fieldSelect'
                                    onClick={() => { handleFlagChange({ target: { name: 'PROOF_SIGNATURE_FLAG', checked: !props.data.flags.PROOF_SIGNATURE_FLAG } }) }}
                                    control={<Checkbox color='primary' name='PROOF_SIGNATURE_FLAG' checked={props.data.flags.PROOF_SIGNATURE_FLAG} onChange={handleFlagChange} />}
                                />
                            </div>
                        }
                        {
                            props.data.formType !== 'ReturnLabel' && props.data.formType === 'Customer/Vendor' &&
                            <div className='row'>
                                <div className='fieldTitle'></div>
                                <FormControlLabel
                                    label="Residential"
                                    className='fieldSelect'
                                    onClick={() => { handleFlagChange({ target: { name: 'IS_RESIDENTIAL', checked: !props.data.flags.IS_RESIDENTIAL } }) }}
                                    control={<Checkbox color='primary' name='IS_RESIDENTIAL' checked={props.data.flags.IS_RESIDENTIAL} onChange={handleFlagChange} />}
                                />
                            </div>
                        }
                    </>}
                </div>
            </div>

            {props.data.multiBox && <Card style={{ width: "90%", marginTop: "20px" }}>
                <CardContent>
                    {renderMultiBoxOptions()}
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        {renderTable()}
                    </div>
                    {/* {renderPackageTableHeader()}
                            {renderPackageTable()} */}
                </CardContent>
            </Card>}
            {renderShiftButtons()}
            <VerifyCredentials open={verifyCredsPopup} close={closeCredsPopUp} credsConfirmed={credsConfirmed} boxes={reqBoxes} />
            <Reasons open={reasonsPopup} close={closeReasonsPopup} addReason={addReason} boxes={reqBoxes} />
        </div>
    );
}