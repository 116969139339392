import React from 'react';
import './Routes.scss';
import EODReport from '../Subcomponent/EndOfDay/EODReport';
import DialogBox from '../Subcomponent/Dialog/DialogBox';
import CacheBuster from '../Managers/Cache.Buster';

export default class EOD extends React.Component<any, {}> {

  public state = {
    openDialog: false,
    dialogText: {Title: '', Msg: ''},
  }

  constructor(props: any) {
    super(props);

    this.changeState = this.changeState.bind(this);
  }

  componentDidMount = async () => {
    await CacheBuster.checkVersion();
  }

  changeState = (data: any) => this.setState(data);

  // RENDER METHODS

    render = () => {
      return (
        <div className='innerContianer'>
          <DialogBox
            text={this.state.dialogText}
            open={this.state.openDialog}
            handleClose={() => {this.setState({openDialog: false})}}
          />

          <div className='primaryRow'>
              <div className='headerText'>End of Day</div>
          </div>
          
          <EODReport changeState={this.changeState}/>
        </div>
      );
    }
}