import Label from "../Models/Label.model";
import AuditLog from "../Models/AuditLog.model";

export default class Constants {

   public static USER_PROFILE: string = "oktauserProfile";
   public static createLogin = "oktacreateLogin";
   public static employeeid: string = "employeeid";
   public static jobCode: string = "jobcode";
   public static city: string = "location";
   public static groups: string = "groups";
   public static preferred_username: string = "preferred_username";
   public static username: string = "name"

   public static GROUPS = {
      USER: 'dce5e71d-e4ff-44ac-9363-4e1fe8fc9c5f',  // aa_shippingcenter_users  // MA Users
      USERS: 'cc7f8b8d-9bbf-46b5-846f-0656fa90c4f1',  //aa_shippingcenter_storeusers  // Normal Users
      ADMIN: 'c46b800f-7433-4f4d-bcfb-74d3c3da427b'  // aa_shippingcenter_admins
   }

   // public static GROUPS = { // this will be changed ????
   //    USER: 'dce5e71d-e4ff-44ac-9363-4e1fe8fc9c5f',  // aa_shippingcenter_users  // MA Users
   //    USERS: 'cc7f8b8d-9bbf-46b5-846f-0656fa90c4f1',  //aa_shippingcenter_storeusers  // Normal Users
   //    ADMIN: 'c46b800f-7433-4f4d-bcfb-74d3c3da427b'  // aa_shippingcenter_admins
   // }

   public static TECH_AUDIT_FILTERS = [
      { NAME: 'All', VALUE: '' },
      { NAME: 'OMS', VALUE: 'OMS Store Fulfillment UI' },
      { NAME: 'Shipping Center', VALUE: 'Shipping Center' },
   ]

   public static MESSAGES = {
      LABEL_SUBMITTED: 'Shipment Label Generated!',
      MULTI_LABEL_SUBMITTED: 'Shipment Labels Generated!',
      RETURN_LABEL_SUBMITTED: 'Return Label Generated!',
      VOID_LABEL_MESSAGE: 'Would you like to void this label?',
      VOID_LABEL_SUCCESS: 'Label has been voided',
      VOID_LABEL_FAILURE: 'Error occured',
   }

   public static AUTH = {
      tenatId: "2903b32e-b3db-4aad-8b64-f3f7b64e235c",
      clientId: "db0aa2a9-46d1-4476-a8be-71ab8336455e",
      scope: "api://db0aa2a9-46d1-4476-a8be-71ab8336455e/access_as_user%20openid",
      state: "VHVlIEFwciAxNyAyMDE4IDE2OjM3OjQwIEdNVC0wNzAwIChQYWNpZmljIERheWxpZ2h0IFRpbWUp"
   }

   public static LOG_TYPES = {
      ERROR: 'Error',
      LOGIN: 'Login',
      LOGOUT: 'Logout',
      REPRINT: 'RePrint',
      VOID_LABEL: 'VoidLabel',
      STORE_PRINT: 'StorePrint',
      CREATE_LABEL: 'CreateLabel',
      RETURN_LABEL: 'ReturnLabel',
      PRINT_CONFIG: 'PrintConfig',
   }

   public static PROGRESS_BAR_STYLE_DESKTOP = {
      pathColor: `#e15241`,
      textColor: '#e15241',
      trailColor: '#d6d6d6',
      backgroundColor: '#e15241'
   }

   public static COLORS = {
      MOBILE_RED: "#c11022",
      DESKTOP_RED: "#e15241",
      WARNING_YELLOW: "#ffd500",
      WARNING_ORANGE: "#ff9800",
      APPROVED_GREEN: "#0b9e0b",
   }

   public static DEFAULT = {
      LOGOUT_URL: "https://login.microsoftonline.com/tenantid/oauth2/v2.0/logout",
   }

   public static FLAGS = {
      PROOF_FLAG: false,
      IS_RESIDENTIAL: false,
      SHIPMENTHOLD_FLAG: false,
      PROOF_SIGNATURE_FLAG: false,
      SATURDAYDELIVERY_FLAG: false,
   }

   public static SHIP_DETAILS = {
      "id": 0,
      "StoreEmail": '',
      "DistrictID": 0,
      "StoreID": 0,
      "Address": "",
      "Address2": "",
      "City": "",
      "State": "",
      "Zip": "",
      "Phone": "",
      "Fax": "",
      "StoreManager": 0,
      "OpsManager": 0,
      "gcpro": "",
      "Latitude": "",
      "Longitude": ""
   }

   public static DRAWER_DATA = [
      {
         Route: "Labels",
         Header: "Shipment Labels",
         Data: []
      },
      {
         Route: "ReturnLabels",
         Header: "Return Labels",
         Data: []
      },
      // {
      //    Route: "AddressBook",
      //    Header: "Address Book",
      //    Data: []
      // },
      {
         Route: "EOD",
         Header: "End of Day",
         Data: []
      },
      {
         Route: "TechAudits",
         Header: "Tech Audits",
         Data: []
      },
      {
         Route: "PrintConfigs",
         Header: "Printer Configurations",
         Data: []
      },
   ]

   public static LABELS = {
      PAGINATION: {
         OPTIONS: [25, 50, 100],
         DEFAULT: { size: 25, page: 1 },
      },
   }

   public static TECH_AUDIT = {
      PAGINATION: {
         OPTIONS: [25, 50, 100],
         DEFAULT: { size: 25, page: 1 },
      },
      FILTERS: ['All', 'Create', 'Return', 'Reprint', 'Print Config', 'Void', 'Error', 'Store Print', 'Login', 'Logout'],
   }

   public static SIZES = [
      { value: "10x10x10", name: "10x10x10" },
      { value: "10x18x4.5", name: "10x18x4.5" },
      { value: "12x12x12", name: "12x12x12" },
      { value: "12x9x1", name: "Envelope" },
      { value: "15x15x20", name: "15x15x20" },
      { value: "16x16x16", name: "16x16x16" },
      { value: "18x18x12", name: "18x18x12" },
      { value: "18x6x4.5", name: "18x6x4.5" },
      { value: "19x19x19", name: "19x19x19" },
      { value: "20x16x4", name: "20x16x4" },
      { value: "20x20x20", name: "20x20x20" },
      { value: "20x20x36", name: "20x20x36" },
      { value: "20x8x50", name: "20x8x50" },
      { value: "22x22x30", name: "22x22x30" },
      { value: "22x22x8", name: "22x22x8" },
      { value: "24x10x10", name: "24x10x10" },
      { value: "24x16x24", name: "24x16x24" },
      { value: "24x20x12", name: "24x20x12" },
      { value: "24x24x16", name: "24x24x16" },
      { value: "26x26x26", name: "26x26x26" },
      { value: "26x26x6", name: "26x26x6" },
      { value: "28x20x24", name: "28x20x24" },
      { value: "28x28x12", name: "28x28x12" },
      { value: "28x28x28", name: "28x28x28" },
      { value: "32x24x24", name: "32x24x24" },
      { value: "36x24x24", name: "36x24x24" },
      { value: "36x36x24", name: "36x36x24" },
      { value: "48x24x12", name: "48x24x12" },
      { value: "60x20x8", name: "60x20x8" },
      { value: "6x9x4.5", name: "6x9x4.5" },
      { value: "8x8x8", name: "8x8x8" }
   ]

   public static PROCESS_FULFILLMENT = {
      ACTIONS: {
         REPRINT: 'RE_PRINT',
         VOID_SHIPMENT: 'VOID_SHIPMENT',
         CREATE_SHIPMENT: 'CREATE_SHIPMENT',
      },
      APP_ID: 'Shipping Center'
   }

   public static STATES = [
      { value: "AL", name: "Alabama" },
      { value: "AK", name: "Alaska" },
      { value: "AZ", name: "Arizona" },
      { value: "AR", name: "Arkansas" },
      { value: "CA", name: "California" },
      { value: "CO", name: "Colorado" },
      { value: "CT", name: "Connecticut" },
      { value: "DE", name: "Delaware" },
      { value: "DC", name: "District Of Columbia" },
      { value: "FL", name: "Florida" },
      { value: "GA", name: "Georgia" },
      { value: "HI", name: "Hawaii" },
      { value: "ID", name: "Idaho" },
      { value: "IL", name: "Illinois" },
      { value: "IN", name: "Indiana" },
      { value: "IA", name: "Iowa" },
      { value: "KS", name: "Kansas" },
      { value: "KY", name: "Kentucky" },
      { value: "LA", name: "Louisiana" },
      { value: "ME", name: "Maine" },
      { value: "MD", name: "Maryland" },
      { value: "MA", name: "Massachusetts" },
      { value: "MI", name: "Michigan" },
      { value: "MN", name: "Minnesota" },
      { value: "MS", name: "Mississippi" },
      { value: "MO", name: "Missouri" },
      { value: "MT", name: "Montana" },
      { value: "NE", name: "Nebraska" },
      { value: "NV", name: "Nevada" },
      { value: "NH", name: "New Hampshire" },
      { value: "NJ", name: "New Jersey" },
      { value: "NM", name: "New Mexico" },
      { value: "NY", name: "New York" },
      { value: "NC", name: "North Carolina" },
      { value: "ND", name: "North Dakota" },
      { value: "OH", name: "Ohio" },
      { value: "OK", name: "Oklahoma" },
      { value: "OR", name: "Oregon" },
      { value: "PA", name: "Pennsylvania" },
      { value: "RI", name: "Rhode Island" },
      { value: "SC", name: "South Carolina" },
      { value: "SD", name: "South Dakota" },
      { value: "TN", name: "Tennessee" },
      { value: "TX", name: "Texas" },
      { value: "UT", name: "Utah" },
      { value: "VT", name: "Vermont" },
      { value: "VA", name: "Virginia" },
      { value: "WA", name: "Washington" },
      { value: "WV", name: "West Virginia" },
      { value: "WI", name: "Wisconsin" },
      { value: "WY", name: "Wyoming" },
   ]

   public static gcStoreType = "GC";
   public static mmaStoreType = "MAA";
   public static ccStoreType = "CC";
   public static distributionCentersStores = "DC";

   public static LabelTableFields = {
      ApplicationId: "ApplicationId.keyword",
      Status: "Status.keyword",
      Weight: "PayLoad.PACKAGES.WEIGHT.keyword",
      Cost: "ServiceResponse.TOTAL.keyword",
      Store: "Store.keyword",
      ShipperReference: "ServiceResponse.SHIPPER_SHIPMENT_REFERENCE.keyword",
      Carrier: "Carrier.keyword",
      Service: "Service.keyword",
      CreatedDate: "LogTime",
      ShipDate: "ShipDate",
      TrackingNo: "ServiceResponse.CARRIER_PACKAGE_REFERENCE.keyword"
   }

   public static Configs = {
      NotificationConfig: "notification",
      ExportConfig: "ExportLimit",
      ShippingAPI: "ShippingAPI",
      PremiumReasons: "PremiumReasons"
   }


}