import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import Constants from './Common/Constants';


const theme = createTheme({
  palette: {
    primary: {
      // main: window.innerWidth > 1023 ? Constants.COLORS.DESKTOP_RED: Constants.COLORS.MOBILE_RED,
      // main: '#da0c0c',
      main: '#D93A2F',
    },
    secondary: {
      main: '#FFFFFF',
    },
  },
})


ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
