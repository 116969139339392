import Constants from "./Constants";
import moment from "moment";
import StoreManager from "../Managers/Store.manager";
import PFManager from "../Managers/Process.Fulfillment.Manager";
import IdentityManager from "../Managers/Identity.manager";
import PFServicesManager from "../Managers/Fulfillment.Services.Manager";
const { v4: uuidv4 } = require('uuid');
export default class Utils {

    public static getGuid() {
        const guid = uuidv4();
        return guid
    }

    public static startDate = moment().toDate();

    public static get currentDate(): Date {
        return moment().toDate();
    }

    public static get ShipDate(): Date {
        let _date = moment().toDate()
        if (_date.getDay() === 0) {
            _date.setHours(_date.getHours() + 24)
        } else if (_date.getDay() === 6) {
            _date.setHours(_date.getHours() + 48)
        }
        return _date;
    }


    public static get dateReducedByDay(): Date {
        return moment().subtract(1, 'days').toDate();
    }
    public static get displayHeight(): number {
        return window.innerHeight - 64;
    }

    public static get displayWidth(): number {
        return window.innerWidth - 240;
    }

    public static get isDesktop(): boolean {
        return window.innerWidth >= 1023;
    }

    public static get isTab(): boolean {
        return window.innerWidth < 1023;
    }

    public static get isMobile(): boolean {
        return window.innerWidth < 640;
    }

    public static isEnterPressed = (event: any | Event) => {
        return event.keyCode === 13
    }

    public static getDesktopDetailWidth = () => {
        return window.innerWidth <= 1024 ? 470 : 520;
    }

    public static checkUserProfile_LocalStorage = () => {
        return localStorage.getItem(Constants.USER_PROFILE) != null &&
            localStorage.getItem(Constants.USER_PROFILE) != undefined &&
            localStorage.getItem(Constants.USER_PROFILE) != '';
    }

    public static onlyUnique(value: any, index: number, self: any) {
        return self.indexOf(value) === index;
    }

    public static getLogType = (name: string) => {
        switch (name) {
            case 'All':
                return '';

            case 'Create':
                return Constants.LOG_TYPES.CREATE_LABEL;

            case 'Return':
                return Constants.LOG_TYPES.RETURN_LABEL;

            case 'Reprint':
                return Constants.LOG_TYPES.REPRINT;

            case 'Print Config':
                return Constants.LOG_TYPES.PRINT_CONFIG;

            case 'Void':
                return Constants.LOG_TYPES.VOID_LABEL;

            case 'Store Print':
                return Constants.LOG_TYPES.STORE_PRINT;

            case 'Error':
                return Constants.LOG_TYPES.ERROR;

            case 'Login':
                return Constants.LOG_TYPES.LOGIN;

            case 'Logout':
                return Constants.LOG_TYPES.LOGOUT;

            default:
                return '';
        }
    }

    public static getInitialData = async () => {
        StoreManager.getConfigs();

        await StoreManager.getAllSCStores();
        if (IdentityManager.storeNumber !== '') {
            let res = StoreManager.allStoredata.find(r => r.StoreId.toString() === IdentityManager.storeNumber)
            if (res !== undefined && res !== null) {
                StoreManager.currentStore = res;
            }
        }
        await StoreManager.getDCList();

        //Use Shipping API or Lambda for PF Services
        if (PFManager.shippingAPI) {
            await PFServicesManager.getCodes();
            await PFServicesManager.getActionServices();
        }
        else {
            await PFManager.getCodes();
            await PFManager.getCarrierServices();
        }

    }

    public static convertNullUndefinedToEmptyString = (input: any) => {
        if (input == undefined || input == null || input == "null") {
            input = "";
        }

        return input;
    }

    public static verifyJobCode = (jobCode: any, mgrOpsCode: any) => {
        jobCode = Number(jobCode);
        if (jobCode <= 0) {
            return false;
        }
        let jobCodes = mgrOpsCode?.split(',');
        let result = false;
        for (let i = 0; i < jobCodes?.length; i++) {
            let code = jobCodes[i];
            code = code?.trim();
            if (code?.includes('-')) {
                let minRange = Number(code.split("-")[0].trim());
                let maxRange = Number(code.split("-")[1].trim());
                result = jobCode >= minRange && jobCode <= maxRange
            }
            else {
                result = jobCode == Number(code);
            }
            if (result) {
                return true;
            }
        }
        return result;
    }
}