import Constants from "../Common/Constants";
import IdentityManager from "./Identity.manager";
import AuditLog from "../Models/AuditLog.model";
import moment from "moment";
import Utils from "../Common/Utils";
import StoreManager from "./Store.manager";

export default class AuditLogsManager {

    public static AuditLogs: any = [];
    public static Auditcount: number = 0;


    public static getAuditCount = async (storeId: string, word: string) => {
        let token = IdentityManager.AccessToken;
        let _res = await fetch(`/logs/getAuditCount?store=${storeId}&word=${word}`, { headers: { 'Authorization': `Bearer ${token}` } });
        let count: number = 0;
        if (storeId && storeId.length > 0) {
            storeId = StoreManager.getSoreShipperPreFixByStoreId(storeId) + storeId;
        }
        try {
            if (_res.status !== 400 && _res.status !== 500) {
                let _result = await _res.json();
                console.log(_result);
                AuditLogsManager.Auditcount = _result.count
                count = _result.count
            }
        } catch (error) {
            console.log(error);
        }
        return count;
    }

    public static getAllLogs = async (storeId: string, size: any, from: any, word: string, filter: string, app: string) => {
        let token = IdentityManager.AccessToken;
        if (storeId && storeId.length > 0) {
            storeId = StoreManager.getSoreShipperPreFixByStoreId(storeId) + storeId;
        }
        let _filter = Utils.getLogType(filter);
        let _res = await fetch(
            `/logs/getAllLogs?store=${storeId}&size=${size}&from=${from}&word=${word}&filter=${_filter}&app=${app}`,
            { headers: { 'Authorization': `Bearer ${token}` } }
        );
        let logs: any[] = [];
        let count: number = 0;

        try {
            if (_res.status !== 400 && _res.status !== 500) {
                let _result = await _res.json();
                if (_result.hits.hits.length > 0) {
                    logs = _result.hits.hits
                        .filter((r: any) => r._source.Date !== undefined)
                        .map((r: any) => ({ _id: r._id, ...r._source }))
                        .sort((a: any, b: any) => moment(a.LogTime).toDate().getTime() > moment(b.LogTime).toDate().getTime() ? -1 : 1)
                    count = _result.hits.total.value;
                }
                AuditLogsManager.AuditLogs = logs;
                AuditLogsManager.Auditcount = count;
            }
        } catch (error) {
            console.log(error);
        }
        return [logs, count];
    }

    public static createLog = async (LogType: string, Payload: any, ServiceResponse: any) => {
        let token = IdentityManager.AccessToken;
        let body = {
            ApplicationId: Constants.PROCESS_FULFILLMENT.APP_ID,
            LogTime: moment().toDate().toISOString(),
            Date: moment().format('YYYY-MM-DD'),
            EmployeeID: IdentityManager.getUserProperty(Constants.employeeid),
            LogType: LogType,
            Payload: Payload !== null ? Payload : {},
            ServiceResponse: ServiceResponse !== null ? ServiceResponse : {},
            Store: StoreManager.getSoreShipperPreFixByStoreId(IdentityManager.storeNumber) + IdentityManager.storeNumber
        }
        let _result = {}
        try {
            let _res = await fetch(
                `/logs/createLog`,
                {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(body)
                }
            );
            if (_res.status !== 400 && _res.status !== 500) {
                _result = await _res.json();
            }
        } catch (error) {
            console.log(error);
        }
        return _result;
    }

    public static auditApprovalLog = async (body:any) =>{
        let token = IdentityManager.AccessToken;
        try {
            let _res = fetch(
                `/logs/auditApprovalLog`,
                {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(body)
                }
            ).then(response => response.json()).catch((error:any)=>{
                console.log('AuditApprovalLog Error', error)
            });
        } catch (error) {
            console.log('AuditApprovalLog Error', error);
            console.log(error);
        }
    }
    
}

// try {
// } catch (error) {
//     console.log(error);
// }