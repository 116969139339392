import React from 'react';
import './Dialogs.scss';
import PrintManager from '../../Managers/Print.Manager';
import PFManager from '../../Managers/Process.Fulfillment.Manager';

import Utils from '../../Common/Utils';
import Constants from '../../Common/Constants';

import 'react-circular-progressbar/dist/styles.css';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { Slide, Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';

import PrintIcon from '@material-ui/icons/Print';
import LabelUtils from '../../Common/Label.Utils';
import PFServicesManager from '../../Managers/Fulfillment.Services.Manager';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
  ) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function MultiPrintDialog(props: any): any {

    const [toPrint, setToPrint] = React.useState<number>(0);
    const [printed, setPrinted] = React.useState<number>(0);

    const [loading, setLoading] = React.useState<boolean>(false);

    const resetState = () => {
        setLoading(false);
        setToPrint(0);
        setPrinted(0);
    }

    const closeDialogBox = () => {
        props.changeState({multiPrint: false});//, bulkProcessLabels: []});
        resetState();
    }

    const printLabel = async () => {
        setLoading(true);
        setToPrint(props.labels.length);
        let zplData: any[] = [];
        
        for (let i = 0; i < props.labels.length; i++) {
             let result: any = null;
            //Use Shipping API or Lambda for PF Services
            if(PFManager.shippingAPI){
                result = await PFServicesManager.rePrint(props.labels[i].ServiceResponse.SUID, props.labels[i].Store);
            }
            else{
                result = await PFManager.rePrint(props.labels[i].ServiceResponse.SUID, props.labels[i].Store);
            }
            result.response.printData.forEach((r: any) => {
                PrintManager.sendPrinterRequest(r)
                zplData.push(r)
            });
            let _printed: number = printed + 1;
            setPrinted(_printed);
        }

        await PrintManager.handlePrintDataDisplay(zplData);
        setLoading(false);
    }
  
  const renderLoader = () => {
    let percentage = 0;
    if (toPrint > 0 && printed > 0)
      percentage = (printed / toPrint) * 100;

    let style = Constants.PROGRESS_BAR_STYLE_DESKTOP;

    return (
      <div style={{ width: '200px', height: '200px', margin: '10px auto' }}>
        <CircularProgressbar value={percentage} text={`${percentage.toFixed(2)}%`}
            styles={buildStyles(style)}/>
      </div>
    );
  }
  
  let trackingNumbers: any[] = [];
  props.labels.forEach((r: any) => {
    let arr = LabelUtils.getProperty.trackingNo(r.ServiceResponse);
    trackingNumbers.push(...arr); 
  });

  return (
    <Dialog open={props.open} onClose={() => {closeDialogBox()}} TransitionComponent={Transition}>
        {
            loading && 
            <div style={{width: Utils.isMobile ? '90vw' : '400px'}}>
                {renderLoader()}
            </div>
        }

        {
            !loading &&
            <DialogTitle className="itemDialogBorder"  style={{background: '#088cff'}}>
                <div className="dialogHeader">
                    <span style={{color: 'white', margin: '0', fontWeight: 600}}>
                        { toPrint === 0 ? 'Print' : 'Labels Printed' }
                    </span>
                    { toPrint === 0 ? <PrintIcon style={{color:'white'}}/> : null }
                </div>
            </DialogTitle>
        }
      
        {
            !loading && toPrint === 0 &&
            <DialogContent className="itemDialogBorder dialogWidth">
                <div className='voidLabel'>
                    <span>You are about to print the selected labels.</span> <br/>
                    <br/>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        <b>Tracking Numbers:</b>
                        {
                            trackingNumbers.map((item: any, i: number, array: Array<any>) => 
                            <span style={{fontSize: '16px', marginLeft: '5px'}}> - {item}</span>)
                        }
                    </div>
                </div>
            </DialogContent>
        }

        {
            !loading &&
            <DialogActions>
            {
                toPrint === 0 &&
                <Button size="small" onClick={() => {printLabel()}} variant="contained" style={{marginLeft:'10px'}}>
                <span className="dialogButton">Yes</span>
                </Button>
            }
            {
                toPrint === 0 &&
                <Button size="small" onClick={() => {closeDialogBox()}} style={{color:'white', background:'#088cff'}} variant="contained">
                <span className="dialogButton">No</span>
                </Button>
            }
            {
                toPrint !== 0 &&
                <Button size="small" onClick={() => {closeDialogBox()}} style={{color:'white', background:'#088cff'}} variant="contained">
                <span className="dialogButton">Close</span>
                </Button>
            }
            </DialogActions>
        }
    </Dialog>
  );
}