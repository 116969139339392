import React from 'react';
import './Shared.scss';
import CloseIcon from '@material-ui/icons/Close';
import { Select, MenuItem } from '@material-ui/core';

export default function FilterBar(props: any): any {

    return (
        <div className="FilterBar">
          
            <Select style={{width: '15%', margin: '0 5px', background: 'white', height: 'max-content'}}>
                <MenuItem value={0}>None</MenuItem>
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
            </Select>

            <Select style={{width: '15%', margin: '0 5px', background: 'white', height: 'max-content'}}>
                <MenuItem value={0}>None</MenuItem>
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
            </Select>

          {/* <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="MM/dd/yyyy"
            margin="normal"
            id="date-picker-inline"
            label="Date picker inline"
            value={selectedDate}
            onChange={this.handleDateChange}
          /> */}

            <div className="clearFilter">
                <CloseIcon/>
                Clear Filter
            </div>
        </div>
    )
}