import React from 'react';
import './CreateLabelViews.scss';
import moment from "moment";

import Utils from '../../../Common/Utils';
import Constants from '../../../Common/Constants';

import { Button, Divider, Card, CardHeader, CardContent, Grid } from '@material-ui/core';
import { FaEdit } from "react-icons/fa";
import PFManager from '../../../Managers/Process.Fulfillment.Manager';

export default function Review(props: any): any {

    const changeStep = (step: number) => props.changeActiveStep(step);

    const renderShiftButtons = () => {
        return (
            <div className='shiftButtons'>
                <Button variant="contained" onClick={() => { props.changeActiveStep(2) }}>Back</Button>
                <Button variant="contained" color="primary" style={{ marginLeft: '10px' }} onClick={() => { props.createShipment() }}>Submit</Button>
            </div>
        );
    }

    console.log(PFManager.carrierServiceData);

    let carrier = "";
    let service = "";
    let filterCarrierServiceData = PFManager.unGroupedCarrierServiceData.filter(a => a.Carrie == props.data.carrier && a.Service == props.data.service);
    if (filterCarrierServiceData && filterCarrierServiceData.length > 0) {
        carrier = props.data.carrier;
        service = filterCarrierServiceData[0].Name;
    }

    const shipDate = moment(props.data.shipDate).format('MM/DD/YYYY');
    const Stype = props.data.formType !== 'ReturnLabel' ? `Store to ${props.data.formType}` : `Return Label`

    const renderShipFrom = () => {
        return (
            <Card className='cardColor' style={{ width: '100%', margin: Utils.isMobile ? '3px 0' : '10px 0', height: Utils.isMobile ? 'auto' : '230px', minHeight: '200px' }}>
                <CardContent>
                    <div className='titleRow'>
                        <div className='header'>Ship-From</div>
                        <div className='edit' onClick={() => { props.changeActiveStep(1) }}><FaEdit /> Edit</div>
                    </div>
                    <div className='row'><div className='label'>Address:</div> <div className='value'>{props.data.shipFromDetails.Address}</div></div>
                    <div className='row'><div className='label'>City:</div> <div className='value'>{props.data.shipFromDetails.City}</div></div>
                    <div className='row'><div className='label'>State:</div> <div className='value'>{props.data.shipFromDetails.State}</div></div>
                    <div className='row'><div className='label'>Postal Code:</div> <div className='value'>{props.data.shipFromDetails.Zip}</div></div>
                    <div className='row'><div className='label'>Phone:</div> <div className='value'>{props.data.shipFromDetails.Phone}</div></div>
                    <div className='row'><div className='label'>Fax:</div> <div className='value'>{props.data.shipFromDetails.Fax}</div></div>
                    {
                        props.data.formType === 'ReturnLabel' &&
                        <div className='row'><div className='label'>Email:</div> <div className='value'>{props.data.returnLabelData.customer_email}</div></div>
                    }
                </CardContent>
            </Card>
        );
    }

    const renderShipTo = () => {
        return (
            <Card className='cardColor' style={{ width: '100%', margin: Utils.isMobile ? '3px 0' : '10px 0', height: Utils.isMobile ? 'auto' : '230px', minHeight: '200px' }}>
                <CardContent>
                    <div className='titleRow'>
                        <div className='header'>Ship-To</div>
                        <div className='edit' onClick={() => { props.changeActiveStep(1) }}><FaEdit /> Edit</div>
                    </div>
                    {
                        props.data.formType === 'Customer/Vendor' &&
                        <>
                        <div className='row'><div className='label'>Customer:</div> <div className='value'>{props.data.customer}</div></div>
                        <div className='row'><div className='label'>Customer Name/ Attn:</div> <div className='value'>{props.data.consigneeCompany}</div></div>
                        </>
                    }
                    <div className='row'><div className='label'>Address:</div> <div className='value'>{props.data.shipToDetails.Address}</div></div>
                    <div className='row'><div className='label'>City:</div> <div className='value'>{props.data.shipToDetails.City}</div></div>
                    <div className='row'><div className='label'>State:</div> <div className='value'>{props.data.shipToDetails.State}</div></div>
                    <div className='row'><div className='label'>Postal Code:</div> <div className='value'>{props.data.shipToDetails.Zip}</div></div>
                    <div className='row'><div className='label'>Phone:</div> <div className='value'>{props.data.shipToDetails.Phone}</div></div>
                    <div className='row'><div className='label'>Fax:</div> <div className='value'>{props.data.shipToDetails.Fax}</div></div>
                    {
                        props.data.formType === 'ReturnLabel' &&
                        <div className='row'><div className='label'>Email:</div> <div className='value'>{props.data.returnLabelData.store_email}</div></div>
                    }
                </CardContent>
            </Card>
        );
    }

    const renderShipmentDetails = () => {
        return (
            <Card className='cardColor' style={{ width: '100%', margin: Utils.isMobile ? '3px 0' : '10px 0', height: Utils.isMobile ? 'auto' : '210px', minHeight: '200px' }}>
                <CardContent>
                    <div className='titleRow'>
                        <div className='header'>Shipment Details</div>
                        <div className='edit' onClick={() => { props.changeActiveStep(2) }}><FaEdit /> Edit</div>
                    </div>
                    <div className='row'><div className='label'>Shipment Reference:</div> <div className='value'>{props.data.shipperRef}</div></div>
                    <div className='row'><div className='label'>Shipment Type:</div> <div className='value'>{Stype}</div></div>
                    <div className='row'><div className='label'>Shipment Date:</div> <div className='value'>{shipDate}</div></div>
                    <div className='row'><div className='label'>Weight:</div> <div className='value'>{props.data.weight} {props.data.weightUnit}</div></div>
                    <div className='row'><div className='label'>Dimension:</div> <div className='value'>{props.data.dimension}</div></div>
                    <div className='row'><div className='label'>Residential:</div> <div className='value'>{props.data.flags.IS_RESIDENTIAL ? 'Yes' : 'No'}</div></div>
                </CardContent>
            </Card>
        );
    }

    const renderCarrierDetails = () => {
        return (
            <Card className='cardColor' style={{ width: '100%', margin: Utils.isMobile ? '3px 0' : '10px 0', height: Utils.isMobile ? 'auto' : '210px', minHeight: '200px' }}>
                <CardContent>
                    <div className='titleRow'>
                        <div className='header'>Carrier Details</div>
                        <div className='edit' onClick={() => { props.changeActiveStep(2) }}><FaEdit /> Edit</div>
                    </div>
                    <div className='row'><div className='label'>Carrier:</div> <div className='value'>{carrier}</div></div>
                    <div className='row'><div className='label'>Service:</div> <div className='value'>{service}</div></div>
                    <div className='row'><div className='label'>Proof:</div> <div className='value'>{props.data.flags.PROOF_FLAG ? 'Yes' : 'No'}</div></div>
                    <div className='row'><div className='label'>Proof Signature:</div> <div className='value'>{props.data.flags.PROOF_SIGNATURE_FLAG ? 'Yes' : 'No'}</div></div>
                    <div className='row'><div className='label'>Saturday Delivery:</div> <div className='value'>{props.data.flags.SATURDAYDELIVERY_FLAG ? 'Yes' : 'No'}</div></div>
                </CardContent>
            </Card>
        );
    }

    const renderMultiBoxShipmentDetails = (box:any)=>{

        return(
            <Card className='multiBoxCard'>
                <CardContent>
                    <Grid container className="fullWidthContainer" spacing={1}>
                        <Grid item xs={6} sm={6}>
                            <div style={{textAlign:"left",fontSize:"18px",fontWeight:"600"}}>Shipment Details</div>
                        </Grid>
                        <Grid item xs={6} sm={6}>                
                            <div className='editDetail' onClick={() => { props.changeActiveStep(2) }}><FaEdit /> Edit</div>    
                        </Grid>
                        <Grid item xs={6} sm={6} style={{textAlign:"right"}}>
                            <div>Shipment Reference:</div>
                        </Grid>
                        <Grid item xs={6} sm={6} style={{textAlign:"left"}}>
                            <div>{props.data.shipperRef}</div>
                        </Grid>
                        <Grid item xs={6} sm={6} style={{textAlign:"right"}}>
                            <div>Shipment Type:</div>
                        </Grid>
                        <Grid item xs={6} sm={6} style={{textAlign:"left"}}>
                            <div>{Stype}</div>
                        </Grid>
                        <Grid item xs={6} sm={6} style={{textAlign:"right"}}>
                            <div>Shipment Date:</div>
                        </Grid>
                        <Grid item xs={6} sm={6} style={{textAlign:"left"}}>
                            <div>{shipDate}</div>
                        </Grid>
                        <Grid item xs={6} sm={6} style={{textAlign:"right"}}>
                            <div>Weight:</div>
                        </Grid>
                        <Grid item xs={6} sm={6} style={{textAlign:"left"}}>
                            <div>{box.weight}{box.weightUnit}</div>
                        </Grid>
                        <Grid item xs={6} sm={6} style={{textAlign:"right"}}>
                            <div>Dimension:</div>
                        </Grid>
                        <Grid item xs={6} sm={6} style={{textAlign:"left"}}>
                            <div>{box.dimension}</div>
                        </Grid>
                        <Grid item xs={6} sm={6} style={{textAlign:"right"}}>
                            <div>Residential:</div>
                        </Grid>
                        <Grid item xs={6} sm={6} style={{textAlign:"left"}}>
                            <div>{box.resFlag ? 'YES' : 'NO'}</div>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        );
    }

    const renderMultiBoxCarrierDetails = (box:any) =>{
        return(
            <Card className='multiBoxCard'>
                <CardContent>
                    <Grid container className="fullWidthContainer" spacing={1}>
                        <Grid item xs={6} sm={6}>
                            <div style={{textAlign:"left",fontSize:"18px",fontWeight:"600"}}>Carrier Details</div>
                        </Grid>
                        <Grid item xs={6} sm={6}>                
                            <div className='editDetail' onClick={() => { props.changeActiveStep(2) }}><FaEdit /> Edit</div>    
                        </Grid>
                        <Grid item xs={6} sm={6} style={{textAlign:"right"}}>
                            <div>Carrier:</div>
                        </Grid>
                        <Grid item xs={6} sm={6} style={{textAlign:"left"}}>
                            <div>{box.carrier}</div>
                        </Grid>
                        <Grid item xs={6} sm={6} style={{textAlign:"right"}}>
                            <div>Service:</div>
                        </Grid>
                        <Grid item xs={6} sm={6} style={{textAlign:"left"}}>
                            <div>{box.serviceName}</div>
                        </Grid>
                        <Grid item xs={6} sm={6} style={{textAlign:"right"}}>
                            <div>Proof:</div>
                        </Grid>
                        <Grid item xs={6} sm={6} style={{textAlign:"left"}}>
                            <div>{box.proofFlag  ? 'YES' : 'NO'}</div>
                        </Grid>
                        <Grid item xs={6} sm={6} style={{textAlign:"right"}}>
                            <div>Proof Signature:</div>
                        </Grid>
                        <Grid item xs={6} sm={6} style={{textAlign:"left"}}>
                            <div>{box.proofSigFlag ? 'YES' : 'NO'}</div>
                        </Grid>
                        <Grid item xs={6} sm={6} style={{textAlign:"right"}}>
                            <div>Saturday Delivery:</div>
                        </Grid>
                        <Grid item xs={6} sm={6} style={{textAlign:"left"}}>
                            <div>{box.saturdayFlag ? 'YES' : 'NO'}</div>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        );
    }

    const renderMultiBoxes = () =>{
        return (
            <>
            <h2 style={{ margin: '5px 0' }}>Multi-Box</h2>
            <Card className='multiBoxCard'>
                {
                    props.data.packages.map((box:any,i:any)=>{
                        return (
                            <>
                                <h2 style={{textAlign:"left",marginLeft:"20px",marginBottom:"5px",textDecoration: "underline"}}>Box: {i+1}</h2>
                                <CardContent>
                                    <Grid container className="fullWidthContainer">
                                        <Grid item xs={6} sm={6}>
                                            {renderMultiBoxShipmentDetails(box)}
                                        </Grid>
                                        <Grid item xs={6} sm={6} style={{display:"flex",justifyContent:"flex-end"}}>
                                            {renderMultiBoxCarrierDetails(box)}
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </>
                        );
                    })
                }
            </Card>
            </>
        );
    }
    return (
        <div className='stepContainer' style={{ height: 'auto' }}>
            <h2 style={{ margin: '5px 0' }}>Review</h2>
            <div className='review'>
                <div className='column'>
                    {renderShipFrom()}
                    {Utils.isMobile ? renderShipTo() : !props.data.multiBox && renderShipmentDetails()}
                </div>

                {!Utils.isMobile && <Divider orientation="vertical" />}

                <div className='column'>
                    {Utils.isMobile ? renderShipmentDetails() : renderShipTo()}
                    {!props.data.multiBox && renderCarrierDetails()}
                </div>
            </div>
            {props.data.multiBox &&  renderMultiBoxes()}
            {renderShiftButtons()}
        </div>
    );
}