import React from 'react';
import './CreateLabel.scss';

import Utils from '../../Common/Utils';
import Constants from '../../Common/Constants';
import LabelUtils from '../../Common/Label.Utils';
import PFUtils from '../../Common/Process.Fulfillment.Utils';

import PrintManager from '../../Managers/Print.Manager';
import StoreManager from '../../Managers/Store.manager';
import IdentityManager from '../../Managers/Identity.manager';
import PFManager from '../../Managers/Process.Fulfillment.Manager';

import Review from './CreateLabelViews/Review';
import CustomizedStepper from '../Shared/Stepper';
import ShipmentType from './CreateLabelViews/ShipmentType';
import ServiceInformation from './CreateLabelViews/ServiceInformation';
import ShippingInformation from './CreateLabelViews/ShippingInformation';

import { Button, Card, CardContent, Divider } from '@material-ui/core';
import PrintIcon from '@material-ui/icons/Print';
import OpenInBrowser from '@material-ui/icons/OpenInBrowser';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import { emitKeypressEvents } from 'readline';
import AuditLogsManager from '../../Managers/Audit.Logs.Manager';
import PFServicesManager from '../../Managers/Fulfillment.Services.Manager';

const FLAGS = Constants.FLAGS;

export default class CreateLabelForm extends React.Component<any, {}> {

    public state = {
        multiBox: false,
        weight: '',
        weightUnit: 'lb',
        isDC: false,
        storeType: IdentityManager.userDefaultStoreType,
        isSPO: false,
        carrier: '0',
        service: '0',
        serviceChanged: false,
        recommendedService: false,
        requiredCreds: false,
        bypassCreds: false,
        approvalProfile: null,
        packages: [],
        customer: '',
        formType: '',
        activeStep: 0,
        dimension: '0',
        reason: '0',
        loading: true,
        shipperRef: '',
        formMessage: '',
        consigneeRef: '',
        currentStore: { id: '', name: '' },
        successData: null,
        selectedStore: { id: '', name: '' },
        isResidential: false,
        formSubmitted: false,
        flags: FLAGS,
        consigneeCompany: '',
        returnLabelData: { customer_email: '', store_email: '', sendMail_customer: false, sendMail_store: false },
        shipDate: Utils.ShipDate,
        shipToDetails: Constants.SHIP_DETAILS,
        shipFromDetails: Constants.SHIP_DETAILS,
        reasonCode: '',
        reasonText: ''
    }

    constructor(props: any) {
        super(props);

        this.newForm = this.newForm.bind(this);
        this.changeState = this.changeState.bind(this);
        this.initializeForm = this.initializeForm.bind(this);
        this.getStepContent = this.getStepContent.bind(this);
        this.changeFormType = this.changeFormType.bind(this);
        this.createShipment = this.createShipment.bind(this);
        this.changeActiveStep = this.changeActiveStep.bind(this);
        this.bypassServiceCreds = this.bypassServiceCreds.bind(this);
    }

    componentDidMount = async () => {
        this.initializeForm();
        this.bypassServiceCreds();
    }

    initializeForm = () => {

        let carrier = "", service = "", carrierServiceData = PFManager.carrierServiceData;
        if (PFManager.default !== null) {
            let def = PFManager.default;
            carrier = def.Carrie.toString();
            service = def.Service.toString();
            this.setState({ carrier, service });
        }

        if (window.location.search.includes('r=1')) {
            if (carrierServiceData !== undefined && carrierServiceData.length > 0) {

                carrier = ""
                service = "";
            }
            this.setState({ activeStep: 1, formType: 'ReturnLabel', carrier, service, returnLabelData: { customer_email: '', store_email: '', sendMail_customer: true, sendMail_store: true } })
        } else {
            this.setState({ returnLabelData: { customer_email: '', store_email: '', sendMail_customer: false, sendMail_store: false } })
        }
    }


    bypassServiceCreds = async () => {
        if (IdentityManager.isAdmin) {
            this.setState({ bypassCreds: true });
            return;
        }
        let _userJobCode = IdentityManager.getUserProperty(Constants.jobCode);
        let _configs: any = await StoreManager.getJobCodeConfig();
        let mgrOpsCode = _configs?.managerOpsJobCode;
        if (_userJobCode && Utils.verifyJobCode(_userJobCode, mgrOpsCode)) {
            this.setState({ bypassCreds: true });
            return;
        }
        this.setState({ bypassCreds: false });
    }

    changeState = (_state: any) => this.setState(_state)

    changeFormType = (formType: string) => this.setState({ formType })

    changeActiveStep = (activeStep: number) => this.setState({ activeStep })

    newForm = (activeStep: number) => this.setState({ activeStep, formSubmitted: false })

    openDialog = (state: any) => this.props.changeState(state);

    getStepContent(step: number) {
        console.log(this.state.shipToDetails, "shipToDetails");
        switch (step) {
            case 0:
                return <ShipmentType
                    formType={this.state.formType}
                    changeState={this.changeState}
                    changeFormType={this.changeFormType}
                    changeActiveStep={this.changeActiveStep}
                />;
            case 1:
                return <ShippingInformation
                    isDC={this.state.isDC}
                    storeType={this.state.storeType}
                    isSPO={this.state.isSPO}
                    customer={this.state.customer}
                    formType={this.state.formType}
                    changeState={this.changeState}
                    shipperRef={this.state.shipperRef}
                    currentStore={this.state.currentStore}
                    isResidential={this.state.isResidential}
                    selectedStore={this.state.selectedStore}
                    shipToDetails={this.state.shipToDetails}
                    changeActiveStep={this.changeActiveStep}
                    returnLabelData={this.state.returnLabelData}
                    shipFromDetails={this.state.shipFromDetails}
                    consigneeCompany={this.state.consigneeCompany}
                />;
            case 2:
                return <ServiceInformation
                    data={this.state}
                    weight={this.state.weight}
                    packages={this.state.packages}
                    changeState={this.changeState}
                    dimension={this.state.dimension}
                    weightUnit={this.state.weightUnit}
                    changeActiveStep={this.changeActiveStep}
                    openDialog={this.openDialog}
                />;
            case 3:
                return <Review data={this.state} createShipment={this.createShipment} changeActiveStep={this.changeActiveStep} />;
            default:
                return <Review changeActiveStep={this.changeActiveStep} />;
        }
    }

    submitAnother = () => {
        let _state = LabelUtils.CreateLabel_State;
        this.setState(_state, () => { this.initializeForm(); });
    }

    sendEmails = async (pdfData: any, successData: any) => {
        //;
        if (this.state.returnLabelData.sendMail_customer == true) {
            let data = LabelUtils.getSuccessData(successData);
            let trackingNo = data.TrackingNo;

            const printData_InputArray: any[] = pdfData.map((r: any) => StoreManager.checkIfIsMAAStoreByStoreString(r.shipper) ? r.PDF_LABEL_DATA : r.BASE_64_PDF);
            const data_pdf: any = await PrintManager.mergePdf(printData_InputArray);
            let res = await PFManager.generateEmail(this.state, this.state.returnLabelData.customer_email, data_pdf, trackingNo[0], this.state.returnLabelData.sendMail_store)
        }
    }

    // This methid is an extra check for Store Users for which they cant change the Default Ship From or Ship To Address
    getStoreIdCreateShipment = () => {
        let selectedId = 0;
        try {
            let currentUserStoreInfo = StoreManager.getStoreDetails_local(IdentityManager.storeNumber);

            if (this.state.formType === 'ReturnLabel') {
                if (this.state.shipToDetails && this.state.shipToDetails.id > 0) {
                    selectedId = this.state.shipToDetails.id;
                }
            }
            else {

                if (this.state.shipFromDetails && this.state.shipFromDetails.id > 0) {
                    selectedId = this.state.shipFromDetails.id;
                }

            }

            if (!(selectedId > 0)) {
                selectedId = currentUserStoreInfo.id;
            }
        }
        catch (e) {
            console.log(e);
        }

        return selectedId;


    }

    getStoreprefix = () => {
        let prefix = "G";
        prefix = IdentityManager.isMAAUser ? "M" : prefix;
        return prefix;
    }

    createShipment = async () => {
        //;
        this.props.changeState({ loading: true });
        if (StoreManager.allStoredata.length == 0) {
            await StoreManager.getAllSCStores();
        }
        let requestData: any = null;
        if (this.state.multiBox && this.state.packages.length > 0) {
            let shipments: any = [];
            for (let i = 0; i < this.state.packages.length; i++) {
                let payLoad = PFUtils.parseData_CreateShipment({ ...this.state }, this.state.packages[i]);
                payLoad.BOX_NO = i + 1;
                shipments.push(payLoad);
            }

            requestData = shipments;
        }
        else {
            requestData = PFUtils.parseData_CreateShipment({ ...this.state });
        }
        //let requestData: any = 
        console.log(this.state, "this.state");
        console.log(requestData, requestData);
        let storeId = this.getStoreIdCreateShipment();
        let id = StoreManager.getSoreShipperPreFixByStoreId(storeId) + storeId;
        if (this.state.formType === 'ReturnLabel') {
            requestData.REASON = 'Customer Return'
            id = StoreManager.getSoreShipperPreFixByStoreId(storeId) + storeId;
        }
        let result: any = null;
        //Use Shipping API or Lambda for PF Services
        if (PFManager.shippingAPI) {
            result = await PFServicesManager.createShipment(id, requestData);
        }
        else {
            result = await PFManager.createShipment(id, requestData);
        }
        //let result: any = await PFServicesManager.createShipment(id, requestData);
        if (result.method === undefined) {
            // For MultiBox
            if (Array.isArray(result)) {
                const errorMsgs = result.filter((obj: any) => obj.response.success == false).map((err: any) => {
                    return {
                        box: err.payLoad.BOX_NO,
                        msg: err.response.msg
                    };
                });
                const allErrors = result.every((obj: any) => obj.response.success == false);
                if (allErrors) {
                    this.props.changeState({ loading: false, openDialog: true, dialogText: { Title: 'Error', MultiBoxMsgs: errorMsgs } });
                }
                else {
                    const successBoxes = result.filter((obj: any) => obj.response.success == true);
                    let printData: any = [];
                    try {
                        for (const box of successBoxes) {
                            box.response.printData.forEach((r: any) => {
                                printData.push(r);
                                PrintManager.sendPrinterRequest(r);
                            });
                        }

                        await PrintManager.handlePrintDataDisplay(printData);
                    }
                    catch (error) {
                        console.log(error);
                        this.props.changeState({ loading: false, openDialog: true, dialogText: { Title: 'Error', Msg: "An error occurred while printing Labels." } });
                    }

                    if (this.state.approvalProfile) {
                        let _result: any = [];
                        for (const box of successBoxes) {
                            const _approvalProfile = this.state.approvalProfile;
                            box.payLoad.isByManager = false
                            const _requiredService = PFManager.unGroupedCarrierServiceData.find(a => a.Carrie == box.payLoad.CARRIER && a.Service == box.payLoad.SERVICE)?.Credentials == "true";
                            if (_requiredService) {
                                const _payLoad = PFUtils.parseApprovalLoggingPayload(box.payLoad, box.response?.data, _approvalProfile);
                                _result.push(_payLoad);
                            }
                        }
                        AuditLogsManager.auditApprovalLog(_result);
                    }
                    else {
                        let _result: any = [];
                        for (const box of successBoxes) {
                            const _requiredService = PFManager.unGroupedCarrierServiceData.find(a => a.Carrie == box.payLoad.CARRIER && a.Service == box.payLoad.SERVICE)?.Credentials == "true";
                            box.payLoad.isByManager = true
                            if (_requiredService) {
                                const _payLoad = PFUtils.parseApprovalLoggingPayload(box.payLoad,box.response?.data, {});
                                _result.push(_payLoad);
                            }
                        }
                        AuditLogsManager.auditApprovalLog(_result);
                    }
                    this.setState({
                        loading: false,
                        formSubmitted: true,
                        formMessage: Constants.MESSAGES.MULTI_LABEL_SUBMITTED,
                        successData: successBoxes
                    });

                    if (errorMsgs && errorMsgs.length > 0) {
                        this.props.changeState({ loading: false, openDialog: true, dialogText: { Title: 'Error', MultiBoxMsgs: errorMsgs } });
                    }

                    this.props.changeState({ loading: false });
                }
            }
            else if (result?.response?.success) {
                if (this.state.formType !== 'ReturnLabel') {
                    if (this.state.approvalProfile) {
                        const _approvalProfile = this.state.approvalProfile;
                        result.payLoad.isByManager = false
                        const _payload = PFUtils.parseApprovalLoggingPayload(result.payLoad, result.response?.data, _approvalProfile);
                        AuditLogsManager.auditApprovalLog(_payload);
                    }
                    else if (this.state.reasonText.length > 0) {
                        result.payLoad.isByManager = true
                        const _payload = PFUtils.parseApprovalLoggingPayload(result.payLoad, result.response?.data,  {});
                        AuditLogsManager.auditApprovalLog(_payload);
                    }
                    await PrintManager.handlePrintDataDisplay(result.response.printData);
                    result.response.printData.forEach((r: any) => PrintManager.sendPrinterRequest(r));
                } else {
                    PrintManager.printData = result.response.printData
                }

                this.setState({
                    loading: false,
                    formSubmitted: true,
                    formMessage: this.state.formType !== 'ReturnLabel' ? Constants.MESSAGES.LABEL_SUBMITTED : Constants.MESSAGES.RETURN_LABEL_SUBMITTED,
                    successData: result
                });

                this.props.changeState({ loading: false });

                await this.sendEmails(result.response.printData, result);
            }
            else {
                console.log(result);
                this.props.changeState({ loading: false, openDialog: true, dialogText: { Title: 'Error', Msg: result.response.msg } });
            }
        } else {
            console.log(result);
            this.props.changeState({ loading: false, openDialog: true, dialogText: { Title: 'Error', Msg: result.method + ': ' + result.details.message } });
        }
    }

    openLabel = async () => {
        if (PrintManager.printData !== undefined) {
            if (PrintManager.printData.length > 0) {
                const printData_InputArray: any[] = PrintManager.printData.map((r: any) => StoreManager.checkIfIsMAAStoreByStoreString(r.shipper) ? r.PDF_LABEL_DATA : r.BASE_64_PDF);
                const data_pdf: any = await PrintManager.mergePdf(printData_InputArray);
                PrintManager.openPdf(data_pdf)
            }
        }
    }

    backToLabels = () => this.props.history.push(this.state.formType === 'ReturnLabel' ? '/ReturnLabels' : '/Labels')

    printLabel = () => PrintManager.printData.forEach((r: any) => PrintManager.sendPrinterRequest(r));

    // RENDER METHODS

    render = () => {
        return (
            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-around' }}>
                {
                    this.state.formSubmitted
                        ?
                        this.renderSuccess()
                        :
                        <CustomizedStepper
                            activeStep={this.state.activeStep}
                            getStepContent={this.getStepContent}
                            changeActiveStep={this.changeActiveStep}
                            steps={['Shipment Type', 'Shipping Information', 'Carrier Information', 'Review']}
                        />
                }
            </div>
        );
    }

    renderSuccess = () => {
        let data = LabelUtils.getSuccessData(this.state.successData);
        return (
            <div>
                <div style={{ color: 'green' }}>
                    <AssignmentTurnedInIcon style={{ fontSize: '80px' }} />
                    <h1>{this.state.formMessage}</h1>
                </div>
                {
                    data !== null && !this.state.multiBox && !Array.isArray(data) &&
                    <div className='SuccessMessage'>
                        <div className='SuccessRow'>
                            <div className='Title'><b>Tracking Number:</b></div>
                            <div className='Value'>
                                {data.TrackingNo.map((data: any, i: number) => <div>{data}</div>)}
                            </div>
                        </div>
                        <div className='SuccessRow'>
                            <div className='Title'><b>Cost:</b></div>
                            <div className='Value'>$ {data.TOTAL}</div>
                        </div>
                        <div className='SuccessRow'>
                            <div className='Title'><b>SUID:</b></div>
                            <div className='Value'>{data.SUID}</div>
                        </div>
                    </div>
                }
                {
                    data && this.state.multiBox && Array.isArray(data) &&
                    this.renderMultiBoxSuccess(data)
                }
                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly', marginTop: "10px" }}>
                    <Button variant="contained" onClick={() => { this.submitAnother() }}>Submit Another</Button>
                    {
                        this.state.formType === 'ReturnLabel' &&
                        <Button variant="contained" color="primary" onClick={() => { this.openLabel() }}><OpenInBrowser /> Open Label</Button>
                    }
                    {
                        this.state.formType === 'ReturnLabel' &&
                        <Button variant="contained" color="primary" onClick={() => { this.printLabel() }}><PrintIcon /> Print Label</Button>
                    }
                    <Button variant="contained" color="primary" onClick={() => { this.backToLabels() }}>Back to Labels</Button>
                </div>
            </div>
        );
    }

    renderMultiBoxSuccess = (data: any) => {
        return (
            <Card>
                {
                    data.map((value: any) => {
                        return (<CardContent>
                            <h2 style={{ textAlign: "left", textDecoration: "underline", color: "#D93A2F" }}>Box : {value.BOX}</h2>
                            <div className='SuccessMessage'>
                                <div className='SuccessRow'>
                                    <div className='Title'><b>Tracking Number:</b></div>
                                    <div className='Value'>
                                        {value.TrackingNo.map((data: any, i: number) => <div>{data}</div>)}
                                    </div>
                                </div>
                                <div className='SuccessRow'>
                                    <div className='Title'><b>Cost:</b></div>
                                    <div className='Value'>$ {value.TOTAL}</div>
                                </div>
                                <div className='SuccessRow'>
                                    <div className='Title'><b>SUID:</b></div>
                                    <div className='Value'>{value.SUID}</div>
                                </div>
                            </div>
                            <Divider />
                        </CardContent>);
                    })
                }
            </Card>
        )
    }
}