import React from 'react';
import './TechAudits.scss';
import Constants from '../../Common/Constants';

export default function AuditDescription(props: any): any {
    
    const getLogType = () => {
        switch (props.data.LogType) {
            case Constants.LOG_TYPES.CREATE_LABEL:
                return  <div style={{display: 'flex', fontSize: '14px', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', textAlign: 'left'}}>
                            <div style={{width: '100%'}}><b>_id:</b> {props.data._id}</div>
                            <div style={{width: '100%'}}>
                                <b>Carrier:</b> {props.data.Carrier} | 
                                <b> Service:</b> {props.data.Service}
                            </div>
                            <div style={{width: '100%'}}>
                                <b>Tracking No:</b> {props.data.ServiceResponse.CARRIER_PACKAGE_REFERENCE} | 
                                <b> Shipper Package Reference:</b> {props.data.ServiceResponse.SHIPPER_PACKAGE_REFERENCE} | 
                                <b> Shipper Shipment Reference:</b> {props.data.ServiceResponse.SHIPPER_SHIPMENT_REFERENCE} | 
                                <b> SUID:</b> {props.data.ServiceResponse.SUID} | 
                                <b> Cost:</b> $ {props.data.ServiceResponse.TOTAL}
                            </div>
                            <div style={{width: '100%'}}>
                                <b>Consignee Contact:</b> {props.data.PayLoad.CONSIGNEE_CONTACT} | 
                                <b> Consignee Address:</b> {props.data.PayLoad.CONSIGNEE_ADDRESS1}, {props.data.PayLoad.CONSIGNEE_ADDRESS2} | 
                                <b> Consignee City:</b> {props.data.PayLoad.CONSIGNEE_CITY}, {props.data.PayLoad.CONSIGNEE_STATE} - {props.data.PayLoad.CONSIGNEE_POSTALCODE} | 
                                <b> Consignee Phone:</b> {props.data.PayLoad.CONSIGNEE_PHONE} | 
                                <b> Residential:</b> {props.data.PayLoad.CONSIGNEE_RESIDENTIAL_FLAG === '1' ? 'True' : 'False'}
                            </div>
                            <div style={{width: '100%'}}>
                                <b>Return Company:</b> {props.data.PayLoad.RETURN_COMPANY} | 
                                <b> Return Contact:</b> {props.data.PayLoad.RETURN_CONTACT} | 
                                <b> Return Address:</b> {props.data.PayLoad.RETURN_ADDRESS1}, {props.data.PayLoad.RETURN_ADDRESS2} | 
                                <b> Return City:</b> {props.data.PayLoad.RETURN_CITY}, {props.data.PayLoad.RETURN_STATE} - {props.data.PayLoad.RETURN_POSTALCODE} | 
                                <b> Return Phone:</b> {props.data.PayLoad.RETURN_PHONE}
                            </div>
                        </div>

            case Constants.LOG_TYPES.RETURN_LABEL:
                return  <div style={{display: 'flex', fontSize: '14px', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', textAlign: 'left'}}>
                            <div style={{width: '100%'}}><b>_id:</b> {props.data._id}</div>
                            <div style={{width: '100%'}}>
                                <b>Carrier:</b> {props.data.Carrier} | 
                                <b> Service:</b> {props.data.Service}
                            </div>
                            <div style={{width: '100%'}}>
                                <b>Tracking No:</b> {props.data.ServiceResponse.CARRIER_PACKAGE_REFERENCE} | 
                                <b> Shipper Package Reference:</b> {props.data.ServiceResponse.SHIPPER_PACKAGE_REFERENCE} | 
                                <b> Shipper Shipment Reference:</b> {props.data.ServiceResponse.SHIPPER_SHIPMENT_REFERENCE} | 
                                <b> SUID:</b> {props.data.ServiceResponse.SUID} | 
                                <b> Cost:</b> $ {props.data.ServiceResponse.TOTAL}
                            </div>
                            <div style={{width: '100%'}}>
                                <b>Consignee Contact:</b> {props.data.PayLoad.CONSIGNEE_CONTACT} | 
                                <b> Consignee Address:</b> {props.data.PayLoad.CONSIGNEE_ADDRESS1}, {props.data.PayLoad.CONSIGNEE_ADDRESS2} | 
                                <b> Consignee City:</b> {props.data.PayLoad.CONSIGNEE_CITY}, {props.data.PayLoad.CONSIGNEE_STATE} - {props.data.PayLoad.CONSIGNEE_POSTALCODE} | 
                                <b> Consignee Phone:</b> {props.data.PayLoad.CONSIGNEE_PHONE} | 
                                <b> Residential:</b> {props.data.PayLoad.CONSIGNEE_RESIDENTIAL_FLAG === '1' ? 'True' : 'False'}
                            </div>
                            <div style={{width: '100%'}}>
                                <b>Return Company:</b> {props.data.PayLoad.RETURN_COMPANY} | 
                                <b> Return Contact:</b> {props.data.PayLoad.RETURN_CONTACT} | 
                                <b> Return Address:</b> {props.data.PayLoad.RETURN_ADDRESS1}, {props.data.PayLoad.RETURN_ADDRESS2} | 
                                <b> Return City:</b> {props.data.PayLoad.RETURN_CITY}, {props.data.PayLoad.RETURN_STATE} - {props.data.PayLoad.RETURN_POSTALCODE} | 
                                <b> Return Phone:</b> {props.data.PayLoad.RETURN_PHONE}
                            </div>
                        </div>

            case 'VoidLabel':
                return  <div style={{display: 'flex', fontSize: '14px', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', textAlign: 'left'}}>
                            <div style={{width: '100%'}}><b>_id:</b> {props.data._id}</div>
                            <div style={{width: '100%'}}>
                                <b>SUID:</b> {props.data.ServiceResponse.SUID}
                            </div>
                        </div>

            case 'StorePrint':
                return  <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            Store Print
                        </div>

            case 'RePrint':
                return  <div style={{display: 'flex', fontSize: '14px', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', textAlign: 'left'}}>
                            <div style={{width: '100%'}}><b>_id:</b> {props.data._id}</div>
                            <div style={{width: '100%'}}>
                                <b>SUID:</b> {props.data.ServiceResponse.SUID}
                            </div>
                        </div>

            case 'PrintConfig':
                return  <div style={{display: 'flex', fontSize: '14px', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', textAlign: 'left'}}>
                            <div style={{width: '100%'}}><b>_id:</b> {props.data._id}</div>
                            <div style={{width: '100%'}}>
                                <b>Action:</b> {props.data.Payload.ACTION}
                            </div>
                            {
                                props.data.Payload.PAYLOAD !== undefined &&
                                <div style={{width: '100%'}}>
                                    <b>Store Number:</b> {props.data.Payload.PAYLOAD.StoreNo} |
                                    {
                                        props.data.Payload.PAYLOAD.PrinterName !== undefined &&
                                        <span>
                                            <b> Printer Name:</b> {props.data.Payload.PAYLOAD.PrinterName} |
                                        </span>
                                    }
                                    {
                                        props.data.Payload.PAYLOAD.PrinterType !== undefined &&
                                        <span>
                                            <b> Printer Type:</b> {props.data.Payload.PAYLOAD.PrinterType} |
                                        </span>
                                    }
                                    {
                                        props.data.Payload.PAYLOAD.PrintingType !== undefined &&
                                        <span>
                                            <b> Printing Type:</b> {props.data.Payload.PAYLOAD.PrintingType}
                                        </span>
                                    }
                                </div>
                            }
                        </div>
            case 'Logout':
                return  <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            Logout
                        </div>

            case 'Login':
                return  <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            Login
                        </div>

            case 'Error':
                return  <div style={{display: 'flex', fontSize: '14px', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', textAlign: 'left'}}>
                            <div style={{width: '100%'}}><b>_id:</b> {props.data._id}</div>
                             <div style={{width: '100%'}}>
                                <b> Action Type:</b> {props.data.ActionType}
                            </div>
                            <div style={{width: '100%'}}>
                                <b> Source:</b> {props.data.SourceLam} | 
                                <b> Status Code:</b> {props.data.StatusCode}
                            </div>
                            <div style={{width: '100%'}}>
                                <b>Message:</b> {props.data.Msg}
                            </div>
                        </div>

            default:
              return  <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <div style={{width: '100%'}}><b>_id:</b> {props.data._id}</div>
                        <div>{props.data.logType}</div>
                      </div>
        }
    }

    return (
        getLogType()
    )
}