import React from 'react';
import './EndOfDay.scss';
import Utils from '../../Common/Utils';
import EODUtils from '../../Common/EOD.Utils';
import moment from 'moment';

export default function EODReportTable(props: any): any {

  const isAllStore = props.store === 'All';

  const rows = isAllStore ? props.rows.sort((a: any, b: any) => a.Store > b.Store ? 1 : -1) : props.rows;

  const renderReportHeader = () => {
    return (
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <div className='report-logo-Container'></div>
        <div style={{fontSize: '50px', fontWeight: 500, display: 'flex', alignItems: 'center'}}>{props.icon} {props.selected}</div>
      </div>
    );
  }

  const renderCarrier = () => {
    return (
      <div style={{display: 'flex', width: '100%', justifyContent: 'space-between'}}>
        <div style={{padding: '10px', border: '2px solid black', width: '80%'}}>
          <div style={{textAlign: 'left', fontSize: '22px', fontWeight: 500}}>Services</div>
          <div style={{display: 'grid', gridTemplateColumns: 'auto auto auto auto auto', fontSize: '12px'}}>
            {
              props.services.map((item: any, i: number, array: Array<any>) => <span style={{margin: '2px 0'}}>- {item}</span>)
            }
          </div>
        </div>
        <div style={{textAlign: 'center', padding: '10px', border: '2px solid black', flexDirection: 'column', width: '13%', display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '18px'}}>
          <div>Total Cartons</div>
          <div style={{borderTop: '1px solid', width: '100%', padding: '5px 0 0 0', margin: '5px 0 0 0'}}>{rows.length}</div>
        </div>
      </div>
    );
  }

  
  const renderStore = () => {
    console.log(props.shipFromDetails);
    let shipDate = moment(props.to).format('YYYY-MM-DD');
    return (
      <div style={{display: 'flex', margin: '5px 0', justifyContent: 'space-between'}}>
        <div style={{width: '30%', display: 'flex', alignItems: 'center', justifyContent: 'center', border: '2px solid black', flexDirection: 'column'}}>
          <div style={{fontSize: '16px', fontWeight: 500, display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', width: '100%'}}>
              <div style={{width: 'auto', minWidth: '25%', textAlign: 'left'}}>Store: </div>
              <div style={{width: 'auto', minWidth:'70%', textAlign: 'left'}}>{isAllStore ? `All Stores` : `${props.shipFromDetails.StoreID} - ${props.shipFromDetails.City}` }</div>
          </div>
          <div style={{marginTop:'5px', fontSize: '16px', fontWeight: 500, display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', width: '100%'}}>
              <div style={{width: '35%', textAlign: 'left'}}>Ship Date: </div>
              <div style={{width: '60%', textAlign: 'left'}}>{shipDate}</div>
          </div>
        </div>
        {
          isAllStore
          ?
            <div style={{width: '65%', height: '50px', padding: '10px', border: '2px solid black', display: 'grid', gridTemplateColumns: 'auto auto'}}>
            </div>
          :
            <div style={{width: '65%', padding: '10px', border: '2px solid black', display: 'grid', gridTemplateColumns: 'auto auto'}}>
              <div style={{display: 'flex', flexDirection: 'column', textAlign: 'left'}}>
                <span style={{margin:'2px 0'}}>{props.shipFromDetails.Address}</span>
                <span style={{margin:'2px 0'}}>{props.shipFromDetails.Address2}</span>
                <span style={{margin:'2px 0'}}>{props.shipFromDetails.State} - {props.shipFromDetails.Zip}</span>
              </div>
              <div style={{display: 'flex', flexDirection: 'column', textAlign: 'left'}}>
                <div style={{display: 'flex', margin:'2px 0'}}>
                  <div style={{width:'60px'}}>Phone: </div>
                  <div>{props.shipFromDetails.Phone}</div>
                </div>
                <div style={{display: 'flex', margin:'2px 0'}}>
                  <div style={{width:'60px'}}>Fax: </div>
                  <div>{props.shipFromDetails.Fax}</div>
                </div>
              </div>
            </div>
        }
      </div>
    );
  }

  const renderHeader = () => {
    return (
      <thead>
        <tr>
          <th>Line#</th>
          {isAllStore && <th>Store</th>}
          <th>Name/Address</th>
          <th>Service</th>
          <th>Tracking Number</th>
          <th>Shipper Reference</th>
          <th>Dimensions</th>
          <th>Weight</th>
          <th>Cost</th>
          {/* <th>Ship Date</th> */}
        </tr>
      </thead>
    );
  }

  const renderBody = () => {
    console.log(rows);
    return (
      <tbody>
        {rows.map((data: any, i: number) => renderRow(data, i))}
        {renderSumRow()}
      </tbody>
    );
  }  
    
  const renderRow = (data: any, rowNum: number) => {
    let Weight = EODUtils.getEODProperty.numeric(data.PayLoad.PACKAGES, 'WEIGHT');
    let address: any[] = EODUtils.getEODProperty.address(data.PayLoad);
    let trackingNumbers: any[] = EODUtils.getEODProperty.trackingNo(data.ServiceResponse);
    let shipRefs: any[] = EODUtils.getEODProperty.shipRef(data.ServiceResponse);
    return (
      <tr key={rowNum}>
        <td>{rowNum+1}</td>
        {isAllStore && <td>{data.Store}</td>}
        <td style={{textAlign:'left',wordBreak: 'break-all'}}>{address.map((data: any, i: number) => <div>{data}</div>)}</td>
        <td>{data.Service}</td>
        <td>{trackingNumbers.map((data: any, i: number) => <div>{data}</div>)}</td>
        <td>{shipRefs.map((data: any, i: number) => <div>{data}</div>)}</td>
        <td>{data.PayLoad.PACKAGES[0].DIMENSION}</td>
        <td>{Weight} lb</td>
        <td>$ {data.ServiceResponse.TOTAL}</td>
        {/* <td>{data.ShipDate}</td> */}
      </tr>
    )
  }
  
  const renderSumRow = () => {
    let sum = 0;
    props.rows.forEach((e: any) => sum += Number(e.ServiceResponse.TOTAL));
    return (
      <tr style={{border: '1px solid black', background: 'white'}}>
        <td><b>Total</b></td>
        {isAllStore && <td></td>}
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td>$ {sum.toFixed(2)}</td>
      </tr>
    )
  }

  const height: string = (Utils.displayHeight*0.98 - 61 - 64) + 'px';

  return (
    <div id='EODReport' style={{background: 'white'}}>
      <div style={{width: '98%', margin: '0 auto'}}>
        {renderReportHeader()}
        {renderCarrier()}
        {renderStore()}
        <table className='EODTable'>
          {renderHeader()}
          {renderBody()}
        </table>
      </div>
    </div>
  );
}
