export default class CacheBuster {

    public static checkVersion = async () => {
        try {
            let _res = await fetch('/meta.json');
            if (_res.status !== 500 && _res.status !== 400) {
                let _result = await _res.json();
                CacheBuster.cacheBust(_result);
            }
        } catch (error) {
            console.log(error);
        }
    }

    public static cacheBust = (_result: any) => {
        let updateTag = localStorage.getItem('updateTag');
        if (updateTag === undefined || updateTag !== _result.version) {
            // update tag in local storage
            localStorage.setItem('updateTag', _result.version);
            // delete browser cache and hard reload
            window.location.reload();
        }
    }
}