import React from 'react';
import './Labels.scss';
import Utils from '../../Common/Utils';
import DatePicker from '../Shared/DatePicker';
import LabelUtils from '../../Common/Label.Utils';

import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';
import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import { Divider, Accordion, AccordionSummary, AccordionDetails, Button, IconButton } from '@material-ui/core';

import ClearIcon from '@material-ui/icons/Clear';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import FilterListIcon from '@material-ui/icons/FilterList';


const FilterClear = withStyles(({
    root: {
        padding: '0 8px',
        fontWeight: 600,
        color: '#D93A2F',
        border: '1px solid #D93A2F',
        '&:hover': {
            color: 'white',
            backgroundColor: "#D93A2F"
        },
    },
}))(Button);

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        chip: {
            fontSize: '15px',
            margin: theme.spacing(0.4),
            borderRadius: '2px !important'
        },
    }),
);

const CustomAccordionSummary = withStyles({
    root: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#CECECE',
        border: '1px solid #D93A2F',
    }
})(AccordionSummary);

const CustomAccordionDetails = withStyles((theme) => ({
    root: {
        borderTop: '0',
        padding: '10px 2px',
        border: '1px solid #D93A2F',
    },
}))(AccordionDetails);

export default function LabelFilter(props: any): any {
    const classes = useStyles();

    const timeSelection: any[] = ['Today', 'Yesterday', '30 Days', '90 Days', 'Custom'];

    const statusSelection: any[] = ['All', 'Active', 'Voided'];

    let apps: any[] = props.appFilterData.list !== undefined ? props.appFilterData.list : [];
    let carrier: any[] = props.carrierFilterData;//.map((r: any) => r.carrier);
    let services: any[] = [];

    if (props.selectedFilterData.carrier !== '') {
        let selected = carrier.find((r: any) => props.selectedFilterData.carrier == r.key);
        if (selected !== undefined && selected !== null) {
            services = selected.services;
        }
    }

    // CARRIER FILTER METHOD

    const carrierChange = (carrier: any) => () => {
        let selectedFilterData = { services: [], carrier: props.selectedFilterData.carrier === carrier ? '' : carrier };
        props.changeStateReload({ selectedFilterData, pagination: { size: 25, page: 1 }, filterChanged: true });
    };

    // SERVICE FILTER METHOD

    const serviceChange = (service: any) => () => {
        let carrier: string = props.selectedFilterData.carrier;
        let services: any[] = props.selectedFilterData.services;

        if (services.indexOf(service) !== -1) { services.splice(services.indexOf(service), 1) }
        else { services = [service] }

        let selectedFilterData = { services: services, carrier: carrier };
        props.changeStateReload({ selectedFilterData, pagination: { size: 25, page: 1 }, filterChanged: true });
    };

    // APPLICATION FILTER METHOD

    const appChange = (app: any) => () => {
        let list = props.appFilterData.list;
        let selected = props.appFilterData.selected;

        if (selected.indexOf(app) !== -1) { selected.splice(selected.indexOf(app), 1); }
        else { selected = [app] }

        let appFilterData = { list: list, selected: selected };
        props.changeStateReload({ appFilterData, pagination: { size: 25, page: 1 }, filterChanged: true });
    };

    // STATUS SELECTION

    const statusSelectionChange = (status: any) => () => {
        if (props.status !== status) {
            props.changeStateReload({ status, pagination: { size: 25, page: 1 }, filterChanged: true });
        }
    }

    // TIME FILTER METHODS

    const timeSelectionChange = (dateFilter: any) => () => {
        if (props.dateFilter !== dateFilter) {
            changeTime({ dateFilter: dateFilter, pagination: { size: 25, page: 1 }, filterChanged: true })
        }
    }

    const handleDateChange_From = (date: Date) => {
        if (Utils.startDate.getTime() >= date.getTime()) {
            changeTime({ from: date, pagination: { size: 25, page: 1 }, filterChanged: true })
        }
    }

    const handleDateChange_To = (date: Date) => {
        if (Utils.startDate.getTime() >= date.getTime()) {
            changeTime({ to: date, pagination: { size: 25, page: 1 }, filterChanged: true })
        }
    }

    const resetFilters = () => {
        changeTime({ dateFilter: 'Today', pagination: { size: 25, page: 1 }, filterChanged: false });
        props.resetSearchTerm();
    }

    const changeTime = (state: any) => {
        props.selectResetAll();
        props.changeStateReload(state);
        props.resetFilters();
    }

    const [expanded, setExpanded] = React.useState<boolean>(true);

    const handleChange = () => {
        let isExpanded = !props.isExpanded;
        setExpanded(isExpanded);
        props.changeState({ isExpanded: isExpanded });
    };

    return (
        <div style={{ width: '100%', display: 'flex', margin: '5px 0', justifyContent: 'space-between' }}>
            {/* <Paper className={classes.root}> */}

            <Accordion expanded={props.isExpanded} className={classes.root}>
                <CustomAccordionSummary>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', height: '24px' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}><FilterListIcon /> <b>Filters</b></div>
                        <div>
                            {
                                props.filterChanged &&
                                <FilterClear variant="outlined" color="primary" startIcon={<ClearIcon />} onClick={resetFilters}>Clear Filters</FilterClear>
                            }
                            <IconButton onClick={handleChange}>{props.isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}</IconButton>
                        </div>
                    </div>
                </CustomAccordionSummary>
                <CustomAccordionDetails>
                    <div className='labelFilters'>
                        <div className='labelFilterSection' style={{ maxWidth: 'inherit', minWidth: '300px' }}>
                            <div className='Title'>Period</div>
                            <div>
                                {timeSelection.map((data: any) =>
                                    <Chip
                                        clickable
                                        key={data}
                                        label={data}
                                        color="primary"
                                        className={classes.chip}
                                        disabled={!props.dataLoaded}
                                        onClick={timeSelectionChange(data)}
                                        variant={props.dateFilter === data ? 'default' : 'outlined'}
                                    />
                                )}
                            </div>
                            {
                                props.dateFilter === 'Custom' &&
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <DatePicker currentDate={props.from} setDate={handleDateChange_From} style={{ width: '135px' }} />
                                    <span style={{ margin: '10px 10px 0px 10px' }}>To</span>
                                    <DatePicker currentDate={props.to} setDate={handleDateChange_To} style={{ width: '135px' }} />
                                </div>
                            }
                        </div>

                        <Divider orientation="vertical" flexItem />

                        <div className='statusFilterSection' style={{ maxWidth: '125px', minWidth: '100px', width: 'auto' }}>
                            <div className='Title'>Status</div>
                            <div>
                                {props.statusFilters.map((data: any) =>
                                    <Chip
                                        clickable
                                        key={data.key}
                                        label={data.doc_count === 0 ? <div>{data.key}</div> : <div>{data.key} <b>({data.doc_count})</b></div>}
                                        color="primary"
                                        className={classes.chip}
                                        disabled={!props.dataLoaded}
                                        onClick={statusSelectionChange(data.key)}
                                        variant={props.status === data.key ? 'default' : 'outlined'}
                                    />
                                )}
                            </div>
                        </div>

                        <Divider orientation="vertical" flexItem />

                        <div className='labelFilterSection'>
                            <div className='Title'>Application</div>
                            <div>
                                {
                                    apps.length > 0
                                        ?
                                        apps.map((data: any) =>
                                            <Chip
                                                clickable
                                                key={data.key}
                                                label={data.doc_count === 0 ? <div>{data.key}</div> : <div>{data.key} <b>({data.doc_count})</b></div>}
                                                color="primary"
                                                className={classes.chip}
                                                disabled={!props.dataLoaded}
                                                onClick={appChange(data.key)}
                                                variant={props.appFilterData.selected.indexOf(data.key) >= 0 ? 'default' : 'outlined'}
                                            />
                                        )
                                        :
                                        <div style={{ margin: '12px 0 0 0' }}>No Applications</div>
                                }
                            </div>
                        </div>

                        <Divider orientation="vertical" flexItem />

                        <div className='labelFilterSection'>
                            <div className='Title'>Carrier</div>
                            <div>
                                {
                                    carrier.length > 0
                                        ?
                                        carrier.map((data: any) =>
                                            <Chip
                                                clickable
                                                key={data.key}
                                                label={data.doc_count === 0 ? <div>{data.key}</div> : <div>{data.key} <b>({data.doc_count})</b></div>}
                                                color="primary"
                                                className={classes.chip}
                                                disabled={!props.dataLoaded}
                                                onClick={carrierChange(data.key)}
                                                variant={props.selectedFilterData.carrier === data.key ? 'default' : 'outlined'}
                                            />
                                        )
                                        :
                                        <div style={{ margin: '12px 0 0 0' }}>No Carriers</div>
                                }
                            </div>
                        </div>

                        <Divider orientation="vertical" flexItem />

                        <div className='labelFilterSection'>
                            <div className='Title'>Service</div>
                            {
                                services.length > 0
                                    ?
                                    <div>
                                        {services.map((data: any) =>
                                            <Chip
                                                clickable
                                                key={data.key}
                                                label={data.doc_count === 0 ? <div>{data.key}</div> : <div>{data.key} <b>({data.doc_count})</b></div>}
                                                color="primary"
                                                className={classes.chip}
                                                disabled={!props.dataLoaded}
                                                onClick={serviceChange(data.key)}
                                                variant={props.selectedFilterData.services.indexOf(data.key) >= 0 ? 'default' : 'outlined'}
                                            />
                                        )}
                                    </div>
                                    :
                                    carrier.length > 0
                                        ? <div style={{ margin: '12px 0 0 0' }}>Select a Carrier</div>
                                        : <div style={{ margin: '12px 0 0 0' }}>No Services</div>
                            }
                        </div>
                    </div>
                </CustomAccordionDetails>
            </Accordion>

            {/* </Paper> */}
        </div>
    );
}	