import IdentityManager from "./Identity.manager";
import AddressBookUtils from "../Common/AddressBook.utils";
import StoreManager from "./Store.manager";

export default class AddressBookManager {

    public static DCList: any[] = [];

    public static getCustomer = async (store: string, word: string) => {
        //;
        let result: any[] = [];
        let token = IdentityManager.AccessToken;
        if (store && store.length > 0) {
            store = StoreManager.getSoreShipperPreFixByStoreId(store) + store;
        }
        let _res = await fetch(`/address/getCustomer?store=${store}&word=${word}`, { headers: { 'Authorization': `Bearer ${token}` } });
        if (_res.status !== 500 && _res.status !== 400) {
            let _result = await _res.json();
            _result = _result.hits.hits
                .map((r: any) => AddressBookUtils.parseAddress(r._source))
                .sort((a: any, b: any) => a.name.toLowerCase() > b.name.toLowerCase() ? -1 : 1)
                .sort((a: any, b: any) => AddressBookUtils.sortStore(a, b))
            result = _result.slice(0, 5);
        }
        return result;
    }


    public static searchSPOOrders = async (order: string) => {
        let result: any = [];
        let token = IdentityManager.AccessToken;
        let _res = await fetch(`/spo/search?order=${order}`, { headers: { 'Authorization': `Bearer ${token}` } });
        if (_res.status !== 500 && _res.status !== 400) {
            let res = await _res.json();
            result = res.resultTable.rows
                .map((r: any) => AddressBookUtils.parseSPOData(r))
        }
        return result;
    }
}