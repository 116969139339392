import React from 'react';
import './Dashboard.scss';
import '../Dialog/Dialogs.scss';
import { Button, Dialog, DialogContent, DialogContentText, DialogActions, Slide, DialogTitle, Select, MenuItem, TextField } from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions/transition';

import ErrorIcon from '@material-ui/icons/Error';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import PrintConfigurationManager from '../../Managers/PrintConfiguration.Manager';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
  ) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddPrinter(props: any): any {
    
    const [check, setCheck] = React.useState<boolean>(false);
    const [storeNo, setStoreNo] = React.useState<string>('');
    const [printerName, setPrinterName] = React.useState<string>('');
    const [printerType, setPrinterType] = React.useState<string>('');
    const [printingType, setPrintingType] = React.useState<string>('');

    const confirm = async () => {
        if (storeNo !== '' && printerName !== '' && printerType !== '' && printingType !== '') {
            await props.createNewRow({StoreNo: storeNo, PrinterName: printerName, PrinterType: printerType, PrintingType: printingType});
        }
        setCheck(true)
    }

    return (
        <Dialog open={props.open} onClose={() => {props.handleClose()}} TransitionComponent={Transition}>
            <DialogTitle className={'itemDialogBorder'}>
                <div className={'dialogHeader'}>
                    Create New Printer Settings
                </div>
            </DialogTitle>
            <DialogContent className='itemDialogBorder'>
                <div className='createRow'>
                    <div>Store number</div>
                    <div>
                        <TextField
                            error={storeNo === '' && check}
                            value={storeNo} size="small"
                            placeholder='Enter Store Number'
                            style={{width: '200px'}} variant="outlined"
                            onChange={(e)=>{setStoreNo(e.target.value);setCheck(false)}}
                        />
                    </div>
                </div>
                <div className='createRow'>
                    <div>Printer Name</div>
                    <div>
                        <TextField
                            error={printerName === '' && check}
                            value={printerName} size="small"
                            placeholder='Enter Printer Name'
                            style={{width: '200px'}} variant="outlined"
                            onChange={(e)=>{setPrinterName(e.target.value);setCheck(false)}}
                        />
                    </div>
                </div>
                <div className='createRow'>
                    <div>Printer Type</div>
                    <div>
                        <Select 
                            value={printerType}
                            style={{width: '200px'}}
                            error={printerType === '' && check}
                            onChange={(e: any)=>{setPrinterType(e.target.value);setCheck(false)}} 
                        >
                            <MenuItem value={''}>Select Printer Type</MenuItem>
                            {PrintConfigurationManager.printerTypes.map((data: any, i: number) => <MenuItem value={data} key={i}>{data}</MenuItem>)}
                        </Select>
                    </div>
                </div>
                <div className='createRow'>
                    <div>Printing Type</div>
                    <div>
                        <Select 
                            value={printingType}
                            style={{width: '200px'}}
                            error={printingType === '' && check}
                            onChange={(e: any)=>{setPrintingType(e.target.value);setCheck(false)}}
                        >
                            <MenuItem value={''}>Select Printing Type</MenuItem>
                            {PrintConfigurationManager.printingTypes.map((data: any, i: number) => <MenuItem value={data} key={i}>{data}</MenuItem>)}
                        </Select>
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button size='small' onClick={confirm} variant="contained" color='primary'>
                    <span className='dialogButton'>Confirm</span>
                </Button>
                <Button size='small' onClick={() => {props.handleClose()}} variant="contained" style={{color:'white', background: 'grey'}}>
                    <span className='dialogButton'>Close</span>
                </Button>
            </DialogActions>
        </Dialog>
    );
}