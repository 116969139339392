import Constants from "../Common/Constants";
import IdentityManager from "./Identity.manager";
import jwt from 'jwt-decode';
// import CryptoUtils from "../Common/Crypto.Utils";

export default class StoreManager {

    public static config: any = {};
    public static DCList: any[] = [];
    public static storeList: any[] = [];
    public static currentStore: any = {};
    public static allStoredata: any[] = [];

    public static getStoreDetails_local = (id: string) => {
        let result = Constants.SHIP_DETAILS;
        if (StoreManager.allStoredata.length > 0) {
            let res = StoreManager.allStoredata.find(r => r.StoreId.toString() === id)
            if (res !== undefined && res !== null) {
                result = res;
            }
        }
        return result;
    }
    
    public static getConfigs = () => {
        let token = IdentityManager.AccessToken;
        let _res = fetch(`/config`, {headers: {'Authorization': `Bearer ${token}`}})
        .then(async (value: Response) => StoreManager.config = await value.json())
        .catch(error => console.log("/config", error));
    }

    public static getJobCodeConfig = async () => {
        let token = IdentityManager.AccessToken;
        let _res = await fetch(`/config`, {headers: {'Authorization': `Bearer ${token}`}})
        if (_res.status !== 500 && _res.status !== 400) {
            let _result = await _res.json();
            return _result;
        }
    }

    public static getStoreList = async () => {
        let token = IdentityManager.AccessToken;
        let _res = await fetch(`/store/storeList`, {headers: {'Authorization': `Bearer ${token}`}})
        if (_res.status !== 500 && _res.status !== 400) {
            let _result = await _res.json();
            if (_result.length > 0) {
                let storeList: any[] = _result;
                StoreManager.storeList = storeList.sort((a: any, b: any) => a.id > b.id ? 1 : -1);
            }
        }
    }

    public static getStoreDetails = async (storeId: string) => {
        let token = IdentityManager.AccessToken;
        let _res = await fetch(`/store/storeInformation/${storeId}`, {headers: {'Authorization': `Bearer ${token}`}})
        let _result = await _res.json();
        let result = Constants.SHIP_DETAILS; 
        if (_result.length > 0) {
            result = _result[0]
            result['Address2'] = "";
            result.Fax = result.Fax !== null && result.Fax !== undefined ? result.Fax.replace(/[^0-9]/g, '') : '';
            result.Phone = result.Phone !== null && result.Phone !== undefined ? result.Phone.replace(/[^0-9]/g, '') : '';
        }
        return result;
    }


    public static getDCList = async () => {
        let token = IdentityManager.AccessToken;
        let _res = await fetch(`/store/DCList`, {headers: {'Authorization': `Bearer ${token}`}})
        if (_res.status !== 500 && _res.status !== 400) {
            let _result = await _res.json();
            if (_result.length > 0) {
                _result.forEach((data: any) => {
                    data['Address'] = data.Address1 !== undefined && data.Address1 !== null ?  data.Address1 : "";
                    data['Address2'] = "";
                    data.Fax = data.Fax !== undefined && data.Fax !== null ? data.Fax.replace(/[^0-9]/g, '') : "";
                    data.Phone = data.Phone !== undefined && data.Phone !== null ? data.Phone.replace(/[^0-9]/g, '') : "";
                    data.id = data.StoreID !== undefined && data.StoreID !== null ? data.StoreID : "";
                });
                StoreManager.DCList = _result.sort((a: any, b: any) => a.StoreID > b.StoreID ? 1 : -1);
            }
        }
    }
    
    public static getAllSCStores = async () => {
        let token = IdentityManager.AccessToken;
        let _res = await fetch(`/store/getAllSCStores`, {headers: {'Authorization': `Bearer ${token}`}})
        if (_res.status !== 500 && _res.status !== 400) {
            let _result = await _res.json();
            if (_result.Stores.length > 0) {
                _result.Stores.forEach((data: any) => {
                    data['Address'] = data.Address1 !== undefined && data.Address1 !== null ?  data.Address1 : "";
                    data.Address2 = data.Address2 !== undefined && data.Address2 !== null ?  data.Address2 : "";
                    data.Fax = data.Fax !== undefined && data.Fax !== null ? data.Fax.replace(/[^0-9]/g, '') : "";
                    data.Phone = data.Phone !== undefined && data.Phone !== null ? data.Phone.replace(/[^0-9]/g, '') : "";
                    data.Zip = data.ZIP !== undefined && data.ZIP !== null ? data.ZIP : "";
                    data.id = data.StoreId !== undefined && data.StoreId !== null ? data.StoreId : "";
                    data.StoreEmail = data.StoreEmail !== undefined && data.StoreEmail !== null ? data.StoreEmail : "";
                });
                StoreManager.storeList =  _result.Stores.map((r: any) => ({id: r.StoreId.toString(), name: r.City , company: r.Company})).sort((a: any, b: any) => parseInt(a.id) > parseInt(b.id) ? 1 : -1);//.sort((a: any, b: any) => a.id > b.id ? 1 : -1);
                console.log(StoreManager.storeList,"StoreManager.storeList");
                StoreManager.allStoredata = _result.Stores.sort((a: any, b: any) => a.StoreID > b.StoreID ? 1 : -1);
            }
        }
    }

    public static getSoreShipperPreFixByStoreId = (storeId: any)=>{

       let preFix = "G";
       storeId = storeId ? storeId.toString():"";
       if(storeId && storeId.length > 0)
       {
           let filter = StoreManager.allStoredata.filter(a=>a.StoreId && a.StoreId.toString() === storeId);
           if(filter && filter.length > 0)
           {
               let selected = filter[0];

               switch(selected.Company)
               {
                   case Constants.gcStoreType:
                    preFix = "G";
                    break;
                   case Constants.mmaStoreType:
                    preFix = "M";
                    break;
                    default:
                        break;
               }

             
           }
       }

       return preFix;

    }

    public static checkIfIsMAAStoreByStoreString = (store:any)=>{

        let isMAA  = false;
        isMAA = store && store.length > 0 ? (store.charAt(0) == "M" ? true: false): false;
        return isMAA;

    }
}