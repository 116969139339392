import Constants from "../Common/Constants";
import IdentityManager from "./Identity.manager";
import moment from "moment";
import Utils from "../Common/Utils";

export default class ExcelManager {

    public static addExcelEntry = async (body: any) => {
        let token = IdentityManager.AccessToken;
        try {
            let _res = fetch(
                `/excel/addExcelEntry`,
                {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(body)
                }
            ).then(response => response.json()).catch((error: any) => {
                console.log('addExcelEntry Error', error)
            });
        } catch (error) {
            console.log('addExcelEntry Error', error);
            console.log(error);
        }
    }

    public static getExcelEntry = async (body: any) => {
        let token = IdentityManager.AccessToken;
        try {
            let result = await fetch(
                `/excel/getExcelEntryByID`,
                {
                    method: 'post',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(body)
                }
            )
                .then(async (response) => {
                    let res: any = await response.json();
                    return res;
                })
                .catch(error => console.log('getExcelEntry Error', error));
            return result;
        } catch (error) {
            console.log('getExcelEntry Error', error);
            console.log(error);
        }
    }
    public static sendRequestForExportUrl = async (body: any) => {
        let token = IdentityManager.AccessToken;
        try {
            let result = await fetch(
                `/excel/sendRequestForExportUrl`,
                {
                    method: 'post',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(body)
                }
            )
                .then(async (response) => {
                    let res: any = await response.json();
                    return res;
                })
                .catch(error => console.log('getExcelEntry Error', error));
            return result;
        } catch (error) {
            console.log('getExcelEntry Error', error);
            console.log(error);
        }
    }

}

// try {
// } catch (error) {
//     console.log(error);
// }