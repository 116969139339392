import IdentityManager from "./Identity.manager";
import AuditLogsManager from "./Audit.Logs.Manager";
import Constants from "../Common/Constants";

export default class PrintConfigurationManager {
    
    public static requestTypes = {
        Read: "READ",
        Create: "CREATE",
        Update: "UPDATE",
        Delete: "DELETE",
        Configs: "CONFIGS"
    }

    public static printingTypes: any[] = [];
    public static printerTypes: any[] = [];
    
    public static sendPrintConfigRequest = async (body: any) => {
        let token = IdentityManager.AccessToken;
        let _result: any = {};
        try {
            let _res =  await fetch(
                `/pc/printConfig`,
                {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(body)
                }
            );
            if (_res.status !== 500 && _res.status !== 400) {
                _result = await _res.json();
                if (body.PAYLOAD !== undefined) {
                    let createLog = await AuditLogsManager.createLog(Constants.LOG_TYPES.PRINT_CONFIG, body, _result);
                }
            }
        } catch (error) {
            console.log(error);
        }
        return _result;
    }
}