import React from 'react';
import './App.scss';
import Loader from "react-loader-spinner";
import { BrowserRouter as Router, Route } from "react-router-dom";

import partyPopper from './party-popper.png';
import megaphone from './megaphone.png'
import confetti from './confetti.png'
import garland from './garland.png'

import Utils from './Common/Utils';
import Constants from './Common/Constants';
import CacheBuster from './Managers/Cache.Buster'
import AuthManager from './Managers/Auth.manager';
import IdentityManager from './Managers/Identity.manager';

import EOD from './Routes/EOD';
import Labels from './Routes/Labels';
import TechAudits from './Routes/TechAudits';
import AddressBook from './Routes/AddressBook';
import CreateLabel from './Routes/CreateLabel';
import LandingPage from './Routes/LandingPage';
import ReturnLabels from './Routes/ReturnLabels';
import PrinterConfiguration from './Routes/PrinterConfiguration';
import Export from './Routes/Export';
import Navbar from './Subcomponent/Shared/Navbar';
import DrawerRow from './Subcomponent/Shared/DrawerRow';
import UserInfoDialog from './Subcomponent/Dialog/UserInfoDialog';

import ErrorIcon from '@material-ui/icons/Error';
import PFManager from './Managers/Process.Fulfillment.Manager';

import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import PFServicesManager from './Managers/Fulfillment.Services.Manager';

const AttentionMessage = ({ closeToast, toastProps, message, heading }: any) => (
  <div>

    {/* <img style={{ textAlign: "center" }} width="50" height="50" src={megaphone} alt="Party Popper" /> */}
    <div style={{ display: "flex", alignItems: "center", gap: "10px", justifyContent: "center" }}>
      <img width="50" height="50" src={megaphone} alt="Party Popper" />
      <div style={{ textAlign: "center", width: "100%" }}>
        <h3 style={{ color: "#FFFFFF", backgroundColor: "#D93A2F", padding: "5px", margin: "0 auto" }}>
          {heading}
        </h3>
      </div>
    </div>
    {/* <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
      <img width="40" height="40" src={megaphone} alt="Party Popper" />
      <div>
        <h3 style={{ color: "#FFFFFF", backgroundColor: "#D93A2F", padding: "5px" }}>
          {heading}
        </h3>
      </div>
    </div> */}
    {/* <img style={{ textAlign: "center" }} width="40" height="40" src={megaphone} alt="Party Popper" />
    <div style={{ textAlign: "center" }}>
      <h3 style={{ color: "#FFFFFF", backgroundColor: "#D93A2F", padding: "5px" }}>
        {heading}
      </h3>
    </div> */}
    <div style={{ textAlign: "left" }}>
      {


        <div dangerouslySetInnerHTML={{ __html: message }} />
        // <ul>
        //   <li><b>Select Nultiple Dimension Box</b> </li>
        //   <ol>
        //     <li>Now you can add multiple dimension box</li>
        //   </ol>
        //   <li><b>Permission Required</b> </li>
        //   <ol>
        //     <li>Manager Persmission is required for premimum Services</li>
        //   </ol>
        // </ul>
        // <ul>
        //   {
        //     message.split('.')?.map((sentence: any) => {
        //       return sentence ? <li>{sentence} </li> : null
        //     })
        //   }

        // </ul>


      }
    </div>

  </div >
)
export default class App extends React.Component {

  public state = {
    user: '',

    error: false,
    loading: true,

    selected: '',
    displayDrawer: false,

    userProfileDialog: false,
    navigation: [],

    notificationConfig: []

  }

  constructor(props: any) {
    super(props);

    this.changeState = this.changeState.bind(this);
    this.toggleDrawer = this.toggleDrawer.bind(this);
  }

  componentDidMount = async () => {
    await CacheBuster.checkVersion();
    this.setState({ loading: true });
    const result = await PFManager.getNotificationConfig();
    await this.checkAuth();
    const notificationResult = result?.find((x: any) => x.key == Constants.Configs.NotificationConfig)?.value;
    const notificationArr = notificationResult ? JSON.parse(notificationResult) : [];
    this.setState({ notificationConfig: notificationArr })

    { !this.state.loading && this.showNotificationToaster() }
  }

  authorizeUser = async () => {
    if (Utils.checkUserProfile_LocalStorage()) {
      let userInfoString = localStorage.getItem(Constants.USER_PROFILE);
      if (typeof userInfoString == "string") {
        await this.resumeInit();
      }
    } else {
      localStorage.removeItem(Constants.createLogin);
      AuthManager.signin();
    }
  }

  checkAuth = async () => {
    if (IdentityManager.isTokenValid) {
      localStorage.removeItem(Constants.USER_PROFILE);
      await this.authorizeUser();
    } else {
      await this.resumeInit();
    }
  }

  // checkAuth = () => {
  //   let userProfileString = localStorage.getItem(Constants.USER_PROFILE)
  //   if (userProfileString !== null) {
  //     let user = JSON.parse(userProfileString);
  //     this.setState({user});
  //   } else {
  //     if (window.location.href.includes("access_token")) {
  //       AuthManager.getToken();
  //     } else {
  //       if (IdentityManager.isTokenValid) {
  //         localStorage.removeItem(Constants.USER_PROFILE);
  //         this.authorizeUser();
  //       } else {
  //         this.resumeInit();
  //       }
  //     }
  //   }
  // }

  // authorizeUser = () => {
  //   if (Utils.checkUserProfile_LocalStorage()) {
  //     let userInfoString = localStorage.getItem(Constants.USER_PROFILE);
  //     if (typeof userInfoString == "string") {
  //       let userInfo: any = JSON.parse(userInfoString);
  //       this.resumeInit();
  //     }
  //   } else {
  //     AuthManager.signin();
  //   }
  // }

  resumeInit = async () => {
    //Use Shipping API or Lambda for PF Services
    if (PFManager.shippingAPI) {
      await PFServicesManager.getGroups();
    }
    else {
      await PFManager.getGroups();
    }

    this.setState({ navigation: IdentityManager.getUserNavigation(), loading: false }, async () => {
      if (IdentityManager.isUserAuthorized) {
        this.setHeader();
        await Utils.getInitialData();
      } else {
        this.setState({ error: true });
      }
    });

  }

  setHeader = () => {
    let list = Constants.DRAWER_DATA
    let name = window.location.pathname.includes('/') && window.location.pathname.length > 1 ? window.location.pathname.split('/')[1] : window.location.pathname;
    let selected = list.find(r => name === r.Route);
    if (selected !== undefined && selected !== null) {
      this.setState({ selected: selected.Header });
    }
  }


  showNotificationToaster = () => {
    const currentDate = new Date()
    this.state.notificationConfig.map((msg: any) => {
      if ((new Date(msg.startDate) <= currentDate) && (new Date(msg.endDate) >= currentDate)) {
        return toast(<AttentionMessage message={msg.message} heading={msg.heading} />)
      }
    })
  }

  toggleDrawer = () => { let displayDrawer = !this.state.displayDrawer; this.setState({ displayDrawer }) }

  setSelectedOption = (selected: string) => this.setState({ selected, displayDrawer: false })

  changeState = (data: any) => this.setState(data)



  render = () => {
    return (
      <div className="App">
        <UserInfoDialog
          changeState={this.changeState}
          open={this.state.userProfileDialog}
        ></UserInfoDialog>
        {/* {
          this.state.user !== "" && <Navbar></Navbar>
        } */}
        {
          this.state.error
            ?
            this.renderError()
            :
            this.renderDrawer()
        }
        {<ToastContainer style={{ width: '500px' }} autoClose={120000} />}
      </div>
    );
  }

  renderDrawer = () => {
    const height = Utils.displayHeight + 'px';
    console.log(this.state.navigation, "navigationnavigationnavigationnavigation");
    const drawerData = Constants.DRAWER_DATA;
    // const displayDrawerData = !IdentityManager.isAdmin && drawerData.length > 3
    //   ? drawerData.splice(drawerData.length - 1, 2)
    //   : Constants.DRAWER_DATA;
    const displayDrawerData = this.state.navigation;

    return (
      <Router>
        {
          Utils.isMobile && this.state.displayDrawer &&
          <div style={{ height: height, width: '100vw', position: 'absolute', zIndex: 2, top: '62px', background: 'rgba(0,0,0,0.7)' }} onClick={this.toggleDrawer}>
            <div style={{ height: height, width: '200px', background: '#FFFFFF', zIndex: 3 }}>
              {
                displayDrawerData.map((item: any, i: number) =>
                  <DrawerRow data={item} key={i} selected={this.state.selected} setSelectedOption={this.setSelectedOption}></DrawerRow>)
              }
            </div>

          </div>
        }

        <Navbar
          loading={this.state.loading}
          changeState={this.changeState}
          toggleDrawer={this.toggleDrawer}
          setSelectedOption={this.setSelectedOption}
        />
        {
          this.state.loading
            ?
            <div style={{ marginTop: '100px' }}>
              <Loader type="TailSpin" color="#D93A2F" height={80} width={80} />
            </div>
            :
            <div className="drawerDiv">
              {
                !Utils.isMobile &&
                <div className="drawer" style={{ minHeight: height }}>
                  {
                    displayDrawerData.map((item: any, i: number) =>
                      <DrawerRow data={item} key={i} selected={this.state.selected} setSelectedOption={this.setSelectedOption}></DrawerRow>)
                  }
                </div>
              }
              <div className="renderComponent" style={{ minHeight: height }}>
                <div>
                  <Route exact path="/" component={LandingPage} />
                  <Route exact path="/EOD" component={EOD} />
                  <Route exact path="/Labels" component={Labels} />
                  <Route exact path="/TechAudits" component={TechAudits} />
                  <Route exact path="/CreateLabel" component={CreateLabel} />
                  <Route exact path="/AddressBook" component={AddressBook} />
                  <Route exact path="/ReturnLabels" component={ReturnLabels} />
                  <Route exact path="/PrintConfigs" component={PrinterConfiguration} />
                  <Route exact path="/Export" component={Export} />
                </div>
              </div>
            </div>
        }
      </Router>
    );
  }

  renderError = () => {
    return (
      <div className='UserError'>
        <div className='title'><ErrorIcon style={{ fontSize: '60px' }} /> ERROR</div>
        <div className='subtitle'>Your profile is not configured to use Shipping Center.</div>
        <div className='subtitle'>Please contact the IT Help Desk (818) 735-8800 ext. 2660 or email <a href={"mailto:gwitickets@guitarcenter.com"}>gwitickets@guitarcenter.com</a></div>
      </div>
    );
  }
}

