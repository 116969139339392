import React from 'react';
import './Routes.scss';
import FilterBar from '../Subcomponent/Shared/FilterBar';


export default class AddressBook extends React.Component<any, {}> {

  constructor(props: any) {
    super(props);
  }


// RENDER METHODS

    render = () => {
      return (
        <div className='innerContianer'>
          <div className='primaryRow'>
              <div className='headerText'>
                Address Book
              </div>
          </div>
          <FilterBar></FilterBar>
        </div>
      );
    }
}