import React from 'react';
import './Dialogs.scss';
import { Button, Dialog, DialogContent, DialogContentText, DialogActions, Slide, DialogTitle, List, ListItem } from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions/transition';

import ErrorIcon from '@material-ui/icons/Error';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
  ) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function DialogBox(props: any): any {

    const isError = props.text.Title.toLowerCase() === 'error';
    const isInfo = props.text.Title.toLowerCase() === 'info';

    const renderMsgs = () =>{
        // For MultiBox Messages
        if(props.text.MultiBoxMsgs?.length>0)
        {
            let msgs = props.text.MultiBoxMsgs;
            return (
                <List>
                    {
                        msgs.map((value:any)=>{
                            return (<ListItem><span><b>Box {value.box}- </b>{value.msg}</span></ListItem>)
                        })
                    }
                </List>
            )   
        }
        else{
            return (<span>{props.text.Msg}</span>);
        }
    }
    
    return (
        <Dialog open={props.open} onClose={() => {props.handleClose()}} TransitionComponent={Transition}>
            <DialogTitle className={`itemDialogBorder${isError ? ' error': ' '}${isInfo ? ' info': ' '}`}>
                <div className={'dialogHeader'}>
                    {props.text.Title}
                    {isError && <ErrorIcon/>}
                    {isInfo && <HelpOutlineIcon/>}
                </div>
            </DialogTitle>
            <DialogContent className='itemDialogBorder'>
                {renderMsgs()}
            </DialogContent>
            <DialogActions>
                <Button size='small' onClick={() => {props.handleClose()}} variant="contained" 
                    style={{color:'white', background: isError ? '#D93A2F' : isInfo ? '#2f40d9' : 'grey'}}>
                    <span className='dialogButton'>Close</span>
                </Button>
            </DialogActions>
        </Dialog>
    );
}