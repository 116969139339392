import React from 'react';
import './Drawer.scss';
import { useHistory } from "react-router";

import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import { IconButton, Grid } from '@material-ui/core';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';

import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import RemoveCircleOutlineOutlinedIcon from '@material-ui/icons/RemoveCircleOutlineOutlined';

const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    // backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, .03)',
    },
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '10px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);
  
const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
}))(MuiAccordionDetails);

export default function DrawerRow(props: any): any {
  
    const history = useHistory();
    const panel = 'currentPanel';
    const [expanded, setExpanded] = React.useState<string | false>();
    // const [index, setIndex] = React.useState<number>(props.index);

    const handleClick = () => {
      props.setSelectedOption(props.data.Header);
      if (props.data.Data.length > 0) {
        setExpanded(expanded ? false : panel);
      } else {
        changeRoute(props.data.Route, null);
      }
    }

    const changeRoute = (route: string, id: string|null) => {
        if (id !== null) {
            history.push({pathname: route + ":" + id});
        } else {
            history.push({pathname: route});
        }
    }

    const isSelected = props.selected === props.data.Header;

    return (
      // <div className={'DrawerRow'}>
      //   <Accordion square expanded={expanded === panel}>
      //     <AccordionSummary>
      //       <div className={'DrawerRowHeader'} onClick={() => {handleClick()}}>
      //           {props.data.Header}
      //       </div>
      //     </AccordionSummary>
      //     <AccordionDetails>
      //         <div className="DrawerRowContent">
      //           {
      //               props.data.Data.map((title: any, i: number, array: Array<any>) => 
      //               <span onClick={() => {changeRoute(props.data.Route, title)}}>{title}</span>)
      //           }
      //         </div>
      //     </AccordionDetails>
      //   </Accordion>
      // </div>
      <div
        onClick={() => {handleClick()}}
        className={isSelected ? 'DrawerLine SelectedDrawerLine hvr-bounce-to-right' : 'DrawerLine hvr-bounce-to-right'}
      >
        {/* <span style={{padding: '0 0 0 4px'}}></span> */}
        {props.data.Header}
      </div>
    );
}