import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, MenuItem, Select, Slide, TextField, Typography } from "@material-ui/core";
import { TransitionProps } from "@material-ui/core/transitions";
import React, { useState } from "react";
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import Constants from "../../Common/Constants";
import PFManager from "../../Managers/Process.Fulfillment.Manager";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function Reasons(props: any): any {
    const [reasonCode, setReasonCode] = useState("");
    const [reasonText, setReasonText] = useState("");
    const changeReasonCode = (event: any) => {
        setReasonCode(event.target.value);
    }
    const changeReasonText = (event: any) => {
        setReasonText(event.target.value);
    }
    //const reasonCodes = JSON.parse(PFManager.configs.find((x: any) => x.key == Constants.Configs.PremiumReasons)?.value);
    const renderServices = () => {
        // For MultiBox Messages
        if (props.boxes?.length > 0) {
            let boxes = props.boxes;
            return (
                <ul>
                    {
                        boxes.map((box: any) => {
                            return (<li style={{ margin: "2px" }}><b>Dimensions: </b>{box.dimension}, <b>Weight: </b>{box.weight}{box.weightUnit}, <b>Service: </b>{box.serviceName}</li>)
                        })
                    }
                </ul>
            )
        }
    }
    const confirm = async () => {
        props.addReason(reasonText);
    }
    const retry = () => {
        setReasonCode("");
        setReasonText("");
    }

    const close = () => {
        retry();
        props.close();
    }

    const renderReasons = () => {
        return (
            <React.Fragment>
                <DialogContent>
                    <Grid item xs={12} sm={12} >
                        <span>You are about to use premium service for the following {props.boxes?.length == 1 ? 'box:' : 'boxes:'}</span>
                        {renderServices()}
                    </Grid>
                    <Divider />
                    {/* <Grid item xs={12} sm={12} className='inputField' style={{ marginTop: "10px" }}>
                        <Typography>
                            Reason Code*
                        </Typography>
                        <Select style={{ width: '100%' }} value={reasonCode} name='dimension' onChange={changeReasonCode} className='fieldSelect'>
                            <MenuItem value={''}>Select a Reason</MenuItem>
                            {
                                reasonCodes.map((data: any, i: number) => <MenuItem value={data.reasonId} key={i}>{data.reasonText}</MenuItem>)
                            }
                        </Select>
                    </Grid> */}
                    <Grid item xs={12} sm={12} className='inputField'>
                        <Typography>
                            Reason Text*
                        </Typography>
                        <TextField fullWidth multiline InputLabelProps={{ shrink: false }} id="reasonText" variant="outlined" value={reasonText}
                            onChange={(e) => changeReasonText(e)} placeholder="Reason Text" autoComplete="off" />
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button color='primary' disabled={reasonText == ""} variant="contained" onClick={() => { confirm() }} startIcon={<DoneIcon />}>
                        Confirm
                    </Button>
                    <Button key={1} style={{ whiteSpace: 'nowrap', backgroundColor: "grey", color: "white" }} onClick={() => { close() }} variant="contained" startIcon={<CloseIcon />}>
                        Close
                    </Button>
                </DialogActions>
            </React.Fragment>
        );
    }
    return (
        <Dialog open={props.open} TransitionComponent={Transition} fullWidth={true}>
            <DialogTitle className={`confirm`}>
                <div className={'dialogHeader'}>
                    Reason
                    {<HelpOutlineIcon />}
                </div>
            </DialogTitle>
            {
                renderReasons()
            }
        </Dialog>
    );
}