import Constants from "./Constants";
import moment from "moment";
import { StoreInfo } from "../Models/StoreInfo";
import Utils from "./Utils";
import IdentityManager from "../Managers/Identity.manager";

export default class AddressBookUtils {
    
    public static parseAddress = (data: any) => {
        let storeInfo = {
            DistrictID: 0,
            StoreID: 0,
            Address : data.ConsigneeAddr1,
            Address2 : data.ConsigneeAddr2,
            City : data.ConsigneeCity,
            State : data.ConsigneeState,
            Zip : data.ConsigneePostal,
            Phone : data.ConsigneePhone,
            Fax : "",
            StoreManager: 0,
            OpsManager: 0,
            gcpro: "",
            Latitude: "",
            Longitude: ""
        }
        let result = {
            storeInfo: storeInfo,
            name: data.ConsigneeContact,
            store: data.CreatedByStore
        }
        return result;
    }

    public static sortStore = (a: any, b: any) => {
        if (IdentityManager.storeNumber !== '') {
            if (a.store.includes(IdentityManager.storeNumber)) {
                return -1
            }
            if (b.store.includes(IdentityManager.storeNumber)) {
                return 1
            } else {
                return a.store > b.store ? 1 : -1;
            }
        } else {
            return 0;
        }
    }

    public static parseSPOData = (data: any) => {
        let storeInfo = {
            DistrictID: 0,
            StoreID: 0,
            Address : data[2],
            Address2 : data[3],
            City : data[4],
            State : data[5],
            Zip : data[6],
            Phone : data[7],
            Fax : "",
            StoreManager: 0,
            OpsManager: 0,
            gcpro: "",
            Latitude: "",
            Longitude: ""
        }
        let result = {
            storeInfo: storeInfo,
            name: `${data[0]} ${data[1]}`,
            store: '',
            email: data[8]
        }
        return result;
    }
}