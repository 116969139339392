import React from 'react';
import './EndOfDay.scss';
import moment from "moment";
import Loader from "react-loader-spinner";

import Utils from '../../Common/Utils';
import EODUtils from '../../Common/EOD.Utils';
import IdentityManager from '../../Managers/Identity.manager';
import PFManager from '../../Managers/Process.Fulfillment.Manager';

import Carrier from './Carrier';
import Service from './Service';
import EODReportTable from './EODReportTable';
import DatePicker from '../Shared/DatePicker';

import { Button, Paper, Select, MenuItem } from '@material-ui/core';
import SecurityIcon from '@material-ui/icons/Security';
import { FaUps, FaFedex, FaUsps } from "react-icons/fa";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import PresentToAllIcon from '@material-ui/icons/PresentToAll';
import Constants from '../../Common/Constants';
import StoreManager from '../../Managers/Store.manager';
import LabelUtils from '../../Common/Label.Utils';
import PFServicesManager from '../../Managers/Fulfillment.Services.Manager';


const html2pdf = require('html2pdf.js');

export default class EODReport extends React.Component<any, {}> {

  public state = {
    store: 'All',
    services: [],
    loader: true,
    reportData: [],
    selected: 'UPS',
    isClicked: false,
    carrierLoaded: false,
    to: Utils.currentDate,
    from: Utils.dateReducedByDay,
    carriers: [{ title: '', services: [] }],
    shipFromDetails: Constants.SHIP_DETAILS,
  }

  constructor(props: any) {
    super(props);

    this.changeState = this.changeState.bind(this);
    this.generateReport = this.generateReport.bind(this);
  }

  componentDidMount = async () => {
    await this.getCarrierData();
    let store = '';
    if (IdentityManager.isAdmin) {
      store = 'All';
    } else {
      store = IdentityManager.storeNumber !== ''
        ? IdentityManager.storeNumber
        : StoreManager.storeList.length > 0
          ? StoreManager.storeList[0].id
          : '101';
    }
    await this.setUserStore(store);
    this.setState({ store });
  }

  changeState = (data: any) => this.setState(data);

  setUserStore = async (storeNumber: string) => {
    let _result = Constants.SHIP_DETAILS;
    if (storeNumber !== '') {
      _result = await StoreManager.getStoreDetails(storeNumber);
      StoreManager.currentStore = _result;
      this.setState({ shipFromDetails: _result });
    }
  }

  getCarrierData = async () => {
    //;
    if (PFManager.carrierServiceData.length === 0) {
        //Use Shipping API or Lambda for PF Services
        if(PFManager.shippingAPI){
            await PFServicesManager.getActionServices();
        }
        else{
            await PFManager.getCarrierServices();
        }
    }

    let company = IdentityManager.getUserCompany();
    let isAdmin = IdentityManager.isAdmin;
    let carriers: any = [], carrier: string = '', services: any[] = [];
    let carrierServiceData = isAdmin ? PFManager.carrierServiceData : PFManager.carrierServiceData.filter((a: any) => a.Services.filter((b: any) => (b.Company.length == 0) || b.Company.includes(company)).length > 0);
    carrierServiceData.forEach((e: any) => {
      let _carrier = { title: '', services: [], icon: '' };
      _carrier.title = e.Title;
      _carrier.services = e.Services.map((r: any) => r.name);
      carriers.push(_carrier);
      if (carrier === '') {
        carrier = _carrier.title;
        services = _carrier.services;
      }
    });
    this.setState({ carriers, carrierLoaded: true, loader: false, selected: carrier, services });
  }

  selectCarrierIcon = (carrier: string) => {
    let _carrier = carrier.toLowerCase();
    switch (_carrier) {
      case 'ups':
        return <FaUps title="UPS" />;
      case 'usps':
        return <FaUsps title="USPS" />;
      case 'fedex':
        return <FaFedex title="FedEx" />;
      default:
        return <SecurityIcon />;
    }
  }

  handleDateChange_To = (date: Date) => {
    this.setState({ to: date });
    // if (Utils.currentDate.getTime() >= date.getTime()) {
    // }
  }

  handleDateChange_From = (date: Date) => {
    if (Utils.startDate.getTime() >= date.getTime()) {
      this.setState({ from: date });
    }
  }

  handleStoreChange = (e: any) => {
    console.log(e)
    this.setState({ store: e.target.value });
    this.setUserStore(e.target.value);
  }

  getReportTime = () => {
    let _toString: string = moment(this.state.to).format('YYYY-MM-DD') + 'T23:59:59.999Z';
    let _fromString: string = moment(this.state.to).format('YYYY-MM-DD') + 'T00:00:00.001Z';

    let _to = LabelUtils.localToGMTStingTime(moment(_toString).toDate());
    let _from = LabelUtils.localToGMTStingTime(moment(_fromString).toDate());

    return [_to, _from];
  }

  generateReport = async () => {
    this.setState({ loader: true });
    if (this.state.services.length === 0) {
      this.setState({ isClicked: true });
    }
    // let [_to, _from] = this.getReportTime();
    let _to = moment(this.state.to).format('YYYY-MM-DD');
    let store = this.state.store === 'All' ? '' : StoreManager.getSoreShipperPreFixByStoreId(this.state.store) + this.state.store;
    let result: any = await PFManager.getEODData(store, _to, this.state.selected);
    if (result.status !== 500 && result.status !== 400) {
      let data = EODUtils.parseData_EODReport(result.data, this.state.selected, this.state.services);
      if (data.length > 0) {
        this.setState({ loader: false, reportData: data });
      } else {
        this.setState({ loader: false });
        this.props.changeState({ dialogText: { Title: 'Info', Msg: 'No data found' }, openDialog: true });
      }
    }
  }

  exportDataPdf = () => {
    // this.setState({ showPdfLoader: true });
    let el = document.getElementById("EODReport") as any;
    let _to = moment(this.state.to).format('YYYY-MM-DD');
    let fileName = `EOD Report - ${_to} - ${this.state.selected}`;
    html2pdf(el, {
      pdfCallback: this.pdfDone,
      margin: [0.2, 0.2, 0.2, 0.2],  // tlbr
      filename: fileName + '.pdf',
      image: { type: 'jpeg', quality: 1 },
      html2canvas: { scale: 2, logging: true },
      jsPDF: { unit: 'in', format: 'a4', orientation: 'p' },
      pagebreak: { after: '.afterClass' }
    }).then((pdf: any) => {
      // this.setState({ showPdfLoader: false });
    });
  }

  pdfDone = (response: any) => console.log("PDF Generated")

  // RENDER METHODS

  render = () => {
    const height: string = (Utils.displayHeight * 0.98 - 101) + 'px';
    const margin: string = (0.5 * (Utils.displayHeight * 0.98 - 101)) + 'px';
    let data: any = this.state.carriers.find(r => r.title === this.state.selected);
    return (
      <div className="EODReportContainer">

        {
          this.state.loader &&
          <div style={{ marginTop: margin }}>
            <Loader type="TailSpin" color="#D93A2F" height={80} width={80} />
          </div>
        }

        {
          !this.state.loader && this.state.carrierLoaded && this.state.reportData.length === 0 &&
          <div className='selection'>
            {this.renderCarrier()}
            {data !== undefined && this.renderService(data)}
          </div>
        }

        {
          !this.state.loader && this.state.carrierLoaded && this.state.reportData.length === 0 &&
          <Button style={{ marginTop: '10px' }} onClick={this.generateReport} size="large" variant="contained" color="primary">
            <span style={{ fontSize: '20px' }}>Generate Report</span>
          </Button>
        }

        {
          this.state.reportData.length > 0 && this.renderEODReport()
        }
      </div>
    );
  }

  renderEODReport = () => {
    return (
      <div>
        <div style={{ display: 'flex', margin: '0 0 10px 0', justifyContent: 'center' }}>
          <Button style={{ fontSize: '20px', marginTop: '10px' }} onClick={() => { this.setState({ reportData: [] }) }} size='large' variant="contained" color="primary">
            <ChevronLeftIcon />
            <span>Back</span>
          </Button>
          <Button style={{ fontSize: '20px', marginTop: '10px', marginLeft: '10px' }} onClick={() => { this.exportDataPdf() }} size='large' variant="contained" color="primary">
            <PresentToAllIcon />
            <span style={{ marginLeft: '5px' }}>Export</span>
          </Button>
        </div>
        <EODReportTable
          to={this.state.to}
          store={this.state.store}
          rows={this.state.reportData}
          changeState={this.changeState}
          selected={this.state.selected}
          services={this.state.services}
          shipFromDetails={this.state.shipFromDetails}
          icon={this.selectCarrierIcon(this.state.selected)}
        />
      </div>
    )
  }

  renderService = (data: any) => {
    let admin = IdentityManager.isAdmin;
    let store = IdentityManager.storeNumber;
    let city = IdentityManager.getUserProperty(Constants.city);
    return (
      <div className='carriers w-adjust'>
        <div className='row'>
          {
            admin
              ?
              <Paper style={{ width: '370px', padding: '20px 10px', display: 'flex', margin: '0 0 10px 0', justifyContent: 'space-around' }}>
                <div className='title'>Store:</div>
                <Select
                  value={this.state.store}
                  style={{ width: '250px' }}
                  onChange={(e) => { this.handleStoreChange(e) }}
                >
                  <MenuItem value='All'>All Stores</MenuItem>
                  {
                    StoreManager.storeList.map((data: any, i: number, array: Array<any>) =>
                      <MenuItem value={data.id} key={i}>{data.id} - {data.name}</MenuItem>)
                  }
                </Select>
              </Paper>
              :
              <Paper style={{ width: '370px', padding: '20px 10px', display: 'flex', margin: '0 0 10px 0' }}>
                <div className='title'>Store:</div>
                <div>{city}</div>
              </Paper>
          }
        </div>
        <div className='row'>
          <Paper style={{ width: '370px', padding: '20px 10px', display: 'flex', justifyContent: 'space-around' }}>
            <div className='title'>Ship Date:</div>
            <div><DatePicker currentDate={this.state.to} setDate={this.handleDateChange_To} style={{ width: '250px' }} /></div>
          </Paper>
        </div>
        <Service
          data={data}
          services={this.state.services}
          changeState={this.changeState}
          isClicked={this.state.isClicked}
        />
      </div>
    );
  }

  renderCarrier = () => {
    return (
      <div className='carriers'>
        {
          this.state.carriers.map((data: any, i: number, array: Array<any>) =>
            <Carrier data={data} selected={this.state.selected} changeState={this.changeState} />)
        }
      </div>
    );
  }
}