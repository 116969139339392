import React from 'react';
import './EndOfDay.scss';

import { createStyles, makeStyles, useTheme, Theme } from '@material-ui/core/styles';
import { Input, InputLabel, MenuItem, FormControl, Select, Chip, FormHelperText, Paper} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            width: '100%',
            minWidth: 120,
            maxWidth: 330,
            // margin: theme.spacing(1),
        },
        chips: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        chip: {
            margin: 2,
        },
        noLabel: {
            marginTop: theme.spacing(3),
        },
    }),
);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function getStyles(name: string, personName: string[], theme: Theme) {
    return {
        fontWeight:
        personName.indexOf(name) === -1
            ? theme.typography.fontWeightRegular
            : theme.typography.fontWeightMedium,
    };
}

export default function Service(props: any): any {
    const classes = useStyles();
    const theme = useTheme();

    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => props.changeState({services: event.target.value as string[]})

    return (
        <div className='row'>
            <Paper className='rowPaper'>
                <div style={{textAlign: 'left', color: '#d93a2f', fontWeight: 500, fontSize: '18px'}}>Services</div>
                <FormControl className={classes.formControl} error={props.isClicked && props.services.length == 0}>
                    {/* <InputLabel id="demo-mutiple-chip-label" style={{fontSize: '15px !important'}}>Services</InputLabel> */}
                    <Select
                        multiple
                        color='primary'
                        value={props.services}
                        onChange={handleChange}
                        input={<Input id="select-multiple-chip" />}
                        renderValue={(selected) => (
                            <div className={classes.chips}>
                                {(selected as string[]).map((value) => (
                                    <Chip color='primary' key={value} label={`${props.data.title} - ${value}`} className={classes.chip} />
                                ))}
                            </div>
                        )}
                        MenuProps={MenuProps}
                    >
                        {
                            props.data.services.map((name: string) => (
                                <MenuItem key={name} value={name} style={getStyles(name, props.services, theme)}>
                                    {props.data.title} - {name}
                                </MenuItem>
                            ))
                        }
                    </Select>
                    {
                        props.isClicked && props.services.length == 0 &&
                        <FormHelperText>Please select at least one service</FormHelperText>
                    }
                </FormControl>
            </Paper>
        </div>
    );
}
