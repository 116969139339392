import React from 'react';
import './Dialogs.scss';
import IdentityManager from '../../Managers/Identity.manager';
import Loader from "react-loader-spinner";

import { Button, Dialog, DialogTitle, DialogContent, DialogActions, TableContainer, Table, Paper, TableRow, TableCell, TableBody, Slide } from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import PFManager from '../../Managers/Process.Fulfillment.Manager';
import Constants from '../../Common/Constants';
import WarningIcon from '@material-ui/icons/Warning';
import BeenhereIcon from '@material-ui/icons/Beenhere';
import ErrorIcon from '@material-ui/icons/Error';
import PFServicesManager from '../../Managers/Fulfillment.Services.Manager';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
  ) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function VoidLabelDialog(props: any): any {

  const [msg, setMSG] = React.useState<string>('');
  const [alert, setAlert] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);

  const closeDialogBox = () => {
    props.changeState({voidLabelDialog: false});
    setAlert(false);
    setMSG('');
  }
  
  const reloadData = () => {
    props.changeState({dataLoaded: false});
    setTimeout(() => {
      props.changeState({voidLabelDialog: false, dataLoaded: true});
      setAlert(false);
      setMSG('');
    }, 100);
  }

  const voidLabel = async () => {
    setLoading(true);
    // let result: any = {"method":"Send Process Fulfillment Request","details":{"message":"socket hang up","name":"Error","stack":"Error: socket hang up\n    at createHangUpError (_http_client.js:322:15)\n    at TLSSocket.socketOnEnd (_http_client.js:425:23)\n    at TLSSocket.emit (events.js:187:15)\n    at TLSSocket.EventEmitter.emit (domain.js:442:20)\n    at endReadableNT (_stream_readable.js:1094:12)\n    at process._tickCallback (internal/process/next_tick.js:63:19)","config":{"url":"https://oz9wlboq0m.execute-api.us-west-2.amazonaws.com/Dev/ProcessFullFillment","method":"post","data":"{\"ACTION\":\"VOID_SHIPMENT\",\"PAYLOAD\":{\"APP_ID\":\"Shipping Center\",\"EMPLOYEE_ID\":\"986732\",\"SHIPPER\":\"G220\",\"SUID\":\"1C214697A34D42E5823E1D273813467E\"}}","headers":{"Accept":"application/json, text/plain, */*","Content-Type":"application/json","x-api-key":"4aCaXj3rpw20LJDjjjuip7FJs1gjrVI08PqQBoN9","User-Agent":"axios/0.21.1","Content-Length":146},"transformRequest":[null],"transformResponse":[null],"timeout":0,"xsrfCookieName":"XSRF-TOKEN","xsrfHeaderName":"X-XSRF-TOKEN","maxContentLength":-1,"maxBodyLength":-1},"code":"ECONNRESET"}}; 
    let result: any = null;
    
    //Use Shipping API or Lambda for PF Services
    if(PFManager.shippingAPI){
        let payload = {
          shipper: props?.selectedLabel?.Store,
          SUID : props?.selectedLabel?.ServiceResponse.SUID
        };
        result = await PFServicesManager.voidShipment(payload);
		}
		else{
        result = await PFManager.voidShipment(props.selectedLabel.ServiceResponse.SUID, props.selectedLabel.Store);
		}
    
    setLoading(false);
    // error case
    if (result.method !== undefined) {
      setAlert(true);
      setMSG(result.details.message);
    } else {
      if (result.response.msg.includes('already') || !result.response.success) { setAlert(true) }
      if (result.response.msg === '') {result.response.msg = `SUID: ${props.selectedLabel.ServiceResponse.SUID}`}
      setMSG(result.response.msg);
    }
  }

  return (
    <Dialog open={props.open} onClose={() => {closeDialogBox()}} TransitionComponent={Transition}>
      {
          loading && 
          <div className='voidDiv'>
            <div className='voidLoader'>
              <Loader type="TailSpin" color="#D93A2F" height={80} width={80} />
            </div>
          </div>
      }

      {
        !loading &&
        <DialogTitle className="itemDialogBorder" style={{background: msg === '' ? '#ffbb33' : alert ? '#D93A2F' : 'green'}}>
          <div className="dialogHeader" style={{color: msg === '' ? 'black' : 'white'}}>
            <div style={{margin: '0', fontWeight: 600}}>
              { msg === '' ? 'WARNING' : alert ? 'Error' : 'Success' }
            </div>
            { msg === '' ? <WarningIcon /> : alert ? <ErrorIcon /> : <BeenhereIcon /> }
          </div>
        </DialogTitle>
      }
      
      {
        !loading &&
        <DialogContent className="itemDialogBorder dialogWidth">
          {
            msg === ''
            ?
            <div className='voidLabel'>
              
              <span>You are about to void this label.</span> <br/>
              <span>This label will be deleted.</span> <br/>
            </div>
            :
            <div className='voidLabel' style={{fontSize: '20px', color: alert ? 'red' : 'inherit'}}>
            {
              alert
                ? Constants.MESSAGES.VOID_LABEL_FAILURE
                : Constants.MESSAGES.VOID_LABEL_SUCCESS
            }
            </div>
          }
          
          {
            msg !== '' &&
            <div className='voidLabel' style={{margin: '5px 0 0 0',fontSize: '16px', color: msg === '' ? 'inherit' : alert ? 'red' : 'inherit'}}>
              {msg}
            </div>
          }
        </DialogContent>
      }

      {
        !loading &&
        <DialogActions>
          {
            msg === '' &&
            <Button size="small" onClick={() => {voidLabel()}} variant="contained" style={{marginLeft:'10px'}}>
              <span className="dialogButton">Yes</span>
            </Button>
          }
          {
            msg === '' &&
            <Button size="small" onClick={() => {closeDialogBox()}} style={{color:'black', background:'#ffbb33'}} variant="contained">
              <span className="dialogButton">No</span>
            </Button>
          }
          {
            msg !== ''
            ? alert
              ?
                <Button size="small" onClick={() => {reloadData()}} color="primary" variant="contained">
                  <span className="dialogButton">Close</span>
                </Button>
              :
                <Button size="small" onClick={() => {reloadData()}} style={{color:'white', background:'green'}} variant="contained">
                  <span className="dialogButton">Close</span>
                </Button>
            : null
          }
        </DialogActions>
      }
    </Dialog>
  );
}