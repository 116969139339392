import Constants from "./Constants";
import moment from "moment";
import { StoreInfo } from "../Models/StoreInfo";
import Utils from "./Utils";
import StoreManager from "../Managers/Store.manager";
import IdentityManager from "../Managers/Identity.manager";
import PFManager from "../Managers/Process.Fulfillment.Manager";

export default class LabelUtils {

    public static get currentDate(): Date {
        return moment().toDate();
    }

    public static getStartEnd = (pagination: any) => {
        let size = pagination.size,
            from = (pagination.page - 1) * pagination.size;
        return [size, from]
    }

    public static getFieldWidth = (): string => {
        let width: string = '0px';
        let elemArrray = document.getElementsByClassName('fieldInputFull');
        if (elemArrray.length > 0) {
            width = getComputedStyle(elemArrray[0]).width;
        }
        return width;
    }

    public static getElementWidth = (id: string) => {
        let width: string = '0px';
        let elem = document.getElementById(id);
        if (elem !== null) {
            width = getComputedStyle(elem).width;
        }
        return width;
    }

    public static ServiceInfo_ChangeText = (e: any) => {
        let _obj: any = {};
        if (e.target.id === 'weight' && e.target.value !== '') {
            if (LabelUtils.validateNumber(e.target.value) && e.target.value <= 150) {
                _obj[e.target.id] = e.target.value;
            }
        } else {
            _obj[e.target.id] = e.target.value;
        }
        return _obj;
    }

    public static ServiceInfo_FieldValidate = (nextAttempt: boolean, props: any, property: string) => {
        let result = false;
        if (nextAttempt) {
            if (property === 'reason') {
                result = props[property] === '0'
            }
            else if (property === 'dimension') {
                result = LabelUtils.validateExpression(props[property]);
            }
            else {
                result = props[property] === ''
            }
        }
        return result;
    }

    public static ShipInfo_Validate = (data: any, property: string) => {
        if (property === 'Fax' || property === 'Phone') {
            return !LabelUtils.validatePhoneEntry(data[property])
        } else if (property === 'Zip') {
            return !LabelUtils.validateZipCode(data[property])
        } else if (property === 'store_email' || property === 'customer_email') {
            return data[property] === '' || !data[property].includes('@')
        } else {
            return data[property] === ''
        }
    }

    public static validateExpression = (data: any) => {
        if (data !== '0') {
            // let value = data.replaceAll('x','*').replaceAll('X','*')
            let vals = data.toLowerCase().split('x');
            let result = true;
            if (vals.length === 3) {
                vals.forEach((r: any) => { if (result) { result = !isNaN(r) } });
            } else {
                result = false;
            }
            return !result
            // return value === '0' || /(?:(?:^|[-+_*/])(?:\s*-?\d+(\.\d+)?(?:[eE][+-]?\d+)?\s*))+$/.test(value)
        } else {
            return true
        }
    }

    public static validateNumber = (data: string) => parseFloat(data) >= 0

    public static validateZipCode = (data: string) => data.length >= 5 && /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(data)

    public static validatePhoneEntry = (data: string) => data.length <= 10 && /[0-9]/.test(data)

    public static validatePhone = (data: string) => data !== '' && data.length <= 10 && /^(()?\d{3}())?(-|\s)?\d{3}(-|\s)?\d{4}$/.test(data)

    public static validateStep1 = (
        data: { shipToDetails: StoreInfo, shipFromDetails: StoreInfo, customer: string, currentStore: any, selectedStore: any },
        step: number,
        type: string
    ) => {
        if
            (
            data.shipToDetails.Address !== '' &&
            data.shipToDetails.City !== '' &&
            data.shipToDetails.State !== '' &&
            data.shipToDetails.Zip !== '' &&
            LabelUtils.validateZipCode(data.shipToDetails.Zip) &&
            // LabelUtils.validatePhone(data.shipToDetails.Phone) &&
            // LabelUtils.validatePhone(data.shipToDetails.Fax) &&
            data.shipFromDetails.Address !== '' &&
            data.shipFromDetails.City !== '' &&
            data.shipFromDetails.State !== '' &&
            data.shipFromDetails.Zip !== '' &&
            LabelUtils.validateZipCode(data.shipFromDetails.Zip) &&
            // LabelUtils.validatePhone(data.shipFromDetails.Phone)
            // LabelUtils.validatePhone(data.shipFromDetails.Fax)
            data.currentStore.id !== data.selectedStore.id //&&
            //data.currentStore.name !== data.selectedStore.name
        ) {
            if (type === 'Customer/Vendor') {
                return data.customer !== '' && LabelUtils.validatePhone(data.shipToDetails.Phone);
            } else {
                return true;
            }
        } else {
            return step === 2 ? false : true;
        }
    }

    public static validateStep2 = (data: any, step: number) => {
        if (data.shipperRef !== '' && data.reason !== '') {
            if (data.multiBox) {
                return data.packages.length > 0
            } else {
                return !LabelUtils.validateExpression(data.dimension) && data.weight !== ''
            }
        } else {
            return step === 3 ? false : true;
        }
    }

    public static getFullData = (data: any) => {
        let keys = Object.keys(data);
        for (let i = 0; i < keys.length; i++) {
            let currentData = data[keys[i]];
            data[keys[i]] = currentData !== undefined && currentData !== null ? currentData : keys[i].includes('send') ? false : '';
        }
        return data;
    }

    public static parseData_LabelTable = (data: any) => {
        if (data === null || data === undefined) {
            return []
        }

        let interim: any[] = data.filter((r: any) => r.LogType === Constants.LOG_TYPES.CREATE_LABEL)
        interim.forEach((r: any) => {
            r.isVoided = r.Status === 'Active' ? false : true;
            r.isProcessing = false;
        })
        return interim;
        // return interim
        // .sort((a: any,b: any) => {
        //     var ad = moment(a.LogTime).toDate();
        //     var bd = moment(b.LogTime).toDate();
        //     return ad.getTime() > bd.getTime() ? -1 : 1
        // })
        // .sort((a: any,b: any) => {
        //     var ad = moment(a.LogTime).toDate();
        //     var bd = moment(b.LogTime).toDate();
        //     return ad.getTime() > bd.getTime() ? -1 : 1
        // });
    }


    public static parseData_ReturnLabelTable = (data: any) => {
        if (data === null || data === undefined) {
            return []
        }

        let interim: any[] = data.filter((r: any) => r.LogType === Constants.LOG_TYPES.RETURN_LABEL)
        interim.forEach((r: any) => r.isVoided = r.Status === 'Active' ? false : true)

        return interim
            .sort((a: any, b: any) => {
                var ad = moment(a.LogTime).toDate();
                var bd = moment(b.LogTime).toDate();
                return ad.getTime() > bd.getTime() ? -1 : 1
            })
            .sort((a: any, b: any) => {
                var ad = moment(a.LogTime).toDate();
                var bd = moment(b.LogTime).toDate();
                return ad.getTime() > bd.getTime() ? -1 : 1
            });
    }

    public static rowSelect_resetAll = (_labelRows: any[]) => {
        for (let i = 0; i < _labelRows.length; i++) { _labelRows[i].isSelected = false; }
        let bulkLabels = _labelRows.filter((r: any) => r.isSelected);
        return [false, bulkLabels];
    }

    public static rowSelect_all = (selectState: boolean, _labelRows: any[]) => {
        for (let i = 0; i < _labelRows.length; i++) { if (!_labelRows[i].isVoided) { _labelRows[i].isSelected = !selectState } }
        let bulkLabels = _labelRows.filter((r: any) => r.isSelected);
        return [!selectState, bulkLabels];
    }

    public static rowSelect_single = (index: number, _labelRows: any[]) => {
        _labelRows[index].isSelected = !_labelRows[index].isSelected;
        const allSelected = _labelRows.filter((r: any) => r.isSelected).length === _labelRows.length;
        let bulkLabels = _labelRows.filter((r: any) => r.isSelected);
        return [allSelected, bulkLabels];
    }

    public static getFilterData_Aggregates = (data: any, state: any) => {
        if (data !== undefined) {
            let appList = { list: data.ApplicationId.buckets, selected: [] };
            if (state.appFilterData.selected.length > 0) {
                appList = state.appFilterData
                appList.list.forEach((app: any) => {
                    let current = data.ApplicationId.buckets.find((r: any) => r.key === app.key)
                    app.doc_count = current !== null && current !== undefined ? current.doc_count : 0;
                });
            }

            let carriers = data.Carrier.buckets;
            if (state.selectedFilterData.carrier !== '') {
                carriers = state.carrierFilterData
                carriers.forEach((carrier: any) => {
                    let current = data.Carrier.buckets.find((r: any) => r.key === carrier.key)
                    carrier.doc_count = current !== null && current !== undefined ? current.doc_count : 0;
                });
            }

            for (let i = 0; i < carriers.length; i++) {
                if (state.selectedFilterData.services.length > 0) {
                    carriers[i].services.forEach((service: any) => {
                        let current = data.Service.buckets.find((r: any) => r.key === service.key)
                        service.doc_count = current !== null && current !== undefined ? current.doc_count : 0;
                    });
                } else {
                    carriers[i].services = data.Service.buckets;
                }
            }

            let count = data.Status.buckets.map((r: any) => r.doc_count).reduce((a: any, b: any, c: any) => { a = a + b; return a; }, 0)
            let statusList = [{ key: "All", doc_count: count }];
            statusList.push(...data.Status.buckets);
            if (state.status !== "All") {
                statusList = state.statusFilters;
                statusList.forEach((status: any) => {
                    let current = data.Status.buckets.find((r: any) => r.key === status.key)
                    status.doc_count = current !== null && current !== undefined ? current.doc_count : 0;
                });
            }

            return [carriers, appList, statusList];
        } else {
            return [[], {}, []]
        }
    }

    public static getFilterData = (labelRows: any[]) => {
        let carriers = labelRows.map((r: any) => r.Carrier).filter(Utils.onlyUnique)
        let carrierFilterData: any[] = [];
        carriers.forEach((carrier: string) => {
            let services = labelRows
                .filter((r: any) => r.Carrier === carrier)
                .map((r: any) => r.Service)
                .filter(Utils.onlyUnique);
            carrierFilterData.push({ carrier: carrier, services: services });
        });
        console.log(carrierFilterData)

        let appList = labelRows.map((r: any) => r.ApplicationId).filter(Utils.onlyUnique)
        return [carrierFilterData, { list: appList, selected: [] }];
    }

    public static filterLabelData = (status: string, rows: any[], carrierData: any, appData: any[]) => {
        return rows
            .filter((r: any) => status === 'All' ? true : status === 'Voided' ? r.isVoided : !r.isVoided)
            .filter((r: any) => appData.length > 0 ? appData.indexOf(r.ApplicationId) >= 0 : true)
            .filter((r: any) => carrierData.carrier !== '' ? r.Carrier === carrierData.carrier : true)
            .filter((r: any) => carrierData.services.length > 0 ? carrierData.services.indexOf(r.Service) >= 0 : true)
    }

    public static getSuccessData = (data: any) => {
        if (data === null) {
            return data;
        }
        let result: any = null;
        try {
            //For Multi Box
            if (Array.isArray(data)) {
                let multiBoxResult: any = [];
                data.forEach((value: any) => {
                    let trackingNo = [];
                    if (value.response.data.PACKAGES.PACKAGE.length !== undefined) {
                        value.response.data.PACKAGES.PACKAGE.forEach((r: any) => {
                            trackingNo.push(r.CARRIER_PACKAGE_REFERENCE);
                        });
                    } else {
                        trackingNo.push(value.response.data.PACKAGES.PACKAGE.CARRIER_PACKAGE_REFERENCE);
                    }
                    let _obj = {
                        BOX: value.payLoad.BOX_NO,
                        SUID: value.response.data.SUID,
                        TOTAL: value.response.data.TOTAL,
                        TrackingNo: trackingNo
                    }
                    multiBoxResult.push(_obj);
                });
                result = multiBoxResult;
            }
            else {
                let trackingNo = [];
                if (data.response.data.PACKAGES.PACKAGE.length !== undefined) {
                    data.response.data.PACKAGES.PACKAGE.forEach((r: any) => {
                        trackingNo.push(r.CARRIER_PACKAGE_REFERENCE);
                    });
                } else {
                    trackingNo.push(data.response.data.PACKAGES.PACKAGE.CARRIER_PACKAGE_REFERENCE);
                }

                result = {
                    SUID: data.response.data.SUID,
                    TOTAL: data.response.data.TOTAL,
                    TrackingNo: trackingNo
                }
            }
        } catch (error) {
            console.log(error)
            result = { SUID: '', TrackingNo: [], TOTAL: '' }
        }
        console.log(result);
        return result;
    }

    public static get CreateLabel_State() {
        return {
            weight: '',
            isDC: false,
            isSPO: false,
            carrier: '0',
            service: '0',
            serviceChanged: false,
            recommendedService: false,
            packages: [],
            customer: '',
            formType: '',
            activeStep: 0,
            dimension: '0',
            reason: '0',
            loading: true,
            shipperRef: '',
            formMessage: '',
            consigneeRef: '',
            currentStore: { id: '', name: '' },
            successData: null,
            selectedStore: { id: '', name: '' },
            isResidential: false,
            formSubmitted: false,
            flags: {
                PROOF_FLAG: false,
                IS_RESIDENTIAL: false,
                SHIPMENTHOLD_FLAG: false,
                PROOF_SIGNATURE_FLAG: false,
                SATURDAYDELIVERY_FLAG: false,
            },
            returnLabelData: { customer_email: '', store_email: '', sendMail_customer: false, sendMail_store: false },
            shipDate: Utils.currentDate,
            shipToDetails: Constants.SHIP_DETAILS,
            shipFromDetails: Constants.SHIP_DETAILS,
        }
    }

    public static localToGMTStingTime(localTime: Date) {
        return new Date(localTime.getTime() + (localTime.getTimezoneOffset() * 60000)).toISOString();
    };


    public static getDateForLabelSearch = (dateFilter: string, to: Date, from: Date) => {
        if (dateFilter === 'Today') {
            let toString: string = moment(Utils.currentDate).format('YYYY-MM-DD') + 'T23:59:59.999Z';
            let fromString: string = moment(Utils.currentDate).format('YYYY-MM-DD') + 'T00:00:00.001Z';

            let _to = LabelUtils.localToGMTStingTime(moment(toString).toDate());
            let _from = LabelUtils.localToGMTStingTime(moment(fromString).toDate());

            return [_to, _from];
        }

        else if (dateFilter === 'Yesterday') {
            let toString: string = moment(Utils.dateReducedByDay).format('YYYY-MM-DD') + 'T23:59:59.999Z';
            let fromString: string = moment(Utils.dateReducedByDay).format('YYYY-MM-DD') + 'T00:00:00.001Z';

            let _to = LabelUtils.localToGMTStingTime(moment(toString).toDate());
            let _from = LabelUtils.localToGMTStingTime(moment(fromString).toDate());

            return [_to, _from];
        }

        else if (dateFilter === 'Last 30 Days' || dateFilter.toLowerCase() === '30 days') {
            let toString: string = moment(Utils.currentDate).format('YYYY-MM-DD') + 'T23:59:59.999Z';
            let fromString: string = moment(Utils.currentDate).subtract(30, "days").format('YYYY-MM-DD') + 'T00:00:00.001Z';

            let _to = LabelUtils.localToGMTStingTime(moment(toString).toDate());
            let _from = LabelUtils.localToGMTStingTime(moment(fromString).toDate());

            return [_to, _from];
        }

        else if (dateFilter.toLowerCase() === '90 days') {
            let toString: string = moment(Utils.currentDate).format('YYYY-MM-DD') + 'T23:59:59.999Z';
            let fromString: string = moment(Utils.currentDate).subtract(90, "days").format('YYYY-MM-DD') + 'T00:00:00.001Z';

            let _to = LabelUtils.localToGMTStingTime(moment(toString).toDate());
            let _from = LabelUtils.localToGMTStingTime(moment(fromString).toDate());

            return [_to, _from];
        }
        else if (dateFilter === 'Last 7 Days') {
            let toString: string = moment(Utils.currentDate).format('YYYY-MM-DD') + 'T23:59:59.999Z';
            let fromString: string = moment(Utils.currentDate).subtract(7, "days").format('YYYY-MM-DD') + 'T00:00:00.001Z';

            let _to = LabelUtils.localToGMTStingTime(moment(toString).toDate());
            let _from = LabelUtils.localToGMTStingTime(moment(fromString).toDate());

            return [_to, _from];
        }

        else if (dateFilter === 'Custom') {
            let toString: string = moment(to).format('YYYY-MM-DD') + 'T23:59:59.999Z';
            let fromString: string = moment(from).format('YYYY-MM-DD') + 'T00:00:00.001Z';

            let _to = LabelUtils.localToGMTStingTime(moment(toString).toDate());
            let _from = LabelUtils.localToGMTStingTime(moment(fromString).toDate());

            return [_to, _from];
        }

        else {
            return ['', ''];
        }
    }

    public static daysDifference = (toDate: any, fromDate: any) => {
        const dateFrom = moment(fromDate);
        const dateTo = moment(toDate);
        const daysDifference = dateTo.diff(dateFrom, 'days');
        return daysDifference;
    }

    public static splitDimenstion = (dimension: string, split: any) => {
        let length = 0, width = 0, height = 0;

        if (dimension !== '0' && dimension.toLowerCase().includes('x')) {
            let dimensionArray = dimension.toLowerCase().split('x');
            length = parseFloat(dimensionArray[0]);
            width = parseFloat(dimensionArray[1]);
            height = parseFloat(dimensionArray[2]);
        } else {
            length = split.length;
            width = split.width;
            height = split.height;
        }

        return [length, width, height];
    }

    public static labelSearch = (row: any, word: string) => {
        if (word === '') {
            return true;
        }

        let result = false;
        try {
            word = word.toLowerCase();

            let weightList: any[] = row.PayLoad.PACKAGES.map((r: any) => r.WEIGHT);
            let trackingNumbers: string[] = LabelUtils.getProperty.trackingNo(row.ServiceResponse);
            if (
                row.ApplicationId.toLowerCase().includes(word) || row.Store.toLowerCase().includes(word) ||
                row.Status.toLowerCase().includes(word) || weightList.indexOf(word) > -1 ||
                row.ServiceResponse.SHIPPER_SHIPMENT_REFERENCE.toLowerCase().includes(word) ||
                row.ServiceResponse.TOTAL.toLowerCase().includes(word) || trackingNumbers.indexOf(word) > -1 ||
                row.Carrier.toLowerCase().includes(word) || row.Service.toLowerCase().includes(word) ||
                row.Date.toLowerCase().includes(word) || row.ShipDate.toLowerCase().includes(word)
            ) {
                result = true
            } else {
                result = false;
            }
        } catch (error) {
            console.log(error)
        }
        return result;
    }


    public static returnLabelSearch = (row: any, word: string) => {
        if (word === '') {
            return true;
        }

        let result = false;
        try {
            word = word.toLowerCase();

            let weightList: any[] = row.PayLoad.PACKAGES.map((r: any) => r.WEIGHT);
            let trackingNumbers: string[] = LabelUtils.getProperty.trackingNo(row.ServiceResponse);
            if (
                row.Status.toLowerCase().includes(word) || weightList.indexOf(word) > -1 ||
                row.ServiceResponse.SHIPPER_SHIPMENT_REFERENCE.toLowerCase().includes(word) ||
                row.Date.toLowerCase().includes(word) || row.ShipDate.toLowerCase().includes(word) ||
                row.ApplicationId.toLowerCase().includes(word) || row.Store.toLowerCase().includes(word) ||
                row.ServiceResponse.TOTAL.toLowerCase().includes(word) || trackingNumbers.indexOf(word) > -1 ||
                row.Carrier.toLowerCase().includes(word) || row.PayLoad.RETURN_CONTACT.toLowerCase().includes(word)
            ) {
                result = true
            } else {
                result = false;
            }
        } catch (error) {
            console.log(error)
        }
        return result;
    }

    public static getLabelParams = (dateFilter: string, to: any, from: any, data: any, sortParams?: any) => {
        let [date_To, date_From] = LabelUtils.getDateForLabelSearch(dateFilter, to, from)
        let [psize, pfrom] = LabelUtils.getStartEnd(data.pagination);
        let storeNumber = IdentityManager.isAdmin ? '' : StoreManager.getSoreShipperPreFixByStoreId(IdentityManager.storeNumber) + IdentityManager.storeNumber;
        let app = data.appFilterData.selected[0];
        let carrier = data.selectedFilterData.carrier;
        let status = data.status === "All" ? "" : data.status;
        let sortField = sortParams ? sortParams.sortField ?? '' : '';
        let sortOrder = sortParams ? sortParams.sortOrder ?? '' : '';

        let service = "", serviceName = data.selectedFilterData.services[0];
        if (serviceName !== "" && serviceName !== undefined && serviceName !== null) {
            let carrierData = PFManager.carrierServiceData.find((c: any) => c.Title === carrier);
            if (carrierData !== undefined && carrierData !== null) {
                let serviceData = carrierData.Services.find((s: any) => s.name.trim() === serviceName.trim())
                if (serviceData !== undefined && serviceData !== null) {
                    service = serviceData.service;
                }
            }
        }

        return {
            app: app,
            status: status,
            carrier: carrier,
            service: service,
            storeNumber: storeNumber,
            sortField: sortField,
            sortOrder: sortOrder,
            date: { To: date_To, From: date_From },
            pagination: { psize: psize, pfrom: pfrom }
        }
    }

    public static getExportExcelParams = (params: any, searchWord: any, guid: any) => {
        let [date_To, date_From] = LabelUtils.getDateForLabelSearch(params.dateFilter, params.to, params.from);
        let storeNumber = IdentityManager.isAdmin ? '' : StoreManager.getSoreShipperPreFixByStoreId(IdentityManager.storeNumber) + IdentityManager.storeNumber;
        let app = params.appFilterData.selected[0];
        let carrier = params.selectedFilterData.carrier;
        let status = params.status === "All" ? "" : params.status;

        let service = "", serviceName = params.selectedFilterData.services[0];
        if (serviceName !== "" && serviceName !== undefined && serviceName !== null) {
            let carrierData = PFManager.carrierServiceData.find((c: any) => c.Title === carrier);
            if (carrierData !== undefined && carrierData !== null) {
                let serviceData = carrierData.Services.find((s: any) => s.name.trim() === serviceName.trim())
                if (serviceData !== undefined && serviceData !== null) {
                    service = serviceData.service;
                }
            }
        }

        return {
            to: date_To,
            from: date_From,
            store: storeNumber,
            status: status,
            app: app,
            carrier: carrier,
            service: service,
            word: searchWord,
            guid: guid
        }
    }

    public static getProperty = {
        numeric: (packages: any[], property: string) => {
            let result = 0;
            packages.forEach((r: any) => result = result + parseFloat(r[property]));
            return result;
        },

        address: (payload: any) => {
            let contact = payload.CONSIGNEE_CONTACT !== '' ? payload.CONSIGNEE_CONTACT : payload.CONSIGNEE_COMPANY
            return [
                contact,
                `${payload.CONSIGNEE_ADDRESS1}, ${payload.CONSIGNEE_ADDRESS2}`,
                `${payload.CONSIGNEE_CITY}, ${payload.CONSIGNEE_STATE} - ${payload.CONSIGNEE_POSTALCODE}`,
                `${payload.CONSIGNEE_COUNTRYID}`
            ];
        },

        trackingNo: (data: any) => {
            let trackingNumbers: string[] = []
            try {
                if (data.PACKAGES !== undefined && data.PACKAGES.length > 0) {
                    data.PACKAGES.forEach((r: any) => trackingNumbers.push(r.CARRIER_PACKAGE_REFERENCE));
                } else {
                    trackingNumbers.push(data.CARRIER_PACKAGE_REFERENCE)
                }
            } catch (error) {
                console.log(error);
            }
            return trackingNumbers;
        }
    }
}