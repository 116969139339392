import Constants from "../Common/Constants";
import IdentityManager from "./Identity.manager";
import jwt from 'jwt-decode';
import AuditLogsManager from "./Audit.Logs.Manager";
// import CryptoUtils from "../Common/Crypto.Utils";

export default class AuthManager {

  public static userData: any | null = null;
  public static redirectURL: string = window.location.origin;

  static getSimple = async (endpoint: string): Promise<any> => {
    let token = IdentityManager.AccessToken;
    const request = await fetch(endpoint, { headers: { 'Authorization': `Bearer ${token}` } });
    if (request.status !== 500 && request.status != 400 && request.status != 404) {
      const response = await request.json();
      return response;
    }
    else {
      console.log("Error=" + endpoint)
    }
  };
  public static logout = async () => {
    //
    let token = IdentityManager.AccessToken;
    let _result = await AuditLogsManager.createLog(Constants.LOG_TYPES.LOGOUT, null, null);
    window.localStorage.removeItem(Constants.USER_PROFILE);
    window.localStorage.removeItem(Constants.createLogin);
    this.getSimple(`/okta/signOut?&idtoken=${token}`).then((_data: any) => {
      let url: string = _data.response;
      AuthManager.reDirectOkta(url);
    });
  }
  public static reDirectOkta = (_url: any) => {
    //
    window.location.replace(_url);
  }
  public static auth = async () => {
    //
    let response = await fetch(`/auth`);
    let data = await response.json();
    return data;
  }

  // public static signin = () => {
  //     let _url = `https://login.microsoftonline.com/${Constants.AUTH.tenatId}/oauth2/v2.0/authorize?response_type=id_token+token&client_id=${Constants.AUTH.clientId}&redirect_uri=${AuthManager.redirectURL}&scope=${Constants.AUTH.scope}&state=${Constants.AUTH.state}&nonce=12345&sso_reload=true`;
  //     window.location.replace(_url);
  // }

  public static getToken = () => {
    //
    let accessTokenString = window.location.href.split('#')[1].split("&").find(r => r.includes("access_token"));
    if (accessTokenString !== undefined) {
      let accessToken: string = accessTokenString.split("=")[1]
      let _accessTokenData: any = jwt(accessToken);
      localStorage.setItem(Constants.USER_PROFILE, JSON.stringify(_accessTokenData));
      window.location.replace(AuthManager.redirectURL);
    }
  }


  public static signin = () => {
    //
    let authUrl = new URL(window.location.href.replace(/#/g, "?"));
    let idToken = authUrl.searchParams.get("id_token");
    if (idToken && idToken.length > 0) {
      AuthManager.handleAuthCode(idToken);
    }
    else {
      this.getSimple(`/okta/signin?&rdr=${AuthManager.redirectURL}`).then((data: any) => {
        let url: string = data.response;
        AuthManager.reDirectOkta(url);
      });
    }
  }

  public static handleAuthCode = async (_idtoken: string) => {
    //
    this.getSimple(`/okta/getTokenProfile?&idtoken=${_idtoken}`).then((_data: any) => {

      let userData = _data.response;
      IdentityManager.userData = userData;
      if (userData !== null && userData !== undefined) {
        localStorage.setItem(Constants.USER_PROFILE, JSON.stringify(userData));
      }

      if (localStorage.getItem(Constants.createLogin) === undefined || localStorage.getItem(Constants.createLogin) === null) {
        AuditLogsManager.createLog(Constants.LOG_TYPES.LOGIN, null, null);
        localStorage.setItem(Constants.createLogin, 'true');
      }

      let _url = window.location.origin;
      window.location.replace(_url);
    });
    //---------------

    // let _url = url;
    // if (window.location.href.includes("?code=")) {
    //   let query = window.location.href.split("?")[1];
    //   let _response = await fetch(`/auth/redirect?rdr=${AuthManager.redirectURL}&` + query);
    //   let _data = await _response.json();
    //   let userData = _data.response;//DataUtils.parseUserData(_data.response);
    //   IdentityManager.userData = userData;
    //   // let userDataEncrypt = CryptoUtils.encryptData(userData);
    //   // console.log(userDataEncrypt)
    //   // let userDataDecrypt = CryptoUtils.decryptData(userDataEncrypt);
    //   // console.log(userDataDecrypt)
    //   if (userData !== null && userData !== undefined) {
    //     localStorage.setItem(Constants.USER_PROFILE, JSON.stringify(userData));
    //   }

    //   if (localStorage.getItem('createLogin') === undefined || localStorage.getItem('createLogin') === null) {
    //     let _result = await AuditLogsManager.createLog(Constants.LOG_TYPES.LOGIN, null, null);
    //     localStorage.setItem('createLogin', 'true');
    //   }

    //   _url = window.location.origin;
    // }
    // window.location.replace(_url);
  }

  // get refresh token and update user profile
  // public static checkTokenRefresh = async (account: string) => {
  //     if (IdentityManager.isTokenValid) {
  //         await fetch(`/auth/refresh?&account=${account}`).then(async (response) => {
  //             let data :any = await response.json();
  //             console.log(data);
  //             if (response.status === 500) {
  //                 console.log(data);
  //             } else {
  //                 let _userDataString = localStorage.getItem(Constants.USER_PROFILE);
  //                 if (_userDataString !== null) {
  //                     let _userData = CryptoUtils.decryptData(_userDataString);
  //                     // let _userData = JSON.parse(_userDataString);
  //                     Constants.REFRESH_IDENTITY_PROPERTIES.forEach(property => _userData[property] = data.response[property]);
  //                     let userDataEncrypt = CryptoUtils.encryptData(_userData);
  //                     localStorage.setItem(Constants.USER_PROFILE, userDataEncrypt);//JSON.stringify(_userData));
  //                     IdentityManager.userData = _userData;
  //                 }
  //             }
  //         });
  //     }
  // }
  

  public static verifyCredentials = async (payload: any) => {
    let _result: any = null;
    let token = IdentityManager.AccessToken;
    try {
      let _response = await fetch(
          `/okta/check`,
          {
              method: 'POST',
              headers: {
                  'Authorization': `Bearer ${token}`,
                  'Accept': 'application/json',
                  'Content-Type': 'application/json'
              },
              body: JSON.stringify(payload)
          }
      );
      _result = await _response.json();
    }
    catch (error) {
      console.log(error);
    }
    return _result;
  }
}