import { group } from 'console';
import moment from 'moment';
import Constants from "../Common/Constants";
import PFManager from './Process.Fulfillment.Manager';
// import CryptoUtils from "../Common/Crypto.Utils";

export default class IdentityManager {

    public static userData: any = null;

    public static loadUserData = () => {
        try {
            if (IdentityManager.userData === null)
                IdentityManager.userData = {};

            if (Object.keys(IdentityManager.userData).length == 0) {
                let userInfoString = localStorage.getItem(Constants.USER_PROFILE);
                if (typeof userInfoString == "string") {
                    // IdentityManager.userData = CryptoUtils.decryptData(userInfoString);
                    IdentityManager.userData = JSON.parse(userInfoString);
                } else {
                    IdentityManager.userData = userInfoString;
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    public static get AccessToken(): string | null {
        let AccessToken = null;
        let userInfoString = localStorage.getItem(Constants.USER_PROFILE)
        if (typeof userInfoString == "string") {
            // let userInfo: any = CryptoUtils.decryptData(userInfoString);
            let userInfo: any = JSON.parse(userInfoString);
            AccessToken = userInfo.accessToken;
        }
        return AccessToken;
    }

    public static get isTokenValid(): boolean {
        let result = true;
        let userInfoString = localStorage.getItem(Constants.USER_PROFILE);
        if (typeof userInfoString == "string") {
            // let userInfo: any = CryptoUtils.decryptData(userInfoString);
            let userInfo: any = JSON.parse(userInfoString);
            let currentTime = moment().toDate().getTime();
            let tokenTime = moment(userInfo.expiresOn).toDate().getTime();
            result = currentTime > tokenTime;
        }
        return result;
    }

    public static getUserProperty = (property: string) => {
        let result = "";
        IdentityManager.loadUserData();
        if (IdentityManager.userData) {
            let value: string = IdentityManager.userData[property];
            //
            if (value != "" && value != null && value != undefined) {
                result = value;
            }
        }
        return result;
    }

    public static get storeNumber(): string {

        let result = "";
        let value = IdentityManager.getUserProperty(Constants.city);
        if (value.includes('-')) {
            result = value.split('-')[0];
            result = result.replace(/^0+/, '');
        }
        // return '220';
        console.log(result, "storeNumber");
        return result;
    }


    public static get isAdmin(): boolean {
        let result = false;
        let groups = IdentityManager.getUserAllGroups();
        if (groups.length > 0) {
            let adminGroup = groups.filter(a => a.Admin != null && a.Admin != undefined && a.Admin == "1");
            result = adminGroup.length > 0 ? true : false;
        }
        return result;
    }


    // This does not account if user is marked as admin or not
    public static get isUser(): boolean {
        console.log("isUser");
        let result = false;
        // If user exist in any of the groups then mark it as User
        let groups = IdentityManager.getUserAllGroups();
        groups = groups.filter(a => a.Admin != "1");
        if (groups.length > 0) {
            result = true;
        }
        return result;
    }

    public static get isMAAUser(): boolean {

        let result = false;
        let groups = IdentityManager.getUserAllGroups();
        if (groups.length > 0) {
            let maaGroups = groups.filter(a => a.MAA != null && a.MAA != undefined && a.MAA == "1");
            result = maaGroups.length > 0 ? true : false;
        }
        return result;

    }

    public static get isUserAuthorized(): boolean {

        let result = true;
        let value: any = IdentityManager.getUserProperty(Constants.groups);
        if (value !== undefined) {

            // if(!IdentityManager.isAdmin || IdentityManager.isUser)
            // {
            //     result = true;
            if (!IdentityManager.isAdmin) {
                if (IdentityManager.storeNumber == "" || !IdentityManager.storeNumber) {
                    result = false;
                }
            }

            // }

        }
        return result;

    }
    public static get getStorePreFix(): string {
        let prefix = "G";
        prefix = IdentityManager.isMAAUser ? "M" : prefix;
        return prefix;
    }

    public static get userDefaultStoreType(): string {

        let storeType = Constants.gcStoreType;
        if (IdentityManager.isMAAUser) {
            storeType = Constants.mmaStoreType;
        }

        return storeType;

    }

    public static getUserNavigation = () => {

        let navigations = [];
        let allFoundGroups: any[] = [];

        if (PFManager.shippingGroups.length > 0) {
            let allFoundGroups = IdentityManager.getUserAllGroups();
            let targetGroup = allFoundGroups.length > 0 ? allFoundGroups[0] : null;
            if (targetGroup) {
                let routesData = Constants.DRAWER_DATA;
                for (let i = 0; i < routesData.length; i++) {
                    if (targetGroup[routesData[i].Route]) {
                        if (targetGroup[routesData[i].Route] == "1") {
                            navigations.push(routesData[i]);
                        }
                    }
                }
            }
        }
        console.log(navigations, "navigations");
        return navigations;

    }

    public static getUserAllGroups = () => {

        let groups: any[] = []
        if (PFManager.shippingGroups.length > 0) {
            let value: any = IdentityManager.getUserProperty(Constants.groups);
            if (value !== undefined) {

                for (let i = 0; i < value.length; i++) {
                    let filter = PFManager.shippingGroups.filter(a => a.GroupId == value[i]);
                    if (filter && filter.length > 0) {
                        groups = [...groups, ...filter];
                    }

                }

                groups = groups.sort((n1: any, n2: any) => {
                    if (n1.Precedence > n2.Precedence) {
                        return 1;
                    }

                    if (n1.Precedence < n2.Precedence) {
                        return -1;
                    }

                    return 0;
                });
            }
        }
        return groups;

    }

    public static getUserCompany = () => {

        let company = "";
        let userGroups = IdentityManager.getUserAllGroups();
        let targetGroup = userGroups.length > 0 ? userGroups[0] : null;
        if (targetGroup) {
            if (targetGroup.MAA != null && targetGroup.MAA != undefined && targetGroup.MAA == "1") {
                company = Constants.mmaStoreType;
            }
            else if (targetGroup.CC != null && targetGroup.CC != undefined && targetGroup.CC == "1") {
                company = Constants.ccStoreType;
            }
            else {
                company = Constants.gcStoreType
            }
        }

        return company;

    }
}