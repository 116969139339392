import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, Slide, TextField, Typography } from "@material-ui/core";
import { TransitionProps } from "@material-ui/core/transitions";
import React, { useState } from "react";

import ErrorIcon from '@material-ui/icons/Error';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import AuthManager from "../../Managers/Auth.manager";
import IdentityManager from "../../Managers/Identity.manager";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Constants from "../../Common/Constants";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function VerifyCredentials(props: any): any {
    const [empIdorEmail, setEmpIdorEmail] = useState("");
    const [password, setPassword] = useState("");
    const [invalidCreds, setInvalidCreds] = useState(false);
    const [loading, setLoading] = useState(false);
    const [invalidMsg, setInvalidMsg] = useState("");

    const changeEmpIdorEmail = (event: any) => {
        setEmpIdorEmail(event.target.value);
    }

    const changePassword = (event: any) => {
        setPassword(event.target.value);
    }

    const renderServices = () => {
        // For MultiBox Messages
        if (props.boxes?.length > 0) {
            let boxes = props.boxes;
            return (
                <ul>
                    {
                        boxes.map((box: any) => {
                            return (<li style={{ margin: "2px" }}><b>Dimensions: </b>{box.dimension}, <b>Weight: </b>{box.weight}{box.weightUnit}, <b>Service: </b>{box.serviceName}</li>)
                        })
                    }
                </ul>
            )
        }
    }

    const confirm = async () => {
        let payload: any = {
            paramA: window.btoa(empIdorEmail),
            paramB: window.btoa(password)
        }
        setLoading(true)
        let response = await AuthManager.verifyCredentials(payload);
        if (response?.success == true && response?.data?.success == true) {
            setLoading(false)
            props.credsConfirmed(response.data.approvalProfile);
        }
        else {
            setLoading(false);
            setInvalidCreds(true);
            if (response?.data?.msg) {
                setInvalidMsg(response.data.msg)
            }
            else {
                setInvalidMsg("Unable to Verify Credentials. Some Error Occurred.");
            }
        }
    }

    const retry = () => {
        setInvalidMsg("");
        setEmpIdorEmail("");
        setPassword("");
        setInvalidCreds(false);
    }

    const close = () => {
        retry();
        props.close();
    }

    const renderVerifyCreds = () => {
        return (
            invalidCreds ?
                <React.Fragment>
                    <DialogContent>
                        <Grid item xs={12} sm={12} className='inputField' style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <Typography variant="h6" style={{ color: '#D93A2F' }}>
                                {invalidMsg}
                            </Typography>
                        </Grid>
                    </DialogContent>
                    <Divider />
                    <DialogActions>
                        <Button color='primary' disabled={empIdorEmail == "" || password == ""} variant="contained" onClick={() => { retry() }} startIcon={<ArrowBackIcon />} >
                            Retry
                        </Button>
                        <Button key={1} style={{ whiteSpace: 'nowrap', backgroundColor: "grey", color: "white" }} onClick={() => { close() }} variant="contained" startIcon={<CloseIcon />}>
                            Close
                        </Button>
                    </DialogActions>
                </React.Fragment>
                :
                <React.Fragment>
                    <DialogContent>
                        <Grid item xs={12} sm={12} >
                            <span>You are about to give approval for the following {props.boxes?.length == 1 ? 'box:' : 'boxes:'}</span>
                            {renderServices()}
                        </Grid>
                        <Divider />
                        <Grid item xs={12} sm={12} className='inputField' style={{ marginTop: "10px" }}>
                            <Typography>
                                Employee ID or Email*
                            </Typography>
                            <TextField type="text" fullWidth InputLabelProps={{ shrink: false }} id="email" variant="outlined" value={empIdorEmail} autoComplete="off"
                                onChange={(e) => changeEmpIdorEmail(e)} placeholder="Employee ID = 900026 or Email = JohnD900026@guitarcenter.com" />
                        </Grid>
                        <Grid item xs={12} sm={12} className='inputField'>
                            <Typography>
                                Password*
                            </Typography>
                            <TextField type="password" fullWidth InputLabelProps={{ shrink: false }} id="password" variant="outlined" value={password}
                                onChange={(e) => changePassword(e)} placeholder="Enter your password" autoComplete="new-password" />
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button color='primary' disabled={empIdorEmail == "" || password == ""} variant="contained" onClick={() => { confirm() }} startIcon={<DoneIcon />}>
                            Confirm
                        </Button>
                        <Button key={1} style={{ whiteSpace: 'nowrap', backgroundColor: "grey", color: "white" }} onClick={() => { close() }} variant="contained" startIcon={<CloseIcon />}>
                            Close
                        </Button>
                    </DialogActions>
                </React.Fragment>
        );
    }
    return (
        <Dialog open={props.open} TransitionComponent={Transition} fullWidth={true}>
            <DialogTitle className={`confirm`}>
                <div className={'dialogHeader'}>
                    Manager Approval
                    {<HelpOutlineIcon />}
                </div>
            </DialogTitle>
            {loading ?
                <div style={{ display: "flex", justifyContent: "center", height: '60px' }}>
                    <CircularProgress style={{ color: "#D93A2F", marginTop: "10px" }} />
                </div>
                :
                renderVerifyCreds()
            }
        </Dialog>
    );
}