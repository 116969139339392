import React from 'react';
import './EndOfDay.scss';
import { Card, CardContent, Radio } from '@material-ui/core';
import SecurityIcon from '@material-ui/icons/Security';
import { FaUps, FaFedex, FaUsps } from "react-icons/fa";

export default function Carrier(props: any): any {

    const selectCarrierIcon = () => {
      let _carrier = props.data.title.toLowerCase();
      switch (_carrier) {
        case 'ups':
          return <FaUps style={{fontSize: '50px'}} title="UPS"/>;
        case 'usps':
          return <FaUsps style={{fontSize: '50px'}}  title="USPS"/>;
        case 'fedex':
          return <FaFedex style={{fontSize: '50px'}}  title="FedEx"/>;
        default:
          return <SecurityIcon/>;
      }
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => props.changeState({selected: event.target.value, services: props.data.services})

    const checked = props.selected === props.data.title;
    
    return (
        <Card className={checked ? 'cardColor cardNormal' : 'cardNormal'} onClick={() => {props.changeState({selected: props.data.title, services: props.data.services})}}>
            <CardContent>
                <div className='Column'>
                    <div className='Title'>
                        <div className='radioButton'>
                            <Radio
                                color='primary'
                                checked={checked}
                                onChange={handleChange}
                                value={props.data.title}
                                inputProps={{ 'aria-label': props.data.title }}
                            />
                            {selectCarrierIcon()}
                        </div>
                        <div className='name'>{props.data.title}</div>
                    </div>
                </div>
            </CardContent>
        </Card>
    );
}