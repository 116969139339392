import React from 'react';
import './Routes.scss';

import Utils from '../Common/Utils';
import Constants from '../Common/Constants';
import CacheBuster from '../Managers/Cache.Buster';
import StoreManager from '../Managers/Store.manager';

import LabelDialog from '../Subcomponent/Dialog/LabelDialog';
import AuditsTable from '../Subcomponent/TechAudits/AuditsTable';
import AuditFilter from '../Subcomponent/TechAudits/AuditFilter';

import { Select, MenuItem } from '@material-ui/core';

export default class TechAudits extends React.Component<any, {}> {

  public state = {
    store: 0,
    filter: 'All',
    app: '',
    labelDialog: false,
    updateNeeded: false,
    selectedLabel: null,

    searchTerm: '',
    updateSearch: false,

    count: 0,
    pagination: Constants.TECH_AUDIT.PAGINATION.DEFAULT,
    storeList: StoreManager.storeList,
  }

  constructor(props: any) {
    super(props);
  }

  componentDidMount = async () => {
    await CacheBuster.checkVersion();
    if (StoreManager.allStoredata.length == 0) {
      await StoreManager.getAllSCStores();
      console.log(StoreManager.storeList, "componentDidMount - StoreManager.storeList");
      this.setState({ storeList: StoreManager.storeList });
    }
  }

  changeState = (data: any) => this.setState(data);

  updateSearchTerm = (e: any) => this.setState({ searchTerm: e.target.value, updateNeeded: true });

  changeStore = (data: any) => this.setState({
    store: data.target.value, updateNeeded: true, count: 0, pagination: Constants.TECH_AUDIT.PAGINATION.DEFAULT
  })

  // RENDER METHODS

  render = () => {
    console.log(this.state.storeList, "aaaaaaaaa");
    return (
      <div className='innerContianer'>
        <LabelDialog
          open={this.state.labelDialog}
          changeState={this.changeState}
          selectedLabel={this.state.selectedLabel}
        />

        <div className='primaryRow'>
          <div className='headerText'>
            Tech Audits
          </div>

          <div>
            <Select value={this.state.store} onChange={this.changeStore} style={{ background: 'white', padding: '2px', width: '225px' }}>
              <MenuItem value={0}>All</MenuItem>
              {
                this.state.storeList.map((data: any, i: number) => <MenuItem value={i + 1} key={i}>{data.id} - {data.name}</MenuItem>)
              }
            </Select>

            <input
              type="text"
              className="searchBar"
              placeholder="Search..."
              value={this.state.searchTerm}
              onChange={this.updateSearchTerm}
              style={{ marginLeft: '10px', width: Utils.isMobile ? '200px' : '300px' }}
            />
          </div>
        </div>

        <AuditFilter
          app={this.state.app}
          count={this.state.count}
          filter={this.state.filter}
          changeState={this.changeState}
          pagination={this.state.pagination}
        />

        <AuditsTable
          app={this.state.app}
          count={this.state.count}
          store={this.state.store}
          filter={this.state.filter}
          changeState={this.changeState}
          searchTerm={this.state.searchTerm}
          pagination={this.state.pagination}
          updateSearch={this.state.updateSearch}
          updateNeeded={this.state.updateNeeded}
        />
      </div>
    );
  }
}