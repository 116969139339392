import React from 'react';
import './Routes.scss';

import DialogBox from '../Subcomponent/Dialog/DialogBox';
import LabelTable from '../Subcomponent/Labels/LabelTable';
import LabelDialog from '../Subcomponent/Dialog/LabelDialog';
import VoidLabelDialog from '../Subcomponent/Dialog/VoidDialog';

import { withStyles } from '@material-ui/core/styles';
import { Button, IconButton } from '@material-ui/core';

import { FaEraser, FaPrint } from "react-icons/fa";
import MultiVoidDialog from '../Subcomponent/Dialog/MultiVoidDialog';
import Utils from '../Common/Utils';
import MultiPrintDialog from '../Subcomponent/Dialog/MultiPrintDialog';
import CacheBuster from '../Managers/Cache.Buster';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import StoreManager from '../Managers/Store.manager';
import Constants from '../Common/Constants';
import LabelUtils from '../Common/Label.Utils';
import PFManager from '../Managers/Process.Fulfillment.Manager';
import Loader from 'react-loader-spinner';

const CreateButton = withStyles(({
  root: {
    color: 'white',
    fontWeight: 600,
    backgroundColor: "#D93A2F",
    '&:hover': { backgroundColor: "#bd3627" },
  },
}))(Button);

const ExcelButton = withStyles(({
  root: {
    color: 'white',
    fontWeight: 600,
    marginLeft:"5px",
    backgroundColor: "#D93A2F",
    '&:hover': { backgroundColor: "#bd3627" },
  },
}))(Button);

export default class Labels extends React.Component<any, {}> {

  public state = {
    searchTerm: '',
    multiVoid: false,
    multiPrint: false,
    dataLoaded: true,
    openDialog: false,
    labelDialog: false,
    updateNeeded: false,
    selectedLabel: null,
    bulkProcessLabels: [],
    voidLabelDialog: false,
    dialogText: { Title: '', Msg: '' }
  }

  constructor(props: any) {
    super(props);

    this.changeState = this.changeState.bind(this);
  }

  componentDidMount = async () => {
    await CacheBuster.checkVersion();
    await StoreManager.getAllSCStores();
    this.setState({ selectedLabel: null, labelDialog: false, bulkProcessLabels: [] })
  }

  changeState = (data: any) => this.setState(data);

  BulkVoid = () => this.setState({ multiVoid: true });

  BulkPrint = () => this.setState({ multiPrint: true });

  handleDateChange = (date: Date | null) => console.log(date);

  updateSearchTerm = (e: any) => this.setState({ searchTerm: e.target.value, updateNeeded: true });

  // RENDER METHODS
  render = () => {
    return (
      <div className='innerContianer'>
        <LabelDialog
          open={this.state.labelDialog}
          changeState={this.changeState}
          selectedLabel={this.state.selectedLabel}
        />
        <VoidLabelDialog
          changeState={this.changeState}
          open={this.state.voidLabelDialog}
          selectedLabel={this.state.selectedLabel}
        />
        <DialogBox
          text={this.state.dialogText}
          open={this.state.openDialog}
          handleClose={() => { this.setState({ openDialog: false }) }}
        />
        <MultiVoidDialog
          open={this.state.multiVoid}
          changeState={this.changeState}
          labels={this.state.bulkProcessLabels}
        />
        <MultiPrintDialog
          open={this.state.multiPrint}
          changeState={this.changeState}
          labels={this.state.bulkProcessLabels}
        />

        <div className='primaryRow'>
          <div style={{display:"flex",alignItems:"center"}}>
            <CreateButton onClick={() => { this.props.history.push('/CreateLabel') }}>Create Label</CreateButton>
          </div>

          <div>
            {
              !Utils.isMobile && this.state.bulkProcessLabels.length > 0 &&
              <CreateButton onClick={this.BulkPrint} style={{ marginRight: '5px' }}>
                <FaPrint style={{ marginRight: '5px' }} /> Print Selected
              </CreateButton>
            }
            {
              !Utils.isMobile && this.state.bulkProcessLabels.length > 0 &&
              <CreateButton onClick={this.BulkVoid}>
                <FaEraser style={{ marginRight: '5px' }} /> Void Selected
              </CreateButton>
            }

            <input
              type="text"
              className="searchBar"
              placeholder="Search Anything ..."
              value={this.state.searchTerm}
              onChange={this.updateSearchTerm}
              style={{ marginLeft: '10px', width: Utils.isMobile ? '150px' : '300px' }}
            />

            {
              this.state.searchTerm !== '' &&
              <IconButton onClick={() => { this.updateSearchTerm({ target: { value: '' } }) }}>
                <HighlightOffIcon color='primary' />
              </IconButton>
            }
          </div>
        </div>


        {
          Utils.isMobile &&
          <div>
            {
              this.state.bulkProcessLabels.length > 0 &&
              <CreateButton onClick={this.BulkPrint} style={{ marginRight: '5px' }}>
                <FaPrint style={{ marginRight: '5px' }} /> Print Selected
              </CreateButton>
            }
            {
              this.state.bulkProcessLabels.length > 0 &&
              <CreateButton onClick={this.BulkVoid}>
                <FaEraser style={{ marginRight: '5px' }} /> Void Selected
              </CreateButton>
            }
          </div>
        }

        {
          this.state.dataLoaded &&
          <LabelTable
            changeState={this.changeState}
            searchTerm={this.state.searchTerm}
            updateNeeded={this.state.updateNeeded}
          />
        }
      </div>
    );
  }
}