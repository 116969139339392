import React from 'react';
import './Routes.scss';
import CacheBuster from '../Managers/Cache.Buster';
import { withRouter } from "react-router-dom";
import ExcelManager from '../Managers/Excel.Manager';
import Loader from 'react-loader-spinner';
import { Button, Card, CardContent, Divider } from '@material-ui/core';
export default class Export extends React.Component<any, {}> {

  public state = {
    id: "",
    statusText: "",
    isProcessCompleted: 0,
    url: "",
    error: false,
    intervalId: 0,
    isRunning: false,
    limit: 0,
    urlExpired: 0,
    minutes: 4,
    seconds: 59,

  }

  constructor(props: any) {
    super(props);
    this.changeState = this.changeState.bind(this);
  }

  startTimer = () => {
    let interval = setInterval(() => {
      if (this.state.seconds === 0 && this.state.minutes === 0) {
        this.changeState({ urlExpired: 1 });
        clearInterval(this.state.intervalId);
      }
      else {
        const { minutes, seconds } = this.state;
        if (seconds === 0) {
          this.changeState({ minutes: this.state.minutes - 1, seconds: 60 });
        } else {
          this.changeState({ seconds: this.state.seconds - 1 });
        }
      }
    }, 1000);
    this.setState({ isRunning: true, intervalId: interval });
  };

  getDataByParameters = async (params: any) => {
    try {
      let response = await ExcelManager.sendRequestForExportUrl(params);
      const link = document.createElement('a');
      const filename = params.guid + ".csv";
      if (response?.response?.url && response?.response?.url != "") {
        link.href = response?.response?.url;
        link.download = filename;
        link.click();
        this.startTimer()
        this.changeState({ error: false, isProcessCompleted: 1, url: response?.response?.url, id: params.guid })
      }
      else {
        this.changeState({ error: true, isProcessCompleted: 1 })
      }
    }
    catch (exception) {
      this.changeState({ error: true, isProcessCompleted: 1 })
    }
  }
  downloadAgain = async () => {
    try {
      const link = document.createElement('a');
      const filename = this.state.id + ".csv";
      link.href = this.state.url;
      link.download = filename;
      link.click();
    }
    catch (exception) {
      this.changeState({ error: true, isProcessCompleted: 1 })
    }
  }
  componentDidMount = async () => {
    const queryParams = new URLSearchParams(this.props.location.search);
    const idValue = queryParams.get("id");
    let parameters = localStorage.getItem("exportParameters");
    if (parameters != null && parameters != "") {
      let parsedParameters = JSON.parse(parameters);
      if (parsedParameters.guid === idValue) {
        await this.getDataByParameters(parsedParameters)
      }
      else {
        this.changeState({ error: true, isProcessCompleted: 1 })
      }
    }
    else {
      this.changeState({ error: true, isProcessCompleted: 1 })
    }
  }
  componentWillUnmount() {
    clearInterval(this.state.intervalId);
  }
  changeState = (data: any) => this.setState(data);

  // RENDER METHODS

  render = () => {
    return (
      <div className='innerContianer'>
        <div className='primaryRow'>
          <div className='headerText'>Export Excel File</div>
        </div>
        <div className='row'>
          <Card>
            <CardContent>
              {
                this.state.isProcessCompleted == 0 ?
                  <>
                    <h3 style={{ textAlign: "center" }}>Excel file is being prepared please do not close this window</h3>
                    <Loader type="TailSpin" color="#D93A2F" height={80} width={80} />
                  </>

                  :
                  <div>
                    {
                      this.state.error == true ?
                        <p style={{ textAlign: "center", color: "#D93A2F" }}>There was an error while preparing the file. Please close this window and start process again</p>
                        :
                        <></>
                    }
                    {
                      this.state.isProcessCompleted == 1 && this.state.error != true ?
                        <><p>The file is ready to download. If the download does not start automatically, please click on the download button to continue. The download link will expire in <b>{this.state.minutes}:{this.state.seconds}</b></p>
                          {
                            this.state.urlExpired == 0 ?
                              <Button variant="contained" color="primary" style={{ background: "#22945e" }} onClick={() => { this.downloadAgain() }}>Download</Button>
                              :
                              <><p style={{ textAlign: "center", color: "#D93A2F" }}>Download url is expired please start process again if you want to download file again</p></>
                          }
                        </>
                        :
                        <></>
                    }
                  </div>
              }

              <h4 style={{ textAlign: "center" }}>{this.state.statusText}</h4>
            </CardContent>
          </Card>
        </div>
      </div>
    );
  }
}