import React from 'react';
import './TechAudits.scss';

import Utils from '../../Common/Utils';
import AuditLogsManager from '../../Managers/Audit.Logs.Manager';


import moment from 'moment';
import Loader from "react-loader-spinner";
import AuditLogo from './AuditLogo';
import StoreManager from '../../Managers/Store.manager';
import AuditDescription from './AuditDescription';

export default class AuditsTable extends React.Component<any, {}> {

  public state = {
    auditLogRows: [],
    dataLoaded: false,
    selectState: false,
  }

  constructor(props: any) {
    super(props);
  }

  componentDidMount = async () => {
    await this.loadData();
  }

  componentDidUpdate = async () => {
    if (this.props.updateNeeded) {
      await this.loadData();
    }
  }

  loadData = async () => {
    this.props.changeState({updateNeeded: false});
    this.setState({dataLoaded: false});
    let storeId = '';
    if (this.props.store !== 0) {
      if (StoreManager.storeList.length > 0) {
        storeId = StoreManager.storeList[this.props.store-1].id
      }
    }
    // let count = await this.getCount(storeId);
    let [logs, count] = await this.getData(storeId);
    this.setState({auditLogRows: logs, dataLoaded: true});
    this.props.changeState({count: count});
  }

  getData = async (storeId: string) => {
    let app = this.props.app,
        filter = this.props.filter,
        size = this.props.pagination.size,
        from = (this.props.pagination.page - 1) * this.props.pagination.size;
        
    let [logs, count] = await AuditLogsManager.getAllLogs(storeId, size, from, this.props.searchTerm, filter, app);
    return [logs, count];
  }

  getCount = async (storeId: string) => {
    let count = await AuditLogsManager.getAuditCount(storeId, this.props.searchTerm);
    return count;
  }
  
  openLabelDialog = (ServiceResponse: any) => this.props.changeState({labelDialog: true, selectedLabel: ServiceResponse})

  // RENDER METHODS

  render = () => {
    const height: string = (Utils.displayHeight*0.98 - 110) + 'px';
    const margin: string = (0.5 * (Utils.displayHeight*0.98 - 101)) + 'px';
    // const data: any[] = this.state.auditLogRows.map((r: any, i: number) => ({...r, id: i}));
    return (
      <div className="AuditTableContainer" style={{height: height}}>
        {
          this.state.dataLoaded
          ?
            <table className="AuditTable">
              {this.renderHeader()}
              {this.renderBody()}
            </table>
          :
            <div style={{marginTop: margin}}>
              <Loader type="TailSpin" color="#D93A2F" height={80} width={80} />
            </div>
        }
      </div>
    );
  }

  renderHeader = () => {
    return (
      <thead>
        <tr>
          <th>Record Operation</th>
          <th>Application Id</th>
          <th>Log Date/Time</th>
          <th>Employee ID</th>
          <th>Store Number</th>
          <th>Details</th>
        </tr>
      </thead>
    );
  }
  
  renderBody = () => {
    return (
      <tbody>
        {this.state.auditLogRows.map((data: any, i: number) => this.renderRow(data, i))}
      </tbody>
    );
  }  

  renderRow = (data: any, rowNum: number) => {
    let _date = moment(data.LogTime).format('YYYY-MM-DD') + ' ' + moment(data.LogTime).toDate().toLocaleTimeString();
    return (
      <tr key={rowNum}>
        <td><AuditLogo logType={data.LogType}/></td>
        <td>{data.ApplicationId}</td>
        <td>{_date}</td>
        <td>{data.EmployeeID}</td>
        <td>{data.Store}</td>
        <td><AuditDescription data={data}/></td>
      </tr>
    )
  }
}