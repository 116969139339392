import React from 'react';
import './CreateLabelViews.scss';
import { Card, CardContent } from '@material-ui/core';
import classes from '*.module.css';
import StoreIcon from '@material-ui/icons/Store';
import PersonIcon from '@material-ui/icons/Person';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import Constants from '../../../Common/Constants';

export default function ShipmentType(props: any): any {

    const changeStep = (type: string) => {
        //;
        if (type !== props.formType) {
            props.changeState({
                shipToDetails: Constants.SHIP_DETAILS,
                shipFromDetails: Constants.SHIP_DETAILS
            });
        }
        props.changeFormType(type);
        props.changeActiveStep(1);
    }

    return (
        <div className='stepContainer' style={{ height: '400px' }}>
            <h2>Shipment Type</h2>
            <div className='cardContainer'>
                <Card className='cards' onClick={() => { changeStep('Store/DC') }}>
                    <CardContent>
                        <div>
                            <div className='iconHeader'>Store to Store/DC</div>
                            <div className='iconRow'><StoreIcon /> <DoubleArrowIcon /> <StoreIcon /></div>
                            {/* <div style={{height: '18px'}}> </div> */}
                        </div>
                    </CardContent>
                </Card>
                <Card className='cards' onClick={() => { changeStep('Customer/Vendor') }}>
                    {/* <Card className='cards' style={{cursor:'inherit', border:'1px solid grey', color:'grey'}}> */}
                    <CardContent>
                        <div>
                            <div className='iconHeader'>Store to Customer/Vendor</div>
                            <div className='iconRow'><StoreIcon /> <DoubleArrowIcon /> <PersonIcon /></div>
                            {/* <div className='iconRow'><StoreIcon style={{color:'#f3837b'}}/> <DoubleArrowIcon  style={{color:'#f3837b'}}/> <PersonIcon  style={{color:'#f3837b'}}/></div>
                            <div className='iconHeader' style={{fontSize:'16px', color:'#D93A2F'}}>Coming Soon!</div> */}
                        </div>
                    </CardContent>
                </Card>
            </div>
        </div>
    );
}
