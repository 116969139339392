import React from 'react';
import './TechAudits.scss';
import Utils from '../../Common/Utils';
import Constants from '../../Common/Constants';

import { Button, Chip, Divider, Select, MenuItem, Paper } from '@material-ui/core';
import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TablePagination from '@material-ui/core/TablePagination';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        chip: {
            fontSize: '15px',
            margin: theme.spacing(0.4),
            borderRadius: '2px !important'
        },
    }),
);

export default function AuditFilter(props: any): any {
    const classes = useStyles();
    const filters = Constants.TECH_AUDIT.FILTERS;
    const pageSizeArray = Constants.TECH_AUDIT.PAGINATION.OPTIONS;

    const changePageSize = (data: any) => {
        let pagination = { size: 5, page: 1 };
        pagination.size = data.target.value;
        props.changeState({ pagination, updateNeeded: true })
    }

    const changePage = (data: any, type: string) => {
        let pagination = props.pagination;
        if (type === 'back' && pagination.page > 1) {
            pagination.page--;
        } else {
            pagination.page++;
        }
        props.changeState({ pagination, updateNeeded: true })
    }

    const changeApp = (app: any) => () => props.changeState({ app: app, updateNeeded: true })

    const changeFilter = (filter: any) => () => props.changeState({ filter: filter, updateNeeded: true })

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,) => {
        let pagination = props.pagination;
        pagination.page = newPage + 1;
        console.log(pagination.page, "handleChangeOld");
        props.changeState({ pagination, updateNeeded: true })

    }
    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {

        let pagination = { size: 5, page: 1 };
        pagination.size = parseInt(event.target.value);
        console.log(pagination.size, "pagination.size");
        props.changeState({ pagination, updateNeeded: true });
    };

    let from = (props.pagination.page - 1) * props.pagination.size;
    let to = from + props.pagination.size;
    if (to > props.count) { to = props.count }

    return (
        <React.Fragment>
            <Paper className='AuditFiltersContainer'>

                <div className='chipFilters'>
                    <div className='logType'>
                        {filters.map((data: any) =>
                            <Chip
                                clickable
                                key={data}
                                label={data}
                                color="primary"
                                className={classes.chip}
                                onClick={changeFilter(data)}
                                variant={props.filter === data ? 'default' : 'outlined'}
                            />
                        )}
                    </div>

                    <Divider orientation={!Utils.isMobile ? "vertical" : "horizontal"} style={{ height: !Utils.isMobile ? '80px' : "5px" }} />

                    <div className='appType'>
                        {/* TECH_AUDIT_FILTERS */}
                        {Constants.TECH_AUDIT_FILTERS.map((data: any) =>
                            <Chip
                                clickable
                                key={data.NAME}
                                label={data.NAME}
                                color="primary"
                                className={classes.chip}
                                onClick={changeApp(data.VALUE)}
                                variant={props.app === data.VALUE ? 'default' : 'outlined'}
                            />
                        )}
                    </div>

                    <Divider orientation={!Utils.isMobile ? "vertical" : "horizontal"} style={{ height: !Utils.isMobile ? '80px' : "5px" }} />
                </div>

                {/* <div className='pagination'>
                    <div className='buttons'>
                        <Button
                            color='primary'
                            variant='contained'
                            disabled={props.pagination.page === 1}
                            onClick={(e) => { changePage(e, 'back') }}
                        >
                            <ChevronLeftIcon />
                        </Button>

                        <div className='text'>
                            {`${from + 1}-${to} of ${props.count}`}
                        </div>

                        <Button
                            color='primary'
                            variant='contained'
                            disabled={to === props.count}
                            onClick={(e) => { changePage(e, 'forward') }}
                        >
                            <ChevronRightIcon />
                        </Button>
                    </div>

                    <div>
                        <span>Page Size: </span>
                        <Select
                            className='dropdown'
                            onChange={changePageSize}
                            value={props.pagination.size}
                        >
                            {pageSizeArray.map((data: any, i: number) => <MenuItem value={data} key={i}>{data}</MenuItem>)}
                        </Select>
                    </div>
                </div> */}

            </Paper>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#D93A2F' }}>

                < div id='paginationWebId' className="paginationWeb" style={{ background: "#D93A2F", display: "flex" }
                }>

                    <div style={{ paddingTop: "10px" }}>

                        <TablePagination
                            component="div"
                            count={props.count}
                            page={props.pagination.page - 1}
                            onPageChange={handleChangePage}
                            rowsPerPage={props.pagination.size}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />

                    </div>
                </div >
            </div>
        </React.Fragment>
    )
}