import * as React from 'react';
import PropTypes from 'prop-types';
import { DataGrid } from '@mui/x-data-grid';
import { makeStyles } from '@material-ui/styles';
import { Button, Select, MenuItem } from '@material-ui/core';

import PrintConfigurationManager from '../../Managers/PrintConfiguration.Manager';
import Utils from '../../Common/Utils';

function renderInputCell(params: any) {
  return params.value;
}

function renderDeleteCell(params: any) {
  return <Button color="primary" variant="contained" onClick={() => { params.row.delete(params.row.StoreNo) }}>Delete</Button>;
}

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: 16,
  },
});

function EditInputCell(props: any) {
  const { id, value, api, field } = props;
  const classes = useStyles();

  const handleChange = (event: any) => {
    api.setEditCellValue({ id, field, value: event.target.value }, event);
    // Check if the event is not from the keyboard
    // https://github.com/facebook/react/issues/7407
    if (event.nativeEvent.clientX !== 0 && event.nativeEvent.clientY !== 0) {
      api.commitCellChange({ id, field });
      api.setCellMode(id, field, 'view');
    }
  };

  const handleRef = (element: any) => {
    if (element) { element.querySelector(`input[value="${value}"]`).focus(); }
  };

  const list = field === 'PrinterType' ? PrintConfigurationManager.printerTypes : PrintConfigurationManager.printingTypes

  return (
    <div className={classes.root}>
      <Select value={value} onChange={handleChange} ref={handleRef} style={{ width: '190px', margin: '0 auto' }}>
        {list.map((data: any, i: number) => <MenuItem value={data} key={i}>{data}</MenuItem>)}
      </Select>
    </div>
  );
}

EditInputCell.propTypes = {
  api: PropTypes.any.isRequired, // GridApi that let you manipulate the grid.
  field: PropTypes.string.isRequired, // The column field of the cell that triggered the event
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired, // The grid row id.
  value: PropTypes.oneOfType([ // The cell value, but if the column has valueGetter, use getValue.
    PropTypes.instanceOf(Date),
    PropTypes.number,
    PropTypes.object,
    PropTypes.string,
    PropTypes.bool,
  ]),
};

function renderEditInputCell(params: any) { return <EditInputCell {...params} />; }

export default function PrintList(props: any) {

  const columns = [
    {
      field: 'StoreNo',
      headerName: 'Store No',
      width: 200,
    },
    {
      field: 'PrinterName',
      headerName: 'Printer Name',
      editable: true,
      width: 200,
    },
    {
      field: 'PrinterType',
      headerName: 'Printer Type',
      renderCell: renderInputCell,
      renderEditCell: renderEditInputCell,
      editable: true,
      width: 200,
    },
    {
      field: 'PrintingType',
      headerName: 'Printing Type',
      renderCell: renderInputCell,
      renderEditCell: renderEditInputCell,
      editable: true,
      width: 200,
    },
    {
      field: 'id',
      headerName: 'Actions',
      renderCell: renderDeleteCell,
      width: 200,
    }
  ];

  const onCellEditCommit = async (data: any, event: any) => {
    console.log(data)
    let currentRow = rows[data.id];
    let checkRow = data.row !== undefined ? data.row : currentRow;
    if (!(checkRow[data.field] === data.value)) {
      let body: any = {
        PrinterName: currentRow.PrinterName,
        PrinterType: currentRow.PrinterType,
        PrintingType: currentRow.PrintingType,
        StoreNo: currentRow.StoreNo
      }
      body[data.field] = data.value;

      let requestBody = { "ACTION": PrintConfigurationManager.requestTypes.Update, "PAYLOAD": body };
      let printers: any = await PrintConfigurationManager.sendPrintConfigRequest(requestBody);
    }
  }

  const rows = props.displayRows.map((r: any, i: number) => ({ ...r, id: i, delete: props.deleteItem }));

  return (
    <div style={{ height: window.innerHeight - ((27 / 100) * window.innerHeight), width: '100%' }}>
      <DataGrid style={{ background: 'white' }} rows={rows} columns={columns} onCellEditCommit={onCellEditCommit} />
    </div>
  );
}
