import React from 'react';
import './Routes.scss';
import CacheBuster from '../Managers/Cache.Buster';

export default class LandingPage extends React.Component<any, {}> {

  constructor(props: any) {
    super(props);
  }

  componentDidMount = async () => {
    await CacheBuster.checkVersion();
  }
// RENDER METHODS

    render = () => {
      return (
        <div className='innerContianer'>
          <div className='welcomeText'>Welcome to Shipping Center</div>
          <br/>
          <div className='welcomeText subText'>If you are experiencing problems with this application please contact the IT Help Desk (818) 735-8800 ext. 2660 or email <a href={"mailto:gwitickets@guitarcenter.com"}>gwitickets@guitarcenter.com</a></div>
        </div>
      );
    }
}