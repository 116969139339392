import React from 'react';
import Loader from "react-loader-spinner";
import './Labels.scss';
import moment from 'moment';

import Utils from '../../Common/Utils';
import Constants from '../../Common/Constants';
import LabelUtils from '../../Common/Label.Utils';

import PrintManager from '../../Managers/Print.Manager';
import IdentityManager from '../../Managers/Identity.manager';
import PFManager from '../../Managers/Process.Fulfillment.Manager';

import LabelFilter from './LabelFilter';
import LabelTableActions from './LabelTableActions';
import LabelTablePageButtons from './LabelTablePageButtons';

import { Button, Divider } from '@material-ui/core';
import TablePagination from '@material-ui/core/TablePagination';
import ClearIcon from '@material-ui/icons/Clear';
import { RiPhoneFindLine } from "react-icons/ri";
import SecurityIcon from '@material-ui/icons/Security';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { FaPrint, FaRegSquare, FaTimesCircle, FaCheckSquare, FaEraser, FaUps, FaFedex, FaUsps, FaCheck, FaTimes, FaSortUp, FaSortDown,FaSort } from "react-icons/fa";
import PFServicesManager from '../../Managers/Fulfillment.Services.Manager';

export default class LabelTable extends React.Component<any, {}> {

  public state = {
    total: 0,
    labelRows: [],
    displayRows: [],
    hasError: false,
    isExpanded: true,
    intialLoad: false,
    dataLoaded: false,
    selectState: false,
    carrierFilterData: [],
    dateFilter: 'Today',
    to: Utils.currentDate,
    from: Utils.dateReducedByDay,
    status: 'All',
    statusFilters: [{ key: "All", doc_count: 0 }, { key: "Active", doc_count: 0 }, { key: "Voided", doc_count: 0 }],
    appFilterData: { list: [], selected: [] },
    selectedFilterData: { carrier: '', services: [] },
    pagination: Constants.LABELS.PAGINATION.DEFAULT,
    filterChanged: false,
    sortField:'',
    sortOrder:''
  }

  constructor(props: any) {
    super(props);

    this.loadData = this.loadData.bind(this);
    this.changeState = this.changeState.bind(this);
    this.resetFilters = this.resetFilters.bind(this);
    this.selectResetAll = this.selectResetAll.bind(this);
    this.resetSearchTerm = this.resetSearchTerm.bind(this);
    this.changeStateReload = this.changeStateReload.bind(this);
    this.labelActionChange = this.labelActionChange.bind(this);
  }

  componentDidMount = async () => {
    await this.loadData('Today', this.state.to, this.state.from);
    this.setState({ intialLoad: true });
    this.paginationScrollFix();
  }

  componentDidUpdate = async () => {
    if (this.props.updateNeeded) {
      this.props.changeState({ updateNeeded: false });
      let appFilterData = this.state.appFilterData;
      appFilterData.selected = []
      let _sortParams = {
        sortField: this.state.sortField,
        sortOrder: this.state.sortOrder
      };
      this.setState({
        filterChanged: false,
        appFilterData: appFilterData,
        selectedFilterData: { carrier: '', services: [] },
        dateFilter: this.props.searchTerm === '' ? 'Today' : '',
        statusFilters: [{ key: "All", doc_count: 0 }, { key: "Active", doc_count: 0 }, { key: "Voided", doc_count: 0 }],
      }, async () => { await this.loadData(this.state.dateFilter, this.state.to, this.state.from,_sortParams) })
    }
  }

  paginationScrollFix = () => {
    var startProductBarPos = -1;
    window.onscroll = function () {
      var bar = document.getElementById('paginationWebId') as any;
      var dataTable = document.getElementById('LabelDiv') as any;
      if (bar && dataTable) {
        var dataTableWidth = dataTable.offsetWidth;
        if (startProductBarPos < 0) startProductBarPos = findPosY(bar);

        if (window.pageYOffset > startProductBarPos && (window.pageYOffset - startProductBarPos > 60)) {
          bar.style.width = (dataTableWidth - (0.1 * dataTableWidth / 100)) + "px";
          bar.classList.add("fixedPagination");

        } else {
          bar.classList.remove("fixedPagination");
          bar.style.width = "";
          bar.style.marginTop = "";
        }
      }
    };



    function findPosY(obj: any) {
      var curtop = 0;
      if (obj && typeof (obj.offsetParent) != 'undefined' && obj.offsetParent) {
        while (obj.offsetParent) {
          curtop += obj.offsetTop;
          obj = obj.offsetParent;
        }
        curtop += obj.offsetTop;
      }
      else if (obj && obj.y)
        curtop += obj.y;
      return curtop;
    }
  }

  changeState = (data: any) => this.setState(data)

  resetSearchTerm = () => this.props.changeState({ searchTerm: '', appFilterData: { list: [], selected: [] } })

  openLabelDialog = (ServiceResponse: any) => this.props.changeState({ labelDialog: true, selectedLabel: ServiceResponse })

  openVoidLabelDialog = (ServiceResponse: any) => this.props.changeState({ voidLabelDialog: true, selectedLabel: ServiceResponse })

  changeStateReload = (data: any) => {
    this.resetSortFilters();
    this.setState(data, async () => { 
      await this.loadData(this.state.dateFilter, this.state.to, this.state.from)});
  } 

  openDialogBox = (data:any) =>{
    this.props.changeState({ dialogText: { Title: 'Error', Msg: data }, openDialog: true })
  }

  resetSortFilters = () => this.setState({sortField:'',sortOrder:''});

  resetFilters = () => this.setState({
    status: 'All',
    dataLoaded: false,
    carrierFilterData: [],
    pagination: { size: 25, page: 1 },
    appFilterData: { list: [], selected: [] },
    selectedFilterData: { carrier: '', services: [] },
    statusFilters: [{ key: "All", doc_count: 0 }, { key: "Active", doc_count: 0 }, { key: "Voided", doc_count: 0 }],
  });

  loadData = async (dateFilter: string, to: any, from: any, sortParams?:any) => {
    this.setState({ dataLoaded: false });
    let labelRows: any[] = [];
    let params = LabelUtils.getLabelParams(dateFilter, to, from, this.state, sortParams);
    let [total, result]: any = await PFManager.getLabels(params, this.props.searchTerm);
    let hasError = false;
    if (result.status !== 500 && result.status !== 400) {
      labelRows = LabelUtils.parseData_LabelTable(result.data)
    } else {
      hasError = true;
      console.log('Error', result);
      this.props.changeState({ dialogText: { Title: 'Error', Msg: result.details.message }, openDialog: true });
    }
    let [carrierFilterData, appFilterData, statusFilters] = LabelUtils.getFilterData_Aggregates(result.aggregations, this.state);
    this.setState({ carrierFilterData, labelRows, displayRows: labelRows, appFilterData, hasError, total, statusFilters, dataLoaded: true, selectState: false });
    this.props.changeState({ dataLoaded: true });
  }

  printLabel = async (data: any, rowNum: number) => {
    let displayRows: any[] = this.state.displayRows;
    displayRows[rowNum].isProcessing = true;
    this.setState({ displayRows });
    let _result: any = null;
    //Use Shipping API or Lambda for PF Services
    if(PFManager.shippingAPI){
        _result = await PFServicesManager.rePrint(data.ServiceResponse.SUID, data.Store);
    }
    else{
        _result = await PFManager.rePrint(data.ServiceResponse.SUID, data.Store);
    }
    if (_result.response.data !== null) {
      console.log(_result.response.data, "_result.response.data");
      await PrintManager.handlePrintDataDisplay(_result.response.printData);
      _result.response.printData.forEach((r: any) => PrintManager.sendPrinterRequest(r));
    } else {
      this.props.changeState({ dialogText: { Title: 'Error', Msg: _result.response.msg }, openDialog: true });
    }
    displayRows[rowNum].isProcessing = false;
    this.setState({ displayRows });
  }

  selectAllRow = () => {
    const [selectState, bulkLabels] = LabelUtils.rowSelect_all(this.state.selectState, this.state.displayRows);
    this.changeBulkRows(selectState, bulkLabels);
  }

  selectSingleRow = (index: number) => {
    const [selectState, bulkLabels] = LabelUtils.rowSelect_single(index, this.state.displayRows);
    this.changeBulkRows(selectState, bulkLabels);
  }

  selectResetAll = () => {
    const [selectState, bulkLabels] = LabelUtils.rowSelect_resetAll(this.state.displayRows);
    this.changeBulkRows(selectState, bulkLabels);
  }

  changeBulkRows = (selectState: any, bulkLabels: any) => {
    this.setState({ selectState });
    this.props.changeState({ bulkProcessLabels: bulkLabels });
  }

  selectCarrierIcon = (carrier: string) => {
    let _carrier = carrier.toLowerCase();
    switch (_carrier) {
      case 'ups':
        return <FaUps title="UPS" />;
      case 'usps':
        return <FaUsps title="USPS" />;
      case 'fedex':
        return <FaFedex title="FedEx" />;
      default:
        return <SecurityIcon />;
    }
  }

  labelActionChange = async (data: any) =>{
    let _sortParams = {
      sortField: this.state.sortField,
      sortOrder: this.state.sortOrder
    };
    this.setState(data, async () => { await this.loadData(this.state.dateFilter, this.state.to, this.state.from,_sortParams) })
  }

  // RENDER METHODS

  render = () => {
    const base: string = (Utils.displayHeight - 70) + 'px';
    const height: string = (Utils.displayHeight * 0.98 - 201) + 'px';
    const margin: string = (0.5 * (Utils.displayHeight * 0.98 - 201)) + 'px';

    return (
      <div className='LabelDiv' id='LabelDiv'>
        {
          this.state.intialLoad &&
          <LabelFilter
            to={this.state.to}
            from={this.state.from}
            status={this.state.status}
            changeState={this.changeState}
            labelRows={this.state.labelRows}
            resetFilters={this.resetFilters}
            isExpanded={this.state.isExpanded}
            dataLoaded={this.state.dataLoaded}
            dateFilter={this.state.dateFilter}
            selectResetAll={this.selectResetAll}
            resetSearchTerm={this.resetSearchTerm}
            appFilterData={this.state.appFilterData}
            filterChanged={this.state.filterChanged}
            statusFilters={this.state.statusFilters}
            changeStateReload={this.changeStateReload}
            carrierFilterData={this.state.carrierFilterData}
            selectedFilterData={this.state.selectedFilterData}
          />
        }

        {/* {
          this.state.dataLoaded && this.state.labelRows.length > 0 &&
          <LabelTableActions
            count={this.state.total}
            changeState={this.changeState}
            pagination={this.state.pagination}
            labelActionChange={this.labelActionChange}
          />
        } */}
        {
          this.state.dataLoaded && this.state.labelRows.length > 0 &&
          <LabelTablePageButtons
            count={this.state.total}
            changeState={this.changeState}
            pagination={this.state.pagination}
            labelActionChange={this.labelActionChange}
            toDate ={this.state.to}
            fromDate = {this.state.from}
            dateFilter = {this.state.dateFilter}
            openDialog = {this.openDialogBox}
            state = {this.state}
            searchTerm = {this.props.searchTerm}
          />
        }

        <div className="LabelTableContainer">
          {
            this.state.dataLoaded
              ?
              this.state.labelRows.length === 0
                ?
                <div style={{ color: '#D93A2F', margin: '20px 0 0 0' }}>
                  <div><ClearIcon style={{ fontSize: '100px' }} /></div>
                  <h1 style={{ margin: '10px 0' }}>No Labels Found</h1>
                </div>
                :
                <table className="LabelTable">
                  {this.renderHeader()}
                  {this.renderBody()}
                </table>
              :
              <div style={{ marginTop: margin }}>
                <Loader type="TailSpin" color="#D93A2F" height={80} width={80} />
              </div>
          }
        </div>

        {/* {
          this.state.dataLoaded && this.state.labelRows.length > 0 &&
          <LabelTablePageButtons
            count={this.state.total}
            changeState={this.changeState}
            pagination={this.state.pagination}
            labelActionChange={this.labelActionChange}
          />
        } */}
      </div>
    );
  }

  getSortIcon = (field:any) => {
    if (field === this.state.sortField) {
      return this.state.sortOrder === 'asc' ? <FaSortUp /> : <FaSortDown />;
    }
    return <FaSort/>;
  };

  handleSort = async (field:any) => {
    let _sortOrder = '';
    if (field === this.state.sortField) {
      _sortOrder = this.state.sortOrder === 'asc' ? 'desc': 'asc';
      this.setState({
        sortOrder: _sortOrder
      });
    } else {
      _sortOrder = "asc"
      this.setState({
        sortField: field,
        sortOrder: _sortOrder
      });
    }

    let _sortParams = {
      sortField: field,
      sortOrder: _sortOrder
    };

    await this.loadData(this.state.dateFilter, this.state.to, this.state.from,_sortParams);
  }

  renderHeader = () => {
    return (
      <thead>
        <tr>
          <th className="faicon">
            {
              this.state.selectState
                ? <FaCheckSquare onClick={() => { this.selectAllRow() }} />
                : <FaRegSquare onClick={() => { this.selectAllRow() }} />
            }
          </th>
          <th style={{ width: '70px' }}>Actions</th>
          <th style={{cursor:'pointer'}} onClick={()=>this.handleSort(Constants.LabelTableFields.ApplicationId)}>
            Application{this.getSortIcon(Constants.LabelTableFields.ApplicationId)}
          </th>
          <th style={{cursor:'pointer'}} onClick={()=>this.handleSort(Constants.LabelTableFields.Status)}>
            Status{this.getSortIcon(Constants.LabelTableFields.Status)}
          </th>
          <th style={{cursor:'pointer'}} onClick={()=>this.handleSort(Constants.LabelTableFields.Weight)}>
            Weight{this.getSortIcon(Constants.LabelTableFields.Weight)}
          </th>
          <th style={{cursor:'pointer'}} onClick={()=>this.handleSort(Constants.LabelTableFields.Cost)}>
            Cost{this.getSortIcon(Constants.LabelTableFields.Cost)}
          </th>
          <th style={{cursor:'pointer'}} onClick={()=>this.handleSort(Constants.LabelTableFields.Store)}>
            Store{this.getSortIcon(Constants.LabelTableFields.Store)}
          </th>
          <th style={{cursor:'pointer'}} onClick={()=>this.handleSort(Constants.LabelTableFields.ShipperReference)}>
            Shipper Reference{this.getSortIcon(Constants.LabelTableFields.ShipperReference)}
          </th>
          <th style={{cursor:'pointer'}} onClick={()=>this.handleSort(Constants.LabelTableFields.Carrier)}>
            Carrier{this.getSortIcon(Constants.LabelTableFields.Carrier)
          }</th>
          <th style={{cursor:'pointer'}} onClick={()=>this.handleSort(Constants.LabelTableFields.Service)}>
            Service{this.getSortIcon(Constants.LabelTableFields.Service)}
          </th>
          <th style={{cursor:'pointer'}} onClick={()=>this.handleSort(Constants.LabelTableFields.TrackingNo)}>
            Tracking Number{this.getSortIcon(Constants.LabelTableFields.TrackingNo)}
          </th>
          <th style={{cursor:'pointer'}} onClick={()=>this.handleSort(Constants.LabelTableFields.CreatedDate)}>
            Created Date{this.getSortIcon(Constants.LabelTableFields.CreatedDate)}
          </th>
          <th style={{cursor:'pointer'}} onClick={()=>this.handleSort(Constants.LabelTableFields.ShipDate)}>
            Ship Date{this.getSortIcon(Constants.LabelTableFields.ShipDate)}
          </th>
        </tr>
      </thead>
    );
  }

  renderBody = () => {
    const displayRows = this.state.displayRows;//.filter(r => LabelUtils.labelSearch(r, this.props.searchTerm))
    return (
      <tbody>
        {displayRows.map((data: any, i: number) => this.renderRow(data, i))}
      </tbody>
    );
  }

  renderRow = (data: any, rowNum: number) => {
    let localDate = moment(data.LogTime).toLocaleString();
    let createDate = moment(localDate).format('YYYY-MM-DD');
    let weight = LabelUtils.getProperty.numeric(data.PayLoad.PACKAGES, 'WEIGHT');
    let trackingNumbers: any[] = LabelUtils.getProperty.trackingNo(data.ServiceResponse);
    return (
      <tr key={rowNum} className={data.isSelected ? 'selected' : ''}>
        <td className={`faicon ${data.isVoided ? 'dis' : ''}`} style={{ color: data.isSelected ? 'white' : data.isVoided ? 'grey' : '#D93A2F' }}>
          {
            data.isSelected
              ? <FaCheckSquare onClick={() => { if (!data.isVoided) { this.selectSingleRow(rowNum) } }} />
              : <FaRegSquare onClick={() => { if (!data.isVoided) { this.selectSingleRow(rowNum) } }} />
          }
        </td>
        <td style={{ borderRight: '1px solid #000000', borderLeft: '1px solid #000000' }}>
          <div className='actions'>
            <div className={`faicon ${data.isVoided ? 'dis' : ''}`} style={{ color: data.isSelected ? 'white' : data.isVoided ? 'grey' : '#D93A2F' }}>
              {
                data.isProcessing
                  ? <Loader type="TailSpin" color="#D93A2F" height={15} width={15} />
                  : <FaPrint title='Print' onClick={() => { if (!data.isVoided) { this.printLabel(data, rowNum) } }} />
              }
            </div>
            <div className="riicon" style={{ color: data.isSelected ? 'white' : '#D93A2F' }}>
              <RiPhoneFindLine title='Details' onClick={() => this.openLabelDialog(data)} />
            </div>
            <div className={`faicon ${data.isVoided ? 'dis' : ''}`} style={{ color: data.isSelected ? 'white' : data.isVoided ? 'grey' : '#D93A2F' }}>
              <FaTimesCircle title='Void' onClick={() => { if (!data.isVoided) { this.openVoidLabelDialog(data) } }} />
            </div>
          </div>
        </td>
        <td>{data.ApplicationId}</td>
        <td>
          <div style={{ display: 'flex', alignItems: 'center', color: data.isSelected ? 'white' : data.isVoided ? '#D93A2F' : 'green' }}>
            {data.isVoided ? <FaTimes className='faicon' /> : <FaCheck className='faicon' />}
            {data.Status}
          </div>
        </td>
        <td>{weight} lb</td>
        <td>${data.ServiceResponse.TOTAL}</td>
        <td>{data.Store}</td>
        <td>{data.ServiceResponse.SHIPPER_SHIPMENT_REFERENCE}</td>
        <td className="carrierIcon">{this.selectCarrierIcon(data.Carrier)}</td>
        <td>{data.Service}</td>
        <td>{trackingNumbers.map((data: any, i: number) => <div key={i}>{data}</div>)}</td>
        <td>{createDate}</td>
        <td>{data.ShipDate}</td>
      </tr>
    )
  }
}
