import React from 'react';
import Loader from "react-loader-spinner";
import './ReturnLabels.scss';
import moment from 'moment';

import Utils from '../../Common/Utils';
import LabelUtils from '../../Common/Label.Utils';
import PrintManager from '../../Managers/Print.Manager';
import IdentityManager from '../../Managers/Identity.manager';
import PFManager from '../../Managers/Process.Fulfillment.Manager';

import ReturnLabelFilter from './ReturnLabelFilter';

import { Button, Divider } from '@material-ui/core';

import { RiPhoneFindLine } from "react-icons/ri";
import ClearIcon from '@material-ui/icons/Clear';
import SecurityIcon from '@material-ui/icons/Security';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { FaPrint, FaRegSquare, FaTimesCircle, FaCheckSquare, FaEraser, FaUps, FaFedex, FaUsps, FaCheck, FaTimes } from "react-icons/fa";
import Constants from '../../Common/Constants';
import TablePagination from '@material-ui/core/TablePagination';
import PFServicesManager from '../../Managers/Fulfillment.Services.Manager';

export default class ReturnLabelTable extends React.Component<any, {}> {

  public state = {
    labelRows: [],
    displayRows: [],
    hasError: false,
    intialLoad: false,
    dataLoaded: false,
    isExpanded: false,
    selectState: false,
    carrierFilterData: [],
    dateFilter: 'Last 30 Days',
    to: Utils.currentDate,
    from: Utils.dateReducedByDay,
    status: 'All',
    appFilterData: { list: [], selected: [] },
    selectedFilterData: { carrier: '', services: [] },
    pagination: Constants.LABELS.PAGINATION.DEFAULT,
  }

  constructor(props: any) {
    super(props);

    this.loadData = this.loadData.bind(this);
    this.changeState = this.changeState.bind(this);
    this.selectResetAll = this.selectResetAll.bind(this);
  }

  componentDidMount = async () => {
    await this.loadData('Last 30 Days', this.state.to, this.state.from);
    this.setState({ intialLoad: true });
    this.paginationScrollFix();
  }

  changeState = (data: any) => this.setState(data);

  loadData = async (dateFilter: string, to: any, from: any) => {
    this.setState({ pagination: Constants.LABELS.PAGINATION.DEFAULT, status: 'All', dataLoaded: false, appFilterData: { list: [], selected: [] }, carrierFilterData: [], selectedFilterData: { carrier: '', services: [] } });
    let labelRows: any[] = [];
    let [date_To, date_From] = LabelUtils.getDateForLabelSearch(dateFilter, to, from)
    let storeNumber = IdentityManager.isAdmin ? '' : IdentityManager.storeNumber;
    let result: any = await PFManager.getReturnLabels(storeNumber, date_To, date_From);
    let hasError = false;
    if (result.status !== 500 && result.status !== 400) {
      labelRows = LabelUtils.parseData_ReturnLabelTable(result.data)
    } else {
      hasError = true;
      console.log('Error', result);
      this.props.changeState({ dialogText: { Title: 'Error', Msg: result.details.message }, openDialog: true });
    }
    let [carrierFilterData, appFilterData] = LabelUtils.getFilterData(labelRows);

    // if (labelRows.length === 0) { labelRows.push(JSON.parse(Constants.data));}

    this.setState({ carrierFilterData, labelRows, displayRows: labelRows, appFilterData, hasError, dataLoaded: true });
    this.props.changeState({ dataLoaded: true });
  }

  openLabelDialog = (ServiceResponse: any) => this.props.changeState({ labelDialog: true, selectedLabel: ServiceResponse })

  openVoidLabelDialog = (ServiceResponse: any) => this.props.changeState({ voidLabelDialog: true, selectedLabel: ServiceResponse })

  printLabel = async (data: any, rowNum: number) => {
    let displayRows: any[] = this.state.displayRows;
    displayRows[rowNum].isProcessing = true;
    this.setState({ displayRows });
    let _result: any = null;
    //Use Shipping API or Lambda for PF Services
    if(PFManager.shippingAPI){
        _result = await PFServicesManager.rePrint(data.ServiceResponse.SUID, data.Store);
    }
    else{
        _result = await PFManager.rePrint(data.ServiceResponse.SUID, data.Store);
    }
    if (_result.response.data !== null) {
      await PrintManager.handlePrintDataDisplay(_result.response.printData);
      _result.response.printData.forEach((r: any) => PrintManager.sendPrinterRequest(r));
    } else {
      this.props.changeState({ dialogText: { Title: 'Error', Msg: _result.response.msg }, openDialog: true });
    }
    displayRows[rowNum].isProcessing = false;
    this.setState({ displayRows });
  }

  selectAllRow = () => {
    const [selectState, bulkLabels] = LabelUtils.rowSelect_all(this.state.selectState, this.state.displayRows);
    this.setState({ selectState });
    this.props.changeState({ bulkProcessLabels: bulkLabels });
  }

  selectSingleRow = (index: number) => {
    const [selectState, bulkLabels] = LabelUtils.rowSelect_single(index, this.state.displayRows);
    { this.setState({ selectState }) };
    this.props.changeState({ bulkProcessLabels: bulkLabels });
  }

  selectResetAll = () => {
    const [selectState, bulkLabels] = LabelUtils.rowSelect_resetAll(this.state.displayRows);
    { this.setState({ selectState }) };
    this.props.changeState({ bulkProcessLabels: bulkLabels });
  }

  selectCarrierIcon = (carrier: string) => {
    let _carrier = carrier.toLowerCase();
    switch (_carrier) {
      case 'ups':
        return <FaUps title="UPS" />;
      case 'usps':
        return <FaUsps title="USPS" />;
      case 'fedex':
        return <FaFedex title="FedEx" />;
      default:
        return <SecurityIcon />;
    }
  }

  // RENDER METHODS

  handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,) => {
    let pageData = { ...this.state.pagination };
    pageData.page = newPage + 1;
    console.log(pageData.page, "handleChangeOld");
    this.setState({ pagination: pageData });

  }

  handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {

    let pageData = { ...this.state.pagination };
    pageData.size = parseInt(event.target.value);
    console.log(pageData.size, "pagination.size");
    this.setState({ pagination: pageData });
  };
  getPaginationValues = () => {
    const displayRows = this.state.displayRows.filter(r => LabelUtils.returnLabelSearch(r, this.props.searchTerm));
    const dataCount = displayRows.length;
    let from = (this.state.pagination.page - 1) * this.state.pagination.size;
    let to = from + this.state.pagination.size;
    if (to > dataCount) { to = dataCount }

    let pageCount = Math.ceil(dataCount / this.state.pagination.size);
    let pages = [];
    for (let i = 0; i < pageCount; i++) {
      pages.push(i + 1);
    }

    let pageStart = from + 1;
    let currentPage = Math.ceil(pageStart / this.state.pagination.size);

    return [to, pages, pageCount, currentPage, dataCount, this.state.pagination.size];
  }

  buildPagedData = (listArray: any[]) => {
    const [to, pages, pageCount, currentPage, count, size] = this.getPaginationValues();
    const trimStart = (+currentPage - 1) * +size;
    const trimEnd = trimStart + (+size);
    return listArray.slice(trimStart, trimEnd);
  }
  paginationScrollFix = () => {
    var startProductBarPos = -1;
    window.onscroll = function () {
      var bar = document.getElementById('paginationWebId') as any;
      var dataTable = document.getElementById('LabelDiv') as any;
      if (bar && dataTable) {
        var dataTableWidth = dataTable.offsetWidth;
        if (startProductBarPos < 0) startProductBarPos = findPosY(bar);

        if (window.pageYOffset > startProductBarPos && (window.pageYOffset - startProductBarPos > 60)) {
          bar.style.width = (dataTableWidth - (0.1 * dataTableWidth / 100)) + "px";
          bar.classList.add("fixedPagination");

        } else {
          bar.classList.remove("fixedPagination");
          bar.style.width = "";
          bar.style.marginTop = "";
        }
      }
    };



    function findPosY(obj: any) {
      var curtop = 0;
      if (obj && typeof (obj.offsetParent) != 'undefined' && obj.offsetParent) {
        while (obj.offsetParent) {
          curtop += obj.offsetTop;
          obj = obj.offsetParent;
        }
        curtop += obj.offsetTop;
      }
      else if (obj && obj.y)
        curtop += obj.y;
      return curtop;
    }
  }

  render = () => {
    //;
    const base: string = (Utils.displayHeight - 70) + 'px';
    const height: string = (Utils.displayHeight * 0.98 - 201) + 'px';
    const margin: string = (0.5 * (Utils.displayHeight * 0.98 - 201)) + 'px';
    const [to, pages, pageCount, currentPage, count, size] = this.getPaginationValues();
    return (
      <div className='LabelDiv' id='LabelDiv'>
        {
          this.state.intialLoad &&
          <ReturnLabelFilter
            to={this.state.to}
            from={this.state.from}
            loadData={this.loadData}
            status={this.state.status}
            changeState={this.changeState}
            labelRows={this.state.labelRows}
            dateFilter={this.state.dateFilter}
            selectResetAll={this.selectResetAll}
            appFilterData={this.state.appFilterData}
            carrierFilterData={this.state.carrierFilterData}
            selectedFilterData={this.state.selectedFilterData}
          />
        }

        {
          this.state.dataLoaded
            ?
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#D93A2F' }}>

              < div id='paginationWebId' className="paginationWeb" style={{ background: "#D93A2F", display: "flex" }
              }>

                <div style={{ paddingTop: "10px" }}>

                  <TablePagination
                    component="div"
                    count={+count}
                    page={+currentPage - 1}
                    onPageChange={this.handleChangePage}
                    rowsPerPage={+size}
                    onRowsPerPageChange={this.handleChangeRowsPerPage}
                  />

                </div>
              </div >
            </div> : ""
        }


        <div className="LabelTableContainer">
          {
            this.state.dataLoaded
              ?
              this.state.labelRows.length === 0
                ?
                <div style={{ color: '#D93A2F', margin: '20px 0 0 0' }}>
                  <div><ClearIcon style={{ fontSize: '100px' }} /></div>
                  <h1 style={{ margin: '10px 0' }}>No Labels Found</h1>
                </div>
                :
                <table className="LabelTable">
                  {this.renderHeader()}
                  {this.renderBody()}
                </table>
              :
              <div style={{ marginTop: margin }}>
                <Loader type="TailSpin" color="#D93A2F" height={80} width={80} />
              </div>
          }
        </div>
      </div>
    );
  }

  renderHeader = () => {
    return (
      <thead>
        <tr>
          <th className="faicon">
            {
              this.state.selectState
                ? <FaCheckSquare onClick={() => { this.selectAllRow() }} />
                : <FaRegSquare onClick={() => { this.selectAllRow() }} />
            }
          </th>
          <th style={{ width: '70px' }}>Actions</th>
          <th>Status</th>
          <th>Weight</th>
          <th>Cost</th>
          <th>Store</th>
          <th>Shipper Reference</th>
          <th>Carrier</th>
          <th>Customer</th>
          <th>Tracking Number</th>
          <th>Created Date</th>
        </tr>
      </thead>
    );
  }

  renderBody = () => {
    const displayRows = this.state.displayRows.filter(r => LabelUtils.returnLabelSearch(r, this.props.searchTerm));
    const pagedData = this.buildPagedData(displayRows);
    console.log(pagedData, "displayRowsdisplayRows");
    return (
      <tbody>
        {pagedData.map((data: any, i: number) => this.renderRow(data, i))}
      </tbody>
    );
  }

  renderRow = (data: any, rowNum: number) => {
    let localDate = moment(data.LogTime).toLocaleString();
    let createDate = moment(localDate).format('YYYY-MM-DD');
    let weight = LabelUtils.getProperty.numeric(data.PayLoad.PACKAGES, 'WEIGHT');
    let trackingNumbers: any[] = LabelUtils.getProperty.trackingNo(data.ServiceResponse);
    return (
      <tr key={rowNum} className={data.isSelected ? 'selected' : ''}>
        <td className={`faicon ${data.isVoided ? 'dis' : ''}`} style={{ color: data.isSelected ? 'white' : data.isVoided ? 'grey' : '#D93A2F' }}>
          {
            data.isSelected
              ? <FaCheckSquare onClick={() => { if (!data.isVoided) { this.selectSingleRow(rowNum) } }} />
              : <FaRegSquare onClick={() => { if (!data.isVoided) { this.selectSingleRow(rowNum) } }} />
          }
        </td>
        <td style={{ borderRight: '1px solid #000000', borderLeft: '1px solid #000000' }}>
          <div className='actions'>
            <div className={`faicon ${data.isVoided ? 'dis' : ''}`} style={{ color: data.isSelected ? 'white' : data.isVoided ? 'grey' : '#D93A2F' }}>
              {
                data.isProcessing
                  ? <Loader type="TailSpin" color="#D93A2F" height={15} width={15} />
                  : <FaPrint title='Print' onClick={() => { if (!data.isVoided) { this.printLabel(data, rowNum) } }} />
              }
            </div>
            <div className="riicon" style={{ color: data.isSelected ? 'white' : '#D93A2F' }}>
              <RiPhoneFindLine title='Details' onClick={() => this.openLabelDialog(data)} />
            </div>
            <div className={`faicon ${data.isVoided ? 'dis' : ''}`} style={{ color: data.isSelected ? 'white' : data.isVoided ? 'grey' : '#D93A2F' }}>
              <FaTimesCircle title='Void' onClick={() => { if (!data.isVoided) { this.openVoidLabelDialog(data) } }} />
            </div>
          </div>
        </td>
        <td>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: data.isSelected ? 'white' : data.isVoided ? '#D93A2F' : 'green' }}>
            {data.isVoided ? <FaTimes className='faicon' /> : <FaCheck className='faicon' />}
            {data.Status}
          </div>
        </td>
        <td>{weight} lb</td>
        <td>${data.ServiceResponse.TOTAL}</td>
        <td>{data.Store}</td>
        <td>{data.ServiceResponse.SHIPPER_SHIPMENT_REFERENCE}</td>
        <td className="carrierIcon">{this.selectCarrierIcon(data.Carrier)}</td>
        <td>{data.PayLoad.RETURN_CONTACT}</td>
        <td>{trackingNumbers.map((data: any, i: number) => <div>{data}</div>)}</td>
        <td>{createDate}</td>
      </tr>
    )
  }

  renderPagination = () => {
    return (
      <div>
        <Button color='primary' variant='contained'><ChevronLeftIcon /></Button>
        <Button color='primary' variant='contained'><ChevronRightIcon /></Button>
      </div>
    )
  }
}
