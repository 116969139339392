import React from 'react';
import './ReturnLabels.scss';
import Utils from '../../Common/Utils';
import DatePicker from '../Shared/DatePicker';
import LabelUtils from '../../Common/Label.Utils';

import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';
import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import { Divider, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FilterListIcon from '@material-ui/icons/FilterList';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        chip: {
            fontSize: '15px',
            margin: theme.spacing(0.4),
            borderRadius: '2px !important'
        },
    }),
);

const CustomAccordionSummary = withStyles({
    root: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#CECECE',
        border: '1px solid #D93A2F',
    }
})(AccordionSummary);

const CustomAccordionDetails = withStyles((theme) => ({
    root: {
        borderTop: '0',
        padding: '10px 2px',
        border: '1px solid #D93A2F',
    },
}))(AccordionDetails);

export default function ReturnLabelFilter(props: any): any {
    const classes = useStyles();

    const timeSelection: any[] = ['Last 7 Days', 'Last 30 Days', 'Custom'];

    const statusSelection: any[] = ['All', 'Active', 'Voided'];

    let apps: any[] = props.appFilterData.list;
    let carrier: any[] = props.carrierFilterData.map((r: any) => r.carrier);
    let services: any[] = [];

    if (props.selectedFilterData.carrier !== '') {
        services = props.carrierFilterData.find((r: any) => props.selectedFilterData.carrier == r.carrier).services;
    }

    // CARRIER FILTER METHOD

    const carrierChange = (carrier: any) => () => {
        let selectedFilterData = { services: [], carrier: props.selectedFilterData.carrier === carrier ? '' : carrier };
        let displayRows = LabelUtils.filterLabelData(props.status, props.labelRows, selectedFilterData, props.appFilterData.selected);
        props.changeState({ selectedFilterData, displayRows });
        props.selectResetAll();
    };

    // SERVICE FILTER METHOD

    const serviceChange = (service: any) => () => {
        let carrier: string = props.selectedFilterData.carrier;
        let services: any[] = props.selectedFilterData.services
        if (services.indexOf(service) !== -1) {
            services.splice(services.indexOf(service), 1);
        } else {
            services.push(service);
        }
        let selectedFilterData = { services: services, carrier: carrier };
        let displayRows = LabelUtils.filterLabelData(props.status, props.labelRows, selectedFilterData, props.appFilterData.selected);
        props.changeState({ selectedFilterData, displayRows });
        props.selectResetAll();
    };

    // APPLICATION FILTER METHOD

    const appChange = (app: any) => () => {
        let list = props.appFilterData.list;
        let selected = props.appFilterData.selected;

        if (selected.indexOf(app) !== -1) {
            selected.splice(selected.indexOf(app), 1);
        } else {
            selected.push(app);
        }
        let appFilterData = { list: list, selected: selected };
        let displayRows = LabelUtils.filterLabelData(props.status, props.labelRows, props.selectedFilterData, appFilterData.selected);
        props.changeState({ appFilterData, displayRows });
        props.selectResetAll();
    };

    // STATUS SELECTION

    const statusSelectionChange = (status: any) => () => {
        if (props.status !== status) {
            let displayRows = LabelUtils.filterLabelData(status, props.labelRows, props.selectedFilterData, props.appFilterData.selected);
            props.changeState({ status, displayRows });
            props.selectResetAll();
        }
    }

    // TIME FILTER METHODS

    const timeSelectionChange = (dateFilter: any) => () => {
        if (props.dateFilter !== dateFilter) {
            props.changeState({ dateFilter: dateFilter });
            props.loadData(dateFilter, props.to, props.from);
        }
    }

    const handleDateChange_From = (date: Date) => {
        if (Utils.startDate.getTime() >= date.getTime()) {
            props.changeState({ from: date });
            props.loadData(props.dateFilter, props.to, date);
        }
    }

    const handleDateChange_To = (date: Date) => {
        if (Utils.startDate.getTime() >= date.getTime()) {
            props.changeState({ to: date });
            props.loadData(props.dateFilter, date, props.from);
        }
    }

    const [expanded, setExpanded] = React.useState<boolean>(true);

    const handleChange = (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
        setExpanded(isExpanded);
        props.changeState({ isExpanded: isExpanded });
    };

    return (
        <div style={{ width: '100%', display: 'flex', margin: '5px 0', justifyContent: 'space-between' }}>
            {/* <Paper className={classes.root}> */}

            <Accordion expanded={expanded} onChange={handleChange} className={classes.root}>
                <CustomAccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <FilterListIcon /> <b>Filters</b>
                    </div>
                </CustomAccordionSummary>
                <CustomAccordionDetails>
                    <div className='labelFilters'>
                        <div className='labelFilterSection' style={{ maxWidth: 'inherit', minWidth: '300px' }}>
                            <div className='Title'>Period</div>
                            <div>
                                {timeSelection.map((data: any) =>
                                    <Chip
                                        clickable
                                        key={data}
                                        label={data}
                                        color="primary"
                                        className={classes.chip}
                                        onClick={timeSelectionChange(data)}
                                        variant={props.dateFilter === data ? 'default' : 'outlined'}
                                    />
                                )}
                            </div>
                            {
                                props.dateFilter === 'Custom' &&
                                <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                                    <DatePicker currentDate={props.from} setDate={handleDateChange_From} style={{ width: '135px' }} />
                                    <span style={{ margin: '0px 10px 10px 10px' }}>To</span>
                                    <DatePicker currentDate={props.to} setDate={handleDateChange_To} style={{ width: '135px' }} />
                                </div>
                            }
                        </div>

                        <Divider orientation="vertical" flexItem />

                        <div className='statusFilterSection' style={{ maxWidth: 'inherit', minWidth: '300px' }}>
                            <div className='Title'>Status</div>
                            <div>
                                {statusSelection.map((data: any) =>
                                    <Chip
                                        clickable
                                        key={data}
                                        label={data}
                                        color="primary"
                                        className={classes.chip}
                                        onClick={statusSelectionChange(data)}
                                        variant={props.status === data ? 'default' : 'outlined'}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </CustomAccordionDetails>
            </Accordion>

            {/* </Paper> */}
        </div>
    );
}