import React from 'react';
import '../CreateLabel.scss';
import Utils from '../../../Common/Utils';
import Constants from '../../../Common/Constants';
import LabelUtils from '../../../Common/Label.Utils';
import StoreManager from '../../../Managers/Store.manager';
import Loader from "react-loader-spinner";

import DatePicker from '../../Shared/DatePicker';

import { Select, MenuItem, TextField, Divider, Button, Radio, Input, InputAdornment, IconButton, Tooltip, FormControlLabel, Checkbox } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PreviousInfo from '../../Shared/PreviousInfo';
import IdentityManager from '../../../Managers/Identity.manager';
import AddressBookManager from '../../../Managers/AddressBook.Manager';
import CustomerDetails from '../CustomerDetails';
import SearchIcon from '@material-ui/icons/Search';
import ErrorIcon from '@material-ui/icons/Error';
import { constants } from 'buffer';
import PFManager from '../../../Managers/Process.Fulfillment.Manager';

export default class ShippingInformation extends React.Component<any, {}> {

    public state = {
        storeType: Constants.gcStoreType,
        isDC: false,
        customer: '',
        spoOrder: '',
        currentStore: { id: '', name: '' },
        spoOrderNames: [],
        customerNames: [],
        selectedStore: { id: '', name: '' },
        isResidential: false,
        nextStepAttempt: false,
        currentDate: Utils.ShipDate,
        DCList: StoreManager.DCList,
        storeList: StoreManager.storeList,
        shipToDetails: Constants.SHIP_DETAILS,
        shipFromDetails: Constants.SHIP_DETAILS,
    }

    constructor(props: any) {
        super(props);

        this.changeStep = this.changeStep.bind(this);
        this.storeChange = this.storeChange.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
    }

    componentDidMount = async () => {

        let customer = this.props.customer;
        let currentStore = this.props.currentStore;
        let selectedStore = this.props.selectedStore;
        let shipToDetails = this.props.shipToDetails;
        let isResidential = this.props.isResidential;
        let spoOrder = this.props.shipperRef;
        // let shipFromDetails = this.props.shipFromDetails;
        let shipFromDetails = Object.assign({}, this.props.shipFromDetails);
        console.log(shipFromDetails, "shipFromDetailsshipFromDetails");
        this.setState({ currentStore, selectedStore, shipToDetails, isResidential, shipFromDetails, customer, spoOrder });

        if (StoreManager.storeList.length === 0) {
            await StoreManager.getAllSCStores();
            await StoreManager.getDCList();
            this.setState({ storeList: StoreManager.storeList, DCList: StoreManager.DCList }, () => {
                this.setUserStore();
            });
        }
        else {
            this.setUserStore();
        }
    }

    setUserStore = () => {
        let storeNumber = IdentityManager.storeNumber;
        let _result: any = Constants.SHIP_DETAILS;
        let currentStore = StoreManager.currentStore;
        let store = { id: '', name: '' };
        if (storeNumber !== '') {
            store = StoreManager.storeList.find(r => r.id === storeNumber);
            if (store !== undefined) {
                if (currentStore.id !== undefined) {
                    let res = StoreManager.allStoredata.find(r => r.StoreId.toString() === IdentityManager.storeNumber)
                    if (res !== undefined && res !== null) {
                        StoreManager.currentStore = res;
                        _result = res;
                    }
                } else {
                    _result = currentStore;
                }
            }
        }
        if (this.props.formType === 'ReturnLabel') {
            this.setState({ shipToDetails: _result, selectedStore: store });
            this.changeReturnLabelData(_result.StoreEmail, 'store_email');
            this.getDefaultReturnLabelCarrierService(store.id);
        } else {
            this.setState({ shipFromDetails: _result, currentStore: store });
        }

    }


    getDefaultReturnLabelCarrierService = (store: any) => {

        let data: any = null;

        let prefix = StoreManager.getSoreShipperPreFixByStoreId(store);

        if (prefix == "M") {
            data = PFManager.unGroupedCarrierServiceData.filter(a => a.DefaultReturn != null && a.DefaultReturn != undefined && a.DefaultReturn.includes(Constants.mmaStoreType));
        }
        else {
            data = PFManager.unGroupedCarrierServiceData.filter(a => a.DefaultReturn != null && a.DefaultReturn != undefined && a.DefaultReturn.includes(Constants.gcStoreType));
        }

        if (data == null) {
            data = PFManager.unGroupedCarrierServiceData.filter(a => a.Carrie == "UPS" && a.Service == "ECONOMY_GROUND");
        }

        if (data && data.length > 0) {
            data = data[0];
        }
        let carrierServiceData = PFManager.carrierServiceData;
        let _carrier = carrierServiceData.findIndex((r: any) => r.Title === data.Carrie);
        let _service = carrierServiceData[_carrier].Services.findIndex((r: any) => r.service === data.Service);
        let carrier = data.Carrie.toString();
        let service = data.Service.toString();
        console.log(carrier + "-" + service, "getDefaultReturnLabelCarrierService");
        this.props.changeState({ carrier, service });
        return { carrier, service };

    }

    toggleIsDC = (event: React.ChangeEvent<HTMLInputElement>) => {
        console.log(event.target.value, "event.target.value");
        let isDC = event.target.value.toString() == Constants.distributionCentersStores ? true : false; // !this.props.isDC;
        let storeType = event.target.value;
        this.setState({ selectedStore: { id: '', name: '' }, shipToDetails: Constants.SHIP_DETAILS, storeType });
        this.props.changeState({ isDC, storeType });
    }


    toggleIsSPO = () => {
        let isSPO = !this.props.isSPO;
        if (this.props.formType !== 'ReturnLabel') {
            this.setState({ selectedStore: { id: '', name: '' }, shipToDetails: Constants.SHIP_DETAILS });
        }
        this.props.changeState({ isSPO });
    }

    handleDateChange = (date: Date) => {
        if (Utils.startDate.getTime() <= date.getTime()) {
            this.setState({ currentDate: date });
            this.props.changeState({ shipDate: date });
        }
    }

    storeChange = async (e: any, selection: string, property: string, isDC: boolean) => {
        
        if (isDC) {
            let data = StoreManager.DCList.find((r: any) => r.StoreID === e.target.value.id)
            this.setState({ [selection]: e.target.value, [property]: data });
        } else {
            if (e.target.value !== null) {
                this.setState({ [selection]: e.target.value });
                let _result: any = StoreManager.getStoreDetails_local(e.target.value.id);//await StoreManager.getStoreDetails(e.target.value.id);
                this.setState({ [property]: _result });
                if (this.props.formType === 'ReturnLabel') {
                    this.changeReturnLabelData(_result.StoreEmail, 'store_email');
                }
            }
        }
    }

    stateChange = async (e: any, selection: string, property: string) => {
        let _obj: any = { 'shipToDetails': this.state.shipToDetails, 'shipFromDetails': this.state.shipFromDetails }
        let _selection = Object.assign({}, _obj[selection]);
        _selection[property] = e.target.value;
        this.setState(_obj);
    }

    changeReturnLabelData = (value: any, property: string) => {
        let returnLabelData = this.props.returnLabelData;
        returnLabelData[property] = value;
        this.props.changeState({ returnLabelData });
    }

    changeConsigneeCompany = (value: any) => this.props.changeState({ consigneeCompany: value })

    changeText = (e: any, selection: string, property: string) => {
        if (e.target.value !== '') {
            if (property === 'Fax' || property === 'Phone') {
                if (!LabelUtils.validatePhoneEntry(e.target.value)) {
                    return
                }
            }
        }

        let _obj: any = { 'shipToDetails': this.state.shipToDetails, 'shipFromDetails': this.state.shipFromDetails }
        let _selection = Object.assign({}, _obj[selection]);
        _selection[property] = e.target.value;
        _obj[selection] = _selection;
        this.setState(_obj);
    }

    changeStep = (step: number) => {
        
        this.setState({ nextStepAttempt: true });
        let vData: any = {
            customer: this.state.customer,
            shipToDetails: this.state.shipToDetails,
            shipFromDetails: this.state.shipFromDetails,
            currentStore: this.state.currentStore,
            selectedStore: this.state.selectedStore,
        }
        let returnLabelData = LabelUtils.getFullData(this.props.returnLabelData);
        //&& returnLabelData.store_email !== '' && returnLabelData.store_email.includes('@')
        let rtLabel = this.props.formType === 'ReturnLabel'
            ? returnLabelData.customer_email !== '' && returnLabelData.customer_email.includes('@')
            : true
        if (LabelUtils.validateStep1(vData, step, this.props.formType) && rtLabel) {
            let data: any = {};
            data['currentStore'] = this.state.currentStore;
            data['shipToDetails'] = Object.assign({}, this.state.shipToDetails);
            data['shipFromDetails'] = Object.assign({}, this.state.shipFromDetails);
            data['shipperRef'] = this.state.spoOrder;

            if (this.props.formType === 'Customer/Vendor') { data['customer'] = this.state.customer; }
            else if (this.props.formType === 'ReturnLabel') {
                data['customer'] = this.state.customer;
                data['selectedStore'] = this.state.selectedStore;
            }
            else { data['selectedStore'] = this.state.selectedStore; }

            data['storeType'] = this.state.storeType;
            this.props.changeState(data);
            this.props.changeActiveStep(step);
        }
        else {
            if (vData.currentStore.id === vData.selectedStore.id) {
                this.setState({ selectedStore: { id: '', name: '' } });
                this.setState({ shipToDetails: Constants.SHIP_DETAILS, });
                if (this.props.formType === 'ReturnLabel') {
                    this.changeReturnLabelData("", 'store_email');
                }
            }
        }
    }

    checkStores = () => this.state.currentStore.id !== this.state.selectedStore.id && this.state.currentStore.name !== this.state.selectedStore.name

    handleTextChange = (e: any) => this.setState({ customer: e.target.value })

    customerClick = (data: any) => {
        if (this.props.formType === 'ReturnLabel') {
            this.setState({ shipFromDetails: data.storeInfo, customer: data.name, customerNames: [], spoOrderNames: [] });
            this.changeReturnLabelData(data.email, 'customer_email');
        } else {
            this.setState({ shipToDetails: data.storeInfo, customer: data.name, customerNames: [], spoOrderNames: [] });
        }
    }

    handleCustomerChange = async (e: any) => {
       
        this.setState({ customer: e.target.value });
        if (e.target.value.length > 2) {
            let customerNames = await AddressBookManager.getCustomer(IdentityManager.storeNumber, e.target.value);
            this.setState({ customerNames });
        } else {
            this.setState({ customerNames: [] });
        }
    }

    handleSPOOrderChange = async (e: any) => this.setState({ spoOrder: e.target.value })

    searchSPOOrders = async () => {
        let spoOrderNames = await AddressBookManager.searchSPOOrders(this.state.spoOrder);
        this.setState({ spoOrderNames });
    }


    validateInput = (dataSet: string, property: string) => {
        let state: any = this.state
        if (this.state.nextStepAttempt) {
            let data = dataSet === '' ? state : state[dataSet];
            if (data === undefined) { data = this.props[dataSet]; }
            return LabelUtils.ShipInfo_Validate(data, property);
        } else {
            return false;
        }
    }
    // RENDER METHODS

    render = () => {
        return (
            <div className='shippingInfo'>
                <PreviousInfo
                    customer={undefined}
                    currentStore={undefined}
                    selectedStore={undefined}
                    isResidential={undefined}
                    formType={this.props.formType}
                />
                <div className='innerDiv'>
                    {this.renderShipper()}
                    {!Utils.isMobile && <Divider orientation="vertical" />}
                    {this.renderStoreInfo()}
                </div>
                {this.renderShiftButtons()}
            </div>
        );
    }

    req = () => <span style={{ color: 'red' }}>*</span>

    renderShiftButtons = () => {
        return (
            <div className='shiftButtons'>
                {
                    this.props.formType !== 'ReturnLabel' &&
                    <Button variant="contained" onClick={() => { this.changeStep(0) }}>Back</Button>
                }
                <Button variant="contained" color="primary" style={{ marginLeft: '10px' }} onClick={() => { this.changeStep(2) }}>Next</Button>
            </div>
        );
    }

    renderShipper = () => {
        let width = LabelUtils.getFieldWidth();
        let spoWidth = LabelUtils.getElementWidth('spoOrders');
        let disable = this.props.formType === 'ReturnLabel' ? false : IdentityManager.storeNumber !== '' && !IdentityManager.isAdmin;
        let shipFromDetails = this.state.shipFromDetails.StoreID === 0 ? this.props.shipFromDetails : this.state.shipFromDetails;
        return (
            <div className='column'>
                <div className='header' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <b>Ship-From Details</b>
                    {this.props.formType === 'ReturnLabel' && this.renderRadioButtons_SPOOrder()}
                </div>
                {
                    this.props.formType === 'ReturnLabel' && this.props.isSPO &&
                    <div className='row'>
                        <div className='fieldInputFull' style={{ width: '75%' }} id='spoOrders'>
                            <Input
                                style={{ width: '100%' }}
                                value={this.state.spoOrder}
                                placeholder='Search Web Order i-e GCW123456789'
                                onChange={this.handleSPOOrderChange}
                                onKeyDown={(e) => { if (e.keyCode === 13) { this.searchSPOOrders() } }}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={this.searchSPOOrders}
                                        >
                                            <SearchIcon />
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                            {
                                this.state.spoOrderNames.length > 0 &&
                                <div className='names' style={{ width: spoWidth }}>
                                    {
                                        this.state.spoOrderNames.map((data: any, i: number) =>
                                            <CustomerDetails data={data} key={i} customerClick={this.customerClick} />)
                                    }
                                </div>
                            }
                        </div>
                        <div>
                            <Tooltip arrow
                                title={
                                    <div style={{ fontSize: '16px', padding: '5px', width: '270px', textAlign: 'center' }}>
                                        Enter Web Order to get customer list
                                    </div>}
                            >
                                <ErrorIcon style={{ color: 'green', marginLeft: '5px' }} />
                            </Tooltip>
                        </div>
                    </div>
                }

                {
                    this.props.formType === 'ReturnLabel'
                        ?
                        <div className='row'>
                            <div className='fieldText'>Customer Name: {this.req()}</div>
                            <div className='fieldInputFull'>
                                <TextField
                                    size="small"
                                    variant="outlined"
                                    style={{ width: '100%' }}
                                    value={this.state.customer}
                                    onChange={this.handleCustomerChange}
                                    error={this.validateInput('', 'customer')}
                                />
                                {
                                    this.state.customerNames.length > 0 &&
                                    <div className='names' style={{ width: width }}>
                                        {
                                            this.state.customerNames.map((data: any, i: number) =>
                                                <CustomerDetails data={data} key={i} customerClick={this.customerClick} />)
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                        :
                        <div className='row'>
                            <div className='fieldText'>Ship From:</div>
                            <Autocomplete
                                disabled={disable}
                                options={this.state.storeList}
                                value={this.state.currentStore }
                                getOptionLabel={(option: any) => {
                                    if (option.id !== '') { return `${option.id} - ${option.name}`; }
                                    else { return 'Select Store' }
                                }}
                                style={{ width: '50%', background: 'white', height: 'max-content' }}
                                onChange={(event: any, newValue: any) => {
                                    this.storeChange({ target: { value: newValue } }, 'currentStore', 'shipFromDetails', false)
                                }}
                                renderInput={(params: any) => <TextField {...params} margin="normal" />}
                            />
                        </div>
                }
                {
                    this.props.formType === 'ReturnLabel' &&
                    <div className='row'>
                        <div className='fieldText'>Customer Email {this.req()}</div>
                        <TextField
                            value={this.props.returnLabelData.customer_email}
                            className='fieldInputFull' variant="outlined" size="small"
                            error={this.validateInput('returnLabelData', 'customer_email')}
                            onChange={(e) => { this.changeReturnLabelData(e.target.value, 'customer_email') }}
                        />
                    </div>
                }
                {
                    this.props.formType === 'ReturnLabel' &&
                    <div className='row'>
                        <div className='fieldText'></div>
                        <FormControlLabel
                            label="Send email to Customer" className='fieldSelect'
                            control={<Checkbox color='primary' checked={this.props.returnLabelData.sendMail_customer} onChange={(e) => { this.changeReturnLabelData(e.target.checked, 'sendMail_customer') }} />}
                        />
                    </div>
                }
                {
                    this.props.formType !== 'ReturnLabel' &&
                    <div className='row'>
                        <div className='fieldText'>Ship Date: {this.req()}</div>
                        <DatePicker
                            style={{ width: '50%' }}
                            setDate={this.handleDateChange}
                            currentDate={this.state.currentDate}
                            shouldDisableDate={(date: Date | null) => {
                                if (date !== null) {
                                    return date.getDay() === 0 || date.getDay() === 6;
                                } else {
                                    return true;
                                }
                            }}
                        />
                    </div>
                }
                <div className='row'>
                    <div className='fieldText'>Address 1: {this.req()}</div>
                    <TextField error={this.validateInput('shipFromDetails', 'Address')} disabled={disable} onChange={(e) => { this.changeText(e, 'shipFromDetails', 'Address') }} value={this.state.shipFromDetails.Address} className='fieldInputFull' variant="outlined" size="small" />
                </div>
                <div className='row'>
                    <div className='fieldText'>Address 2:</div>
                    <TextField disabled={disable} onChange={(e) => { this.changeText(e, 'shipFromDetails', 'Address2') }} value={this.state.shipFromDetails.Address2} className='fieldInputFull' variant="outlined" size="small" />
                </div>
                <div className='row'>
                    <div className='fieldText'>City: {this.req()}</div>
                    <TextField error={this.validateInput('shipFromDetails', 'City')} disabled={disable} onChange={(e) => { this.changeText(e, 'shipFromDetails', 'City') }} value={this.state.shipFromDetails.City} className='fieldInputFull' variant="outlined" size="small" />
                </div>
                <div className='row'>
                    <div className='fieldText'>State: {this.req()}</div>
                    <Select
                        disabled={disable}
                        value={this.state.shipFromDetails.State}
                        error={this.validateInput('shipFromDetails', 'State')}
                        style={{ width: '50%', background: 'white', height: 'max-content' }}
                        onChange={(e) => { this.changeText(e, 'shipFromDetails', 'State') }}
                    >
                        {
                            Constants.STATES.map((data: any, i: number) =>
                                <MenuItem value={data.value} key={i}>{data.name}</MenuItem>)
                        }
                    </Select>
                </div>
                <div className='row'>
                    <div className='fieldText'>Postal Code: {this.req()}</div>
                    {/*  */}
                    <TextField error={this.validateInput('shipFromDetails', 'Zip')} onChange={(e) => { this.changeText(e, 'shipFromDetails', 'Zip') }} disabled={disable} value={this.state.shipFromDetails.Zip} className='fieldInputFull' variant="outlined" size="small" />
                </div>
                <div className='row'>
                    <TextField error={this.validateInput('shipFromDetails', 'Phone')} disabled={disable} onChange={(e) => { this.changeText(e, 'shipFromDetails', 'Phone') }} value={this.state.shipFromDetails.Phone} className='fieldInputHalf' label="Phone Number" variant="outlined" size="small" />
                    <TextField onChange={(e) => { this.changeText(e, 'shipFromDetails', 'Fax') }} disabled={disable} value={this.state.shipFromDetails.Fax} className='fieldInputHalf' label="Fax Number" variant="outlined" size="small" />
                </div>
            </div>
        );
    }

    renderStoreInfo = () => {
        let width = LabelUtils.getFieldWidth()
        let disable = this.props.formType === 'ReturnLabel' ? true : false;// IdentityManager.storeNumber !== '' && !IdentityManager.isAdmin; 
        let spoWidth = LabelUtils.getElementWidth('spoOrders')
        return (
            <div className='column'>
                <div className='header' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <b>Ship-To Details</b>
                    {
                        this.props.formType !== 'ReturnLabel'
                            ?
                            this.props.formType === 'Customer/Vendor'
                                ? this.renderRadioButtons_SPOOrder()
                                : this.renderRadioButtons_StoreDC()
                            : null
                    }
                </div>
                {
                    this.props.formType === 'Customer/Vendor' && this.props.isSPO &&
                    <div className='row'>
                        <div className='fieldInputFull' style={{ width: '75%' }} id='spoOrders'>
                            <Input
                                style={{ width: '100%' }}
                                value={this.state.spoOrder}
                                placeholder='Search Web Order i-e GCW123456789'
                                onChange={this.handleSPOOrderChange}
                                onKeyDown={(e) => { if (e.keyCode === 13) { this.searchSPOOrders() } }}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={this.searchSPOOrders}
                                        >
                                            <SearchIcon />
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                            {
                                this.state.spoOrderNames.length > 0 &&
                                <div className='names' style={{ width: spoWidth }}>
                                    {
                                        this.state.spoOrderNames.map((data: any, i: number) =>
                                            <CustomerDetails data={data} key={i} customerClick={this.customerClick} />)
                                    }
                                </div>
                            }
                        </div>
                        <div>
                            <Tooltip arrow
                                title={
                                    <div style={{ fontSize: '16px', padding: '5px', width: '270px', textAlign: 'center' }}>
                                        Enter Web Order to get customer list
                                    </div>}
                            >
                                <ErrorIcon style={{ color: 'green', marginLeft: '5px' }} />
                            </Tooltip>
                        </div>
                    </div>
                }

                {
                    this.props.formType === 'Customer/Vendor'
                        ?
                        <div className='row'>
                            <div className='fieldText'>Customer/Vendor: {this.req()}</div>
                            <div className='fieldInputFull'>
                                <TextField
                                    size="small"
                                    variant="outlined"
                                    style={{ width: '100%' }}
                                    value={this.state.customer}
                                    onChange={this.handleCustomerChange}
                                    error={this.validateInput('', 'customer')}
                                />
                                {
                                    this.state.customerNames.length > 0 &&
                                    <div className='names' style={{ width: width }}>
                                        {
                                            this.state.customerNames.map((data: any, i: number) =>
                                                <CustomerDetails data={data} key={i} customerClick={this.customerClick} />)
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                        :
                        <div className='row'>
                            <div className='fieldText'>Ship To:</div>
                            {this.renderShipTo_StoreDC()}
                        </div>
                }
                {
                    this.props.formType === 'Customer/Vendor' &&
                    <div className='row'>
                        <div className='fieldText'>Company Name/ Attn:</div>
                        <TextField value={this.props.consigneeCompany} className='fieldInputFull' variant="outlined" size="small" onChange={(e) => { this.changeConsigneeCompany(e.target.value) }} />
                    </div>
                }
                {
                    this.props.formType === 'ReturnLabel' &&
                    <div className='row'>
                        {/* {this.req()} */}
                        <div className='fieldText'>Store Email</div>
                        <TextField

                            value={this.props.returnLabelData.store_email}
                            className='fieldInputFull' variant="outlined" size="small"
                            //error={this.validateInput('returnLabelData', 'store_email')}
                            onChange={(e) => { this.changeReturnLabelData(e.target.value, 'store_email') }}
                        />
                    </div>
                }
                {
                    this.props.formType === 'ReturnLabel' &&
                    <div className='row'>
                        <div className='fieldText'></div>
                        <FormControlLabel
                            label="Send email copy to Store" className='fieldSelect'
                            control={<Checkbox color='primary' checked={this.props.returnLabelData.sendMail_store} onChange={(e) => { this.changeReturnLabelData(e.target.checked, 'sendMail_store') }} />}
                        />
                    </div>
                }
                <div className='row'>
                    <div className='fieldText'>Address 1: {this.req()}</div>
                    <TextField disabled={disable} error={this.validateInput('shipToDetails', 'Address')} onChange={(e) => { this.changeText(e, 'shipToDetails', 'Address') }} value={this.state.shipToDetails.Address} className='fieldInputFull' variant="outlined" size="small" />
                </div>
                <div className='row'>
                    <div className='fieldText'>Address 2:</div>
                    <TextField disabled={disable} onChange={(e) => { this.changeText(e, 'shipToDetails', 'Address2') }} value={this.state.shipToDetails.Address2} className='fieldInputFull' variant="outlined" size="small" />
                </div>
                <div className='row'>
                    <div className='fieldText'>City: {this.req()}</div>
                    <TextField disabled={disable} error={this.validateInput('shipToDetails', 'City')} onChange={(e) => { this.changeText(e, 'shipToDetails', 'City') }} value={this.state.shipToDetails.City} className='fieldInputFull' variant="outlined" size="small" />
                </div>
                <div className='row'>
                    <div className='fieldText'>State: {this.req()}</div>
                    <Select disabled={disable}
                        value={this.state.shipToDetails.State}
                        error={this.validateInput('shipToDetails', 'State')}
                        style={{ width: '50%', background: 'white', height: 'max-content' }}
                        onChange={(e) => { this.changeText(e, 'shipToDetails', 'State') }}
                    >
                        {
                            Constants.STATES.map((data: any, i: number) =>
                                <MenuItem value={data.value} key={i}>{data.name}</MenuItem>)
                        }
                    </Select>
                </div>
                <div className='row'>
                    <div className='fieldText'>Postal Code: {this.req()}</div>
                    <TextField disabled={disable} error={this.validateInput('shipToDetails', 'Zip')} onChange={(e) => { this.changeText(e, 'shipToDetails', 'Zip') }} value={this.state.shipToDetails.Zip} className='fieldInputFull' variant="outlined" size="small" />
                </div>
                <div className='row'>
                    {
                        this.props.formType === 'Customer/Vendor'
                            ?
                            <TextField error={this.validateInput('shipToDetails', 'Phone')} onChange={(e) => { this.changeText(e, 'shipToDetails', 'Phone') }} value={this.state.shipToDetails.Phone} className='fieldInputHalf' label="Phone Number" variant="outlined" size="small" />
                            :
                            <TextField disabled={disable} onChange={(e) => { this.changeText(e, 'shipToDetails', 'Phone') }} value={this.state.shipToDetails.Phone} className='fieldInputHalf' label="Phone Number" variant="outlined" size="small" />
                    }
                    <TextField disabled={disable} onChange={(e) => { this.changeText(e, 'shipToDetails', 'Fax') }} value={this.state.shipToDetails.Fax} className='fieldInputHalf' label="Fax Number" variant="outlined" size="small" />
                </div>
            </div>
        );
    }

    renderShipTo_StoreDC = () => {
        
        let isAdmin = IdentityManager.isAdmin
        let disable = this.props.formType === 'ReturnLabel' ? true && !isAdmin : false;
        let DCList = this.state.DCList.map((r: any) => ({ id: r.StoreID, name: r.Description }));

        let stores = [];
        if (this.props.storeType != Constants.distributionCentersStores) {
            if (this.props.formType === 'ReturnLabel' && isAdmin) {
                stores = this.state.storeList;
            }
            else {
                stores = this.state.storeList.filter(a => a.company == this.props.storeType);
            }

        }
        console.log(stores, "renderShipTo_StoreDC");

        return (
            <Autocomplete
                disabled={disable}
                value={this.state.selectedStore}
                options={this.props.isDC ? DCList : stores}
                getOptionLabel={(option: any) => {
                    if (option.id !== '') { return `${option.id} - ${option.name}`; }
                    else { return this.props.isDC ? 'Select Distribution Center' : 'Select Store' }
                }}
                style={{ width: '50%', background: 'white', height: 'max-content' }}
                onChange={(event: any, newValue: any) => {
                    this.storeChange({ target: { value: newValue } }, 'selectedStore', 'shipToDetails', this.props.isDC);
                    if (this.props.formType === 'ReturnLabel') {
                        this.getDefaultReturnLabelCarrierService(newValue.id);
                    }


                }}
                renderInput={(params: any) => <TextField {...params} error={this.checkStores} margin="normal" />}
            />
        );
    }

    renderRadioButtons_StoreDC = () => {
        console.log(this.props.storeType, "this.props.storeType");
        console.log(this.props.isDC, "this.props.isDC");
        //let _result: any = StoreManager.getStoreDetails_local(this.state.currentStore.id);
        //console.log(_result, "_result_result");
        let storeT = Constants.gcStoreType;
        storeT = this.props.storeType; //this.props.isDC ? Constants.distributionCentersStores : (_result.Company && _result.Company.length > 0 ? _result.Company : storeT);
        return (
            <div>
                <Radio color='primary' value={Constants.gcStoreType} checked={!this.props.isDC && storeT == Constants.gcStoreType} onChange={(event: any) => { this.toggleIsDC(event) }} />
                GC Stores
                <Radio color='primary' value={Constants.mmaStoreType} checked={!this.props.isDC && storeT == Constants.mmaStoreType} onChange={(event: any) => { this.toggleIsDC(event) }} />
                MAA Stores
                <Radio color='primary' value={Constants.distributionCentersStores} checked={this.props.isDC && storeT == Constants.distributionCentersStores} onChange={(event: any) => { this.toggleIsDC(event) }} />
                Distribution Center
            </div>
        );
    }

    renderRadioButtons_SPOOrder = () => {
        return (
            <div>
                <Radio color='primary' checked={this.props.isSPO} onChange={this.toggleIsSPO} />
                Web Order
                <Radio color='primary' checked={!this.props.isSPO} onChange={this.toggleIsSPO} />
                Others
            </div>
        );
    }
}