import React from 'react';
import './Dialogs.scss';
import IdentityManager from '../../Managers/Identity.manager';
import Loader from "react-loader-spinner";

import { Button, Dialog, DialogTitle, DialogContent, DialogActions, TableContainer, Table, Paper, TableRow, TableCell, TableBody, Slide } from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import PFManager from '../../Managers/Process.Fulfillment.Manager';
import Constants from '../../Common/Constants';
import WarningIcon from '@material-ui/icons/Warning';

import 'react-circular-progressbar/dist/styles.css';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import Utils from '../../Common/Utils';
import LabelUtils from '../../Common/Label.Utils';
import PFServicesManager from '../../Managers/Fulfillment.Services.Manager';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
  ) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function MultiVoidDialog(props: any): any {

    const [toVoid, setToVoid] = React.useState<number>(0);
    const [voided, setVoided] = React.useState<number>(0);
    const [loaded, setLoaded] = React.useState<boolean>(false);

    const [loading, setLoading] = React.useState<boolean>(false);
    const [results, setResults] = React.useState<any[]>([]);

    const resetState = () => {
        setLoaded(false);
        setLoading(false);
        setResults([]);
        setToVoid(0);
        setVoided(0);
    }

    const closeDialogBox = () => {
        props.changeState({multiVoid: false});
        resetState();
    }

    const reloadData = () => {
        props.changeState({dataLoaded: false});
        setTimeout(() => {
            props.changeState({multiVoid: false, bulkProcessLabels: [], dataLoaded: true});
            resetState();
        }, 100);
    }

    const voidLabel = async () => {
        setLoading(true);
        setToVoid(props.labels.length);

        for (let i = 0; i < props.labels.length; i++) {
            
            let result: any = null;
            //Use Shipping API or Lambda for PF Services
            if(PFManager.shippingAPI){
                let payload = {
                    shipper:  props.labels[i].Store,
                    SUID : props.labels[i].ServiceResponse.SUID
                };
                result = await PFServicesManager.voidShipment(payload);
            }
            else{
                result = await PFManager.voidShipment(props.labels[i].ServiceResponse.SUID, props.labels[i].Store);
            }

            let _voided = voided + 1
            setVoided(_voided)
            
            // error case
            let msg = '';
            if (result.method !== undefined) {
                // msg = result.details.message;
                msg = Constants.MESSAGES.VOID_LABEL_FAILURE
            } else {
                msg = result.response.msg;
            }

            if (msg === '') {
                msg = Constants.MESSAGES.VOID_LABEL_SUCCESS;
            }
            
            let _results = results;
            let trackNumbers = LabelUtils.getProperty.trackingNo(props.labels[i].ServiceResponse);
            trackNumbers.forEach((r: any) => _results.push({Title: r, Msg: msg}));
            // _results.push({Title: props.labels[i].ServiceResponse.CARRIER_PACKAGE_REFERENCE, Msg: msg});
            setResults(_results);
        }

        setLoading(false);
        setLoaded(true);
    }
  
  const renderLoader = () => {
    let percentage = 0;
    if (toVoid > 0 && voided > 0)
      percentage = (voided / toVoid) * 100;

    // let style = Utils.isDesktop ?  Constants.PROGRESS_BAR_STYLE_DESKTOP : Constants.PROGRESS_BAR_STYLE
    let style = Constants.PROGRESS_BAR_STYLE_DESKTOP;

    return (
      <div style={{ width: '200px', height: '200px', margin: '10px auto' }}>
        <CircularProgressbar value={percentage} text={`${percentage.toFixed(2)}%`}
            styles={buildStyles(style)}/>
      </div>
    );
  }

  let trackingNumbers: any[] = [];
  props.labels.forEach((r: any) => {
    let temp = LabelUtils.getProperty.trackingNo(r.ServiceResponse);
    trackingNumbers.push(...temp); 
  });

  return (
    <Dialog open={props.open} onClose={() => {closeDialogBox()}} TransitionComponent={Transition}>
        {
            loading && 
            <div style={{width: Utils.isMobile ? '90vw' : '400px'}}>
                {renderLoader()}
            </div>
        }

        {
            !loading &&
            <DialogTitle className="itemDialogBorder"  style={{background: toVoid === 0 ? '#ffbb33' : 'white'}}>
                <div className="dialogHeader">
                    <span style={{color: toVoid === 0 ? 'black' : 'inherity', margin: '0', fontWeight: 600}}>
                        { toVoid === 0 ? 'WARNING' : 'Results' }
                    </span>
                    { toVoid === 0 ? <WarningIcon /> : null }
                </div>
            </DialogTitle>
        }
      
        {
            !loading &&
            <DialogContent className="itemDialogBorder dialogWidth">
                {
                    toVoid === 0
                    ?
                    <div className='voidLabel'>
                        <span>You are about to void the selected labels.</span> <br/>
                        <span>These labels will be deleted.</span> <br/>
                        <br/>
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            <b>Tracking Numbers:</b>
                            {
                                trackingNumbers.map((item: any, i: number, array: Array<any>) => 
                                <span style={{fontSize: '16px', marginLeft: '5px'}}> - {item}</span>)
                            }
                        </div>
                    </div>
                    :
                    <div className='voidLabel' style={{fontSize: '14px'}}>
                    {
                        results.map((item: any, i: number, array: Array<any>) => 
                            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                <div style={{width: Utils.isMobile ? '35%' : '40%'}}>{item.Title}: </div>
                                <div style={{width: Utils.isMobile ? '55%' : '60%'}}>{item.Msg}</div>
                            </div>
                        )
                    }
                    </div>
                }
            </DialogContent>
        }

        {
            !loading &&
            <DialogActions>
            {
                toVoid === 0 &&
                <Button size="small" onClick={() => {voidLabel()}} variant="contained" style={{marginLeft:'10px'}}>
                <span className="dialogButton">Yes</span>
                </Button>
            }
            {
                toVoid === 0 &&
                <Button size="small" onClick={() => {closeDialogBox()}} style={{color:'black', background:'#ffbb33'}} variant="contained">
                <span className="dialogButton">No</span>
                </Button>
            }
            {
                toVoid !== 0 &&
                <Button size="small" onClick={() => {reloadData()}} color="primary" variant="contained">
                <span className="dialogButton">Close</span>
                </Button>
            }
            </DialogActions>
        }
    </Dialog>
  );
}