import Constants from "./Constants";
import moment from "moment";

export default class EODUtils {

  public static parseData_EODReport = (data: any, selected: string, services: any[]) => {
    if (data === null || data === undefined) {
        return []
    }
    
    // let voidedData = data
    //   .filter((r: any) => r.LogType === Constants.LOG_TYPES.VOID_LABEL)
    //   .map((r: any) => r.ServiceResponse.SUID);
    
    let interim: any[] = data.filter((r: any) => r.LogType === Constants.LOG_TYPES.CREATE_LABEL)
    interim.forEach((r: any) => r.isVoided = r.Status === 'Active' ? false : true)

    // return data
    return interim
    .filter((r: any) =>  r.Status === 'Active')
    .filter((r: any) =>  r.Carrier === selected)
    .filter((r: any) => services.indexOf(r.Service) > -1)
    .sort((a: any,b: any) => {
      var ad = moment(a.LogTime).toDate();
      var bd = moment(b.LogTime).toDate();
      return ad.getTime() > bd.getTime() ? -1 : 1
    })
    .sort((a: any,b: any) => {
      var ad = moment(a.LogTime).toDate();
      var bd = moment(b.LogTime).toDate();
      return ad.getTime() > bd.getTime() ? -1 : 1
    });
  }

  public static getEODProperty = {
    numeric: (packages: any[], property: string) => {
      let result = 0;
      packages.forEach((r:any) => result = result + parseFloat(r[property]));
      return result;
    },

    address: (payload: any) => {
      let contact = payload.CONSIGNEE_CONTACT !== '' ? payload.CONSIGNEE_CONTACT : payload.CONSIGNEE_COMPANY
      return [
        contact,
        `${payload.CONSIGNEE_ADDRESS1}, ${payload.CONSIGNEE_ADDRESS2}`,
        `${payload.CONSIGNEE_CITY}, ${payload.CONSIGNEE_STATE} - ${payload.CONSIGNEE_POSTALCODE}`,
        `${payload.CONSIGNEE_COUNTRYID}`
      ];
    },
    
    trackingNo: (data: any) => {
      let trackingNumbers: string[] = []
      if (data.PACKAGES !== undefined) {
          data.PACKAGES.forEach((r: any) => trackingNumbers.push(r.CARRIER_PACKAGE_REFERENCE));
      } else {
          trackingNumbers.push(data.CARRIER_PACKAGE_REFERENCE)
      }
      return trackingNumbers;
    },

    
    shipRef: (data: any) => {
      let shipRefs: string[] = []
      if (data.PACKAGES !== undefined) {
          data.PACKAGES.forEach((r: any) => shipRefs.push(r.SHIPPER_SHIPMENT_REFERENCE));
      } else {
          shipRefs.push(data?.SHIPPER_SHIPMENT_REFERENCE ?? "")
      }
      return shipRefs;
    },

  }
}