import React from 'react';
import './CreateLabel.scss';

export default function CustomerDetails(props: any): any {

    return (
        <div className='name' onClick={() => {props.customerClick(props.data)}}>
            <div className='title'><b>{props.data.name}</b></div>
            <div className='details'>{props.data.storeInfo.Address}, {props.data.storeInfo.Address2}</div>
            <div className='details'>{props.data.storeInfo.State} - {props.data.storeInfo.Zip}</div>
        </div>
    );
}